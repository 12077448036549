export const locationConstants = {
	COUNTRY_REQUEST: 'LOCATION_COUNTRY_REQUEST',
	COUNTRY_SUCCESS: 'LOCATION_COUNTRY_SUCCESS',
	COUNTRY_FAILURE: 'LOCATION_COUNTRY_FAILURE',

	STATE_REQUEST: 'LOCATION_STATE_REQUEST',
	STATE_SUCCESS: 'LOCATION_STATE_SUCCESS',
	STATE_FAILURE: 'LOCATION_STATE_FAILURE',

	CITY_REQUEST: 'LOCATION_CITY_REQUEST',
	CITY_SUCCESS: 'LOCATION_CITY_SUCCESS',
	CITY_FAILURE: 'LOCATION_CITY_FAILURE',

	BIOME_REQUEST: 'LOCATION_BIOME_REQUEST',
	BIOME_SUCCESS: 'LOCATION_BIOME_SUCCESS',
	BIOME_FAILURE: 'LOCATION_BIOME_FAILURE',

	LAND_CATEGORY_REQUEST: 'LOCATION_LAND_CATEGORY_REQUEST',
	LAND_CATEGORY_SUCCESS: 'LOCATION_LAND_CATEGORY_SUCCESS',
	LAND_CATEGORY_FAILURE: 'LOCATION_LAND_CATEGORY_FAILURE'
}
