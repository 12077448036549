import styled from 'styled-components'
import { Button, Message } from 'semantic-ui-react'
import theme from '../../styles/theme'

export const Background = styled.div`
	z-index: 9999999999999;
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	justify-content: center;
	align-items: center;
`

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
`

export const TabsContainer = styled.div`
	display: flex;
	justify-content: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	backgroundcolor: '#fff';
	.MuiTabs-indicator {
		background: #d56115;
	}
	.MuiButtonBase-root {
		font-size: 15px;
		flex: 1;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.54);
	}
	.MuiButtonBase-root[aria-selected='true'] {
		color: #194d48;
	}
	.MuiTabs-root {
		width: 100%;
	}
`
export const EmailSent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px 60px;
	padding-bottom: 40px;
	@media screen and (max-width: 782px) {
		padding: 16px 0;
	}
`

export const StyledHeader = styled.span`
	font-family: Roboto;
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
	color: ${() => theme.colors.primary};
	padding: 24px 0px 16px 0px;
	i {
		color: ${() => theme.colors.primary};
		font-weight: 400;
	}
	i:hover {
		cursor: pointer;
	}
`
export const InputWrapper = styled.div`
	display: flex;
	padding: 0px 32px;
	gap: 16px;
	flex-direction: column;
	.field {
		margin: 0 !important;
		display: flex;
		flex-direction: column;
		gap: 8px;
		font-family: Roboto;
		font-size: 14px;
		font-weight: 400;
		color: ${() => theme.colors.black06};
	}
	.field .input > input {
		padding-left: 12px !important;
		padding-right: 12px !important;
		width: 100% !important;
		font-family: Roboto;
	}
	.field .input > input::placeholder {
		font-size: 15px;
	}
	.MuiTypography-root {
		color: ${() => theme.colors.black06};
		font-size: 14px;
	}
	.MuiFormControlLabel-root {
		margin: 0 !important;
	}
	@media screen and (max-width: 782px) {
		padding: 0 16px;
	}
`

export const LoginButton = styled(Button)`
	&.ui {
		background-color: ${() => theme.colors.secondary} !important;
		color: #fff !important;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		margin-right: 0;
	}
	&.ui:hover {
		background-color: ${() => theme.colors.secondary};
		color: #fff;
	}
	&.ui i {
		display: flex !important;
		align-items: center !important;
		margin-right: 0 !important;
		opacity: 1 !important;
		color: '#fff' !important;
		fontsize: '28px' !important;
	}
`

export const ModalWrapper = styled.div`
	display: flex;
	padding: 16px 60px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	@media screen and (max-width: 782px) {
		padding: 16px 0;
	}
`

export const AlertMessage = styled(Message)`
	font-family: Roboto;
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
`
