/**
 0 - Aguardando aprovação
 1 - Aprovado
 2 - Rejeitado
 3 - Solicitada correção
 4 - Rascunho
 5 - Rejeitada análise do conteúdo pelo curador indicado
 10 - Excluído
*/
export const STATUS = {
	AGUARDANDO_APROVACAO: 0,
	APROVADO: 1,
	REJEITADO: 2,
	SOLICITADA_CORRECAO: 3,
	RASCUNHO: 4,
	REJEITADA_ANALISE: 5,
	EXCLUIDO: 10
}

export const STATUS_NAME = {
	[STATUS.AGUARDANDO_APROVACAO]: 'Aguardando aprovação',
	[STATUS.APROVADO]: 'Aprovado',
	[STATUS.REJEITADO]: 'Rejeitado',
	[STATUS.SOLICITADA_CORRECAO]: 'Correção solicitada',
	[STATUS.RASCUNHO]: 'Rascunho',
	[STATUS.REJEITADA_ANALISE]: 'Análise rejeitada',
	[STATUS.EXCLUIDO]: 'Excluído'
}
