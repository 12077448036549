import styled from 'styled-components'
import { Menu } from 'semantic-ui-react'

export const Wrapp = styled.ol.attrs((props) => ({
	isWpLoggedIn: props.isWpLoggedIn
}))`
	${({ isWpLoggedIn }) =>
		isWpLoggedIn
			? `
      top: 54px;
`
			: `
      top: 24px;
`}
	display: flex;
	height: 0;
	flex-direction: column;
	justify-content: start;
	padding-left: 230px;
	position: fixed;
	z-index: 9999;
	width: 100vw;
	@media screen and (max-width: 1050px) {
		height: 80px;
		${({ isWpLoggedIn }) =>
			isWpLoggedIn
				? `
        top: 132px;
  `
				: `
        top: 100px;
  `}
		padding-left: 30px;
		background: ${({ theme }) => theme.colors.primary};
	}
	@media screen and (max-width: 782px) {
		${({ isWpLoggedIn }) =>
			isWpLoggedIn
				? `
        top: 145px;
  `
				: `
        top: 100px;
  `}
		padding-left: 16px;
	}
	@media screen and (max-width: 600px) {
		top: 100px;
	}
`
export const InputContainer = styled.div`
	width: 720px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 1015px) {
		width: 400px;
	}
`
export const InputBackground = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	height: 54px;
	padding: 8px 12px;
	gap: 16px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	color: ${({ theme }) => theme.colors.primary};
	border-radius: 8px;
	background: #fff;
	::placeholder {
		color: ${({ theme }) => theme.colors.primary};
		opacity: 0.5;
	}
	i {
		position: absolute;
		top: 10px;
		right: 20px;
		color: ${({ theme }) => theme.colors.primary};
		font-size: 20px;
	}
`

export const Input = styled.input`
	flex: 1;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 400;
	:focus,
	:hover {
		outline: none;
		border-color: #fff;
	}
`
export const Filters = styled.div.attrs((props) => ({
	isWpLoggedIn: props.isWpLoggedIn
}))`
	${({ isWpLoggedIn }) =>
		isWpLoggedIn
			? `
    top: 132px;
`
			: `
    top: 100px;
`}
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100vw;
	z-index: 999;
	justify-content: start;
	align-items: center;
	@media screen and (max-width: 1050px) {
		${({ isWpLoggedIn }) =>
			isWpLoggedIn
				? `
        top: 212px;
  `
				: `
        top: 180px;
  `}
	}
	@media screen and (max-width: 782px) {
		${({ isWpLoggedIn }) =>
			isWpLoggedIn
				? `
        top: 225px;
  `
				: `
        top: 180px;
  `}
	}
	@media screen and (max-width: 600px) {
		top: 180px;
	}
`
export const Types = styled.div`
	display: flex;
	width: 100vw;
	height: 50px;
	padding: 0px 60px 0px 230px;
	justify-content: start;
	align-items: center;
	gap: 8px;
	background-color: var(--primary, #194d48);
	box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
	@media screen and (max-width: 1200px) {
		padding: 0 24px;
	}
	@media screen and (max-width: 782px) {
		padding: 0 16px;
		overflow-x: auto;
	}
	.ui.simple.dropdown:hover > .menu {
		display: none;
	}
`
export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: end;
	height: 40px;
	padding: 4px 16px;
	align-items: center;
	@media screen and (max-width: 900px) {
		justify-content: center;
		padding: 0;
	}
`
export const AdvancedFiltersButton = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 8px;
	color: var(--blue-grey-lighten-3, #b0bec5);
	:hover {
		cursor: pointer;
	}
`
export const AdvancedFiltersButtonTitle = styled.div`
	white-space: nowrap;
	vertical-align: center;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	color: #fff;
`
export const CloseAdvancedFiltersButton = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 6px;
	color: #424242;
	text-align: center;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	:hover {
		cursor: pointer;
	}
`

export const AdvancedFilters = styled.div`
	display: flex;
	width: 100vw;
	padding: 4px 60px 4px 230px;
	box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2);
	background: var(--brown-lighten-5, #efebe9);
	margin-top: -48px;
	z-index: -1;
	transition: margin 0.5s;
	&.clicked {
		margin-top: 0;
	}
	@media screen and (max-width: 1200px) {
		padding: 4px 30px;
	}
	@media screen and (max-width: 900px) {
		flex-direction: column;
		padding: 32px;
		margin-top: -600px;
		gap: 16px;
		&.clicked {
			height: calc(100vh - 275px);
			overflow-y: auto;
			margin-top: 0px;
		}
	}
`

export const AdvancedFiltersFakeDiv = styled.div`
	height: 48px;
	width: 100%;
	transition: margin 0.5s;
	margin-top: -48px;
	&.clicked {
		margin-top: 0;
	}
	@media screen and (max-width: 900px) {
		display: none;
	}
`

export const TypeItem = styled(Menu.Item)`
	display: flex;
	padding: 0px 16px;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	align-self: stretch;
	color: #b0bec5 !important;
	&.active {
		background-color: rgba(255, 255, 255, 0.12) !important;
		color: #fff !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
	},
	&.active i {
		color: #fff !important;
	}
	:hover {
		cursor: pointer;
		color: #fff;
	}
	i {
		color: #b0bec5 !important;
	}
  .menu i {
		color: ${({ theme }) => theme.colors.primary} !important;
    font-size: 14px;
  }
	.menu {
		margin-top: 15px !important;
		margin-left: -20px !important;
	}
  .ui.dropdown .menu > .item {
    display: flex;
    gap: 8px;
    align-items: baseline;
  }
  &.more-item .ui.inline.dropdown {
    display: flex;
    gap: 8px;
  }
  &.more-item .icon {
    width: 8px;
  }
  .ui.dropdown .menu>.item>.image {
    margin-top: 0;
    width: 8px;
  }
  .ui.inline.dropdown > .text {
    font-weight: 400; 
  }
  .menu .text {
    font-size 14px;
  }
`
