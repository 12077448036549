import React from 'react'

import heading from '../../../assets/about/heading.jpg'
import questions from './questions.json'
import * as S from './styles'

const About = () => {
	return (
		<>
			<div className="page">
				<S.Heading>
					<img
						src={heading}
						alt="Entenda como funciona o Portal Proteja e como você pode colaborar"
					/>
				</S.Heading>
				<S.Wrapp>
					<S.Video>
						<iframe
							src="https://www.youtube.com/embed/p-T1IPiZFz0"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</S.Video>
					{questions.map((item, key) => {
						const qKey = `question_${key}`
						return (
							<li key={qKey}>
								<strong>{item.question}</strong>
								<p
									dangerouslySetInnerHTML={{
										__html: item.answer
									}}
								></p>
							</li>
						)
					})}
				</S.Wrapp>
			</div>
		</>
	)
}

export default About
