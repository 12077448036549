import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import EditCollectionDialog from '../EditCollectionDialog'
import { alertActions } from '../../../../app/_actions'
import PublishCollection from '../../PublishCollection'

const EditCollectionWrapper = ({
	collectionTypeObject,
	collectionModalOpen,
	setCollectionModalOpen,
	onUpdate = null,
	handlePublish = null,
	handleDelete = null,
	isAtCollectionPage = false
}) => {
	const publishCollectionTitle = collectionTypeObject
		? `Editar ou enviar ${collectionTypeObject.name.toLowerCase()} para avaliação`
		: ''

	const dispatch = useDispatch()
	// @ts-ignore
	const alert = useSelector((state) => state.alert)

	const { object: collection } = useSelector(
		// @ts-ignore
		(state) => state.collections
	)

	const [snackbarOpen, setSnackbarOpen] = useState(false)
	const [publishModalOpen, setPublishModalOpen] = useState(false)
	const history = useHistory()

	const handleOpenCollectionModal = useCallback(() => {
		setCollectionModalOpen(true)
		setPublishModalOpen(false)
	}, [setCollectionModalOpen])

	// const handleOpenPublishModal = useCallback(() => {
	// 	setCollectionModalOpen(false)
	// 	setPublishModalOpen(true)
	// }, [setCollectionModalOpen])

	const handleCloseCollectionModal = useCallback(() => {
		setCollectionModalOpen(false)
		if (!isAtCollectionPage && collection && collection.friendly_url) {
			history.push(`/painel/editar/${collection.friendly_url}`)
		}
	}, [isAtCollectionPage, collection, setCollectionModalOpen, history])

	const handleClosePublishModal = () => {
		setPublishModalOpen(false)
	}

	const handleSnackbarClose = useCallback(() => {
		setTimeout(() => {
			dispatch(alertActions.clear())
		}, 1000)
		setSnackbarOpen(false)
	}, [dispatch, setSnackbarOpen])

	useEffect(() => {
		if (alert.type) {
			setSnackbarOpen(true)
		}
	}, [alert.type])

	useEffect(() => {
		if (collectionModalOpen) {
			setPublishModalOpen(false)
		}
	}, [collectionModalOpen])

	// useEffect(() => {
	// 	if (selectedCollectionId && setSelectedCollectionId) {
	// 		handleOpenPublishModal()
	// 	}
	// }, [selectedCollectionId, setSelectedCollectionId, handleOpenPublishModal])

	if (!collectionTypeObject) {
		return <></>
	}

	return (
		<>
			<EditCollectionDialog
				collectionTypeObject={collectionTypeObject}
				handleClose={handleCloseCollectionModal}
				initialCollectionId={collection.id || null}
				isModalOpen={collectionModalOpen}
				onUpdate={onUpdate}
			/>
			<PublishCollection
				open={publishModalOpen}
				handleClose={handleClosePublishModal}
				startEdit={handleOpenCollectionModal}
				title={publishCollectionTitle}
				onDelete={handleDelete}
				onSubmitToPublish={handlePublish}
			/>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={alert.type !== 'green' ? 'warning' : 'success'}
					variant="filled"
					sx={{ width: '100%' }}
				>
					{alert.message ? alert.message : 'Salvo com sucesso'}
				</Alert>
			</Snackbar>
		</>
	)
}

export default EditCollectionWrapper
