import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import SharingBar from '../SharingBar'
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions'
import {
	ContentBlockForGrid,
	ContentOrCollectionDetailDialog
} from '../../../eita-react-components'
import noImg from '../../../assets/no-image.jpg'
import { collectionsActions } from '../../../app/_actions'
import useOnScreen from '../../../hooks/useOnScreen'
import SkeletonCustom from '../SkeletonCustom'

function CollectionListPage({ collectionTypeObject }) {
	const endOfPageRef = useRef(null)
	const [contentDetailOpen, setContentDetailOpen] = useState(false)
	const dispatch = useDispatch()
	const [activeItemPointer, setActiveItemPointer] = useState(null)
	const { width } = useWindowDimensions()
	const { i18n } = useTranslation()
	const { data, object, loading, page } = useSelector(
		// @ts-ignore
		(state) => state.collections
	)
	const PER_PAGE = 12
	const reachedEndOfScreen = useOnScreen(endOfPageRef)

	useEffect(() => {
		dispatch(
			collectionsActions.getCollectionsByType(
				collectionTypeObject.slug,
				1,
				PER_PAGE
			)
		)
	}, [])

	useEffect(() => {
		if (!data || data.length === 0) {
			return
		}
		if (!loading && reachedEndOfScreen && data.has_next) {
			dispatch(
				collectionsActions.loadMoreCollectionsByType(
					collectionTypeObject.slug,
					page + 1,
					PER_PAGE
				)
			)
		}
	}, [
		dispatch,
		data,
		page,
		loading,
		reachedEndOfScreen,
		collectionTypeObject.slug
	])

	const onCollectionContentClick = (friendlyUrl) => {
		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${friendlyUrl}`, '_blank')
				: window.open(`/conteudo/${friendlyUrl}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const onBlockClick = (newItemId, newItemIndex, friendlyUrl) => {
		if (width > 782) {
			setActiveItemPointer({
				itemId: newItemId,
				itemIndex: newItemIndex
			})
			setContentDetailOpen(true)
			return
		}

		const win =
			i18n.language == 'es-ES'
				? window.open(
						`/${collectionTypeObject.slug}-es/${friendlyUrl}`,
						'_blank'
				  )
				: window.open(
						`/${collectionTypeObject.slug}/${friendlyUrl}`,
						'_blank'
				  )
		if (win != null) {
			win.focus()
		}
	}

	useEffect(() => {
		if (activeItemPointer) {
			dispatch(
				collectionsActions.getCollectionById(activeItemPointer.itemId)
			)
			return
		}

		dispatch(collectionsActions.cleanCollection())
	}, [activeItemPointer, dispatch])

	const hasNext =
		activeItemPointer &&
		data &&
		data.collections &&
		data.collections.length > 0 &&
		activeItemPointer.itemIndex < data.collections.length - 1

	const hasPrev = activeItemPointer && activeItemPointer.itemIndex > 0

	const nextItem = useCallback(() => {
		setActiveItemPointer((currActiveItemPointer) => {
			if (!data || !data.collections) {
				return currActiveItemPointer
			}
			if (
				currActiveItemPointer &&
				currActiveItemPointer.itemIndex < data.collections.length - 1
			) {
				const newItemIndex = currActiveItemPointer.itemIndex + 1
				return {
					itemId: data.collections[newItemIndex].id,
					itemIndex: newItemIndex
				}
			}
			return currActiveItemPointer
		})
	}, [data])

	const previousItem = useCallback(() => {
		setActiveItemPointer((currActiveItemPointer) => {
			if (!data || !data.collections) {
				return currActiveItemPointer
			}
			if (currActiveItemPointer && currActiveItemPointer.itemIndex > 0) {
				const newItemIndex = currActiveItemPointer.itemIndex - 1
				return {
					itemId: data.collections[newItemIndex].id,
					itemIndex: newItemIndex
				}
			}
			return currActiveItemPointer
		})
	}, [data])

	const handleContentDetailClose = () => {
		setActiveItemPointer(null)
		setContentDetailOpen(false)
	}

	return (
		<>
			{!loading && data && data.collections.length === 0 && (
				<Alert severity="info" sx={{ width: '100%' }} variant="filled">
					Não há {collectionTypeObject.plural.toLowerCase()} registrad
					{collectionTypeObject.genre}s.
				</Alert>
			)}
			{data && data.collections.length > 0 && (
				<Box sx={{ mb: 4, ml: 4 }}>
					{data.totalResults}{' '}
					{collectionTypeObject.plural.toLowerCase()} registrad
					{collectionTypeObject.genre}s.
				</Box>
			)}
			<S.GridWrap>
				{data &&
					data.collections.map((collection, index) => (
						<ContentBlockForGrid
							key={index}
							title={collection.title}
							img={
								collection.cover_id
									? collection.cover_id
									: noImg
							}
							onClick={() => {
								onBlockClick(
									collection.id,
									index,
									collection.friendly_url
								)
							}}
							contentType={collection.collection_type}
							isFixedWidth={false}
						/>
					))}
				{data && loading && (
					<>
						{data.collections.length > 0 ? (
							<SkeletonCustom />
						) : (
							<S.ListWrap>
								{Array.from(Array(PER_PAGE).keys()).map(
									(index) => (
										<SkeletonCustom key={index} />
									)
								)}
							</S.ListWrap>
						)}
					</>
				)}
			</S.GridWrap>

			<S.PaginationWrap ref={endOfPageRef} />

			<ContentOrCollectionDetailDialog
				item={object}
				open={contentDetailOpen}
				handleClose={handleContentDetailClose}
				nextItem={nextItem}
				previousItem={previousItem}
				hasNext={hasNext}
				hasPrev={hasPrev}
				onCollectionContentClick={onCollectionContentClick}
			>
				<SharingBar item={object} />
			</ContentOrCollectionDetailDialog>
		</>
	)
}

export default CollectionListPage
