import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

import header from '../../../assets/partners-docs/header.jpg'
import partners1 from '../../../assets/partners-docs/partners-1.jpg'
import partners2 from '../../../assets/partners-docs/partners-2.jpg'
import partners3 from '../../../assets/partners-docs/partners-3.jpg'
// import support from '../../../assets/partners-docs/support.jpg'
import support2 from '../../../assets/partners-docs/support2.png'
import governance from '../../../assets/partners-docs/governance.jpg'
import partners from './partners.json'
import docs from './docs'
import * as S from './styles'

const requestImageFile = require.context('../../../assets', true, /.png$/)

const settings = {
	lazyLoad: true,
	autoplay: true,
	dots: true,
	infinite: true,
	slidesToShow: 1,
	speed: 500
}

function Partners() {
	const handleLeave = ({ target }) => {
		target.scrollTop = 0
	}

	return (
		<div className="page">
			<S.SectionImage>
				<img
					src={header}
					alt="Conheça as organizações parceiras do Proteja"
				/>
			</S.SectionImage>
			<S.SectionImage>
				<img
					src={partners1}
					alt="Conheça as organizações parceiras do Proteja"
				/>
				<Link to="/fale-conosco">
					<img
						style={{ margin: '-5px 0' }}
						src={partners2}
						alt="Conheça as organizações parceiras do Proteja"
					/>
				</Link>
				<img
					src={partners3}
					alt="Conheça as organizações parceiras do Proteja"
				/>
			</S.SectionImage>
			<div>
				<iframe
					width="100%"
					height="720"
					src="https://www.youtube.com/embed/iEtqiXT835Y"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>
			<Section>
				<S.WrapPartners>
					<S.List>
						{partners.map((item) => (
							<li key={item.id}>
								<S.WrapImage>
									<a target="blank" href={item.link}>
										<img
											loading="lazy"
											src={
												requestImageFile(
													`./partners/${item.id}.png`
												).default
											}
											alt={item.id}
										/>
									</a>
								</S.WrapImage>
								<S.Description onMouseLeave={handleLeave}>
									{item.text}
								</S.Description>
							</li>
						))}
					</S.List>
				</S.WrapPartners>
			</Section>
			<S.SectionImage>
				<img
					src={support2}
					alt="Conheça as organizações parceiras do Proteja"
				/>
			</S.SectionImage>
			<S.SectionImage>
				<img
					src={governance}
					alt="Conheça as organizações parceiras do Proteja"
				/>
			</S.SectionImage>
			<Section>
				<S.WrapDocs>
					<Slider {...settings}>
						{docs.map((item) => (
							<S.SliderImage key={item.id}>
								<>
									<img
										src={
											requestImageFile(
												`./partners-docs/governance_${item.id}.png`
											).default
										}
									/>
								</>
							</S.SliderImage>
						))}
					</Slider>
				</S.WrapDocs>
			</Section>
		</div>
	)
}

const Section = ({ children }) => {
	return <S.Section>{children}</S.Section>
}

export default Partners
