import LoadingButton from '@mui/lab/LoadingButton'
import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	.MuiStepLabel-root {
		gap: 8px;
	}
	.MuiStepLabel-label {
		font-size: 18px;
		display: flex;
		flex-direction: column;
	}
	.MuiTypography-caption {
		font-size: 13px;
		color: var(--black-06, rgba(0, 0, 0, 0.6));
	}
	.MuiStep-root {
		padding: 18px !important;
	}
	.MuiStepIcon-text {
		font-size: 13px;
	}
	.MuiSvgIcon-root {
		transform: scale(1.6);
	}
	.MuiSvgIcon-root.Mui-active {
		color: ${({ theme }) => theme.colors.secondary};
	}
`

export const ContentFooter = styled.div`
	display: flex;
	padding: 16px 24px 24px 24px;
	justify-content: space-between;
	align-items: flex-end;
`

export const FooterButton = styled(LoadingButton)``
