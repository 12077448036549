import { carouselConstants } from '../_constants'
import { carouselService } from '../_services'

export const carouselActions = {
	reset,
	getContent,
	loadMore
}

function getContent(carouselIndex, params, page, perPage) {
	return (dispatch) => {
		carouselService.getContent(params, page, perPage).then(
			(data) => dispatch(success(carouselIndex, data)),
			(error) => console.error(error.toString())
		)
	}

	function success(carouselIndex, data) {
		return {
			type: carouselConstants.SUCCESS,
			carouselIndex,
			data
		}
	}
}

function reset(carouselIndex) {
	return (dispatch) => {
		dispatch({ type: carouselConstants.CLEAN, carouselIndex })
	}
}

function loadMore(carouselIndex, params, page, perPage) {
	return (dispatch) => {
		carouselService.loadMore(params, page, perPage).then(
			(data) => dispatch(success(carouselIndex, data, page)),
			(error) => console.error(error.toString())
		)
	}

	function success(carouselIndex, data, page) {
		return {
			type: carouselConstants.LOAD_MORE,
			carouselIndex,
			data,
			page
		}
	}
}
