import React from 'react'
import * as S from './styles'

const EditDialogFooter = ({
	showFooter,
	handleCloseModal,
	step = null,
	setStep = null,
	handleSubmit,
	loading,
	steps = null
}) => {
	return (
		<>
			{showFooter && (
				<S.Wrapper>
					<S.ContentFooter>
						<S.FooterButton onClick={handleCloseModal}>
							Fechar
						</S.FooterButton>
						<div>
							{step === null ? (
								<S.FooterButton
									onClick={handleSubmit}
									variant="contained"
									loading={loading}
								>
									Salvar
								</S.FooterButton>
							) : (
								<>
									{step > 0 && (
										<S.FooterButton
											onClick={() => setStep(step - 1)}
											// disabled={loadingImage}
										>
											Voltar
										</S.FooterButton>
									)}
									<S.FooterButton
										onClick={() => handleSubmit(step)}
										variant="contained"
										loading={loading}
										// loading={loading || loadingImage}
										// loadingPosition="start"
									>
										{step < steps.length - 1
											? 'Próximo'
											: 'Finalizar'}
									</S.FooterButton>
								</>
							)}
						</div>
					</S.ContentFooter>
				</S.Wrapper>
			)}
		</>
	)
}

export default EditDialogFooter
