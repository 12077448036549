import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
	GridColumn,
	Form,
	Header,
	Grid,
	Button,
	Message
} from 'semantic-ui-react'
import { alertActions, userActions } from '../../../app/_actions'

import * as S from './styles'

const fields = [
	{
		type: 'password',
		name: 'newPassword',
		label: 'Nova senha',
		placeholder: 'Insira a nova senha',
		icon: 'lock',
		required: true
	},
	{
		type: 'password',
		name: 'passwordConfirmation',
		label: 'Confirmação de senha',
		placeholder: 'Repita a nova senha',
		icon: 'lock',
		required: true
	}
]

const ResetPassword = ({ history }) => {
	const dispatch = useDispatch()

	const token = new URLSearchParams(history.location.search).get('token')
	const [submitted, setSubmitted] = useState(false)
	const [inputs, setInputs] = useState({
		newPassword: '',
		passwordConfirmation: ''
	})

	const { fetchingNewPassword, changedNewPassword } = useSelector(
		(state) => state.authentication
	)
	const { type, message } = useSelector((state) => state.alert)

	function handleChange(e) {
		const { name, value } = e.target
		setInputs((inputs) => ({ ...inputs, [name]: value }))
	}

	function handleSubmit(e) {
		e.preventDefault()
		const { newPassword, passwordConfirmation } = inputs

		setSubmitted(true)
		dispatch(alertActions.clear())
		if (inputs.newPassword && inputs.passwordConfirmation) {
			dispatch(
				userActions.setNewPassword(
					newPassword,
					passwordConfirmation,
					token
				)
			)
		}
	}

	if (!token) {
		history.replace('/')
		//precisa atualizar a tela para trocar o menu do wordpress
		window.location.reload()
	}

	const ReturnToLogin = () => (
		<NavLink to="/">
			<Button
				type="button"
				style={{ margin: '5px 0' }}
				loading={fetchingNewPassword}
				fluid
				size="big"
			>
				Voltar para a home
			</Button>
		</NavLink>
	)

	return (
		<S.Container className="page">
			<Grid stackable container centered style={{ margin: '5em 0 10px' }}>
				<GridColumn width={8}>
					{type && message && (
						<Message
							className={fetchingNewPassword ? '' : 'shake'}
							color={type}
							header={message}
						/>
					)}
					{changedNewPassword ? (
						<ReturnToLogin />
					) : (
						<>
							<Header>Definir nova senha</Header>
							<Form size="large" onSubmit={handleSubmit}>
								{fields.map((field, key) => {
									return (
										<Form.Input
											key={key}
											type={field.type}
											label={`${field.label} ${
												field.required ? '*' : ''
											}`}
											error={
												submitted &&
												field.required &&
												!inputs[field.name]
											}
											name={field.name}
											placeholder={field.placeholder}
											onChange={handleChange}
											fluid
											icon={field.icon}
											size="big"
											iconPosition="left"
										/>
									)
								})}
								<Button
									className="styled"
									loading={fetchingNewPassword}
									fluid
									size="big"
								>
									Mudar senha
								</Button>
								<ReturnToLogin />
							</Form>
						</>
					)}
				</GridColumn>
			</Grid>
		</S.Container>
	)
}

export default ResetPassword
