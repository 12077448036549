import { useEffect } from 'react'

/**
 * Hook to import js script
 * @param {React.node} el
 * @param {String} resourceUrl
 */
const useScript = (resourceUrl) => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src = resourceUrl
		script.async = true
		document.body.appendChild(script)
		return () => {
			document.body.removeChild(script)
		}
	}, [resourceUrl])
}
export default useScript
