import styled from 'styled-components'

export const Wrapp = styled.ol`
	padding: 64px;
	list-style: decimal;
	li {
		margin: 32px 0;
	}
	strong {
		font-size: 1.5em;
		color: ${({ theme }) => theme.colors.primary};
	}
	p {
		font-size: 1.2em;
	}
	a {
		color: ${({ theme }) => theme.colors.secondary};
	}
	@media screen and (max-width: 1024px) {
		padding: 16px;
	}
`

export const Heading = styled.div`
	text-align: center;
	img {
		max-width: 1400px;
		width: 100%;
	}
`

export const Video = styled.div`
	float: right;
	margin: 24px;
	iframe {
		width: 560px;
		height: 315px;
	}
	@media screen and (max-width: 1024px) {
		float: unset;
		iframe {
			width: 100%;
		}
	}
`
