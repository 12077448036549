import styled from 'styled-components'

export const SharingBar = styled.div`
	display: flex;
	width: 100%;
	padding: 16px;
	gap: 8px;
	background: var(--brown-lighten-5, #efebe9);
	.MuiInputBase-root {
		background: #fff;
	}
	.MuiTextField-root {
		flex: 1;
		margin-right: 8px;
	}
	@media screen and (max-width: 580px) {
		justify-content: space-between;
		.MuiTextField-root {
			display: none;
		}
	}
`
export const SharingBarRow = styled.div`
	display: flex;
	gap: 8px;
`
export const CopyLink = styled.a`
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
`
