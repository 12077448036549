import styled from 'styled-components'

export const Wrapper = styled.div`
	margin-top: 16px;
	.content-type {
		display: flex;
		background: #f5f5f5;
		padding: 10px 16px;
		box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset;
	}
	.content-info {
		display: flex;
		background: #fafafa;
		padding: 10px 16px;
		box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset;
	}
`
export const ContentLink = styled.a`
	color: ${({ theme }) => theme.colors.secondary};
	:hover {
		color: #194d48;
	}
`
