const languageList = [
	{
		id: 'pt',
		name: 'Portuguese',
		nativeName: 'Português',
		portugueseName: 'Português'
	},
	{
		id: 'es',
		name: 'Spanish; Castilian',
		nativeName: 'español, castellano',
		portugueseName: 'Espanhol'
	},
	{
		id: 'en',
		name: 'English',
		nativeName: 'English',
		portugueseName: 'Inglês'
	},
	{
		id: 'lr',
		name: 'Libras',
		nativeName: 'Libras',
		portugueseName: 'Libras'
	},
	{
		id: 'ab',
		name: 'Abkhaz',
		nativeName: 'аҧсуа',
		portugueseName: 'Abecázio'
	},
	{ id: 'aa', name: 'Afar', nativeName: 'Afaraf', portugueseName: 'Afar' },
	{
		id: 'af',
		name: 'Afrikaans',
		nativeName: 'Afrikaans',
		portugueseName: 'Africâner'
	},
	{ id: 'ak', name: 'Akan', nativeName: 'Akan', portugueseName: 'Akan' },
	{
		id: 'sq',
		name: 'Albanian',
		nativeName: 'Shqip',
		portugueseName: 'Albanês'
	},
	{
		id: 'am',
		name: 'Amharic',
		nativeName: 'አማርኛ',
		portugueseName: 'Amárico'
	},
	{
		id: 'ar',
		name: 'Arabic',
		nativeName: 'العربية',
		portugueseName: 'Árabe'
	},
	{
		id: 'an',
		name: 'Aragonese',
		nativeName: 'Aragonés',
		portugueseName: 'Aragonês'
	},
	{
		id: 'hy',
		name: 'Armenian',
		nativeName: 'Հայերեն',
		portugueseName: 'Armênio'
	},
	{
		id: 'as',
		name: 'Assamese',
		nativeName: 'অসমীয়া',
		portugueseName: 'Assamês'
	},
	{
		id: 'av',
		name: 'Avaric',
		nativeName: 'авар мацӀ, магӀарул мацӀ',
		portugueseName: 'Avar'
	},
	{
		id: 'ae',
		name: 'Avestan',
		nativeName: 'avesta',
		portugueseName: 'Avéstico'
	},
	{
		id: 'ay',
		name: 'Aymara',
		nativeName: 'aymar aru',
		portugueseName: 'Aimará'
	},
	{
		id: 'az',
		name: 'Azerbaijani',
		nativeName: 'azərbaycan dili',
		portugueseName: 'Azerbaijano'
	},
	{
		id: 'bm',
		name: 'Bambara',
		nativeName: 'bamanankan',
		portugueseName: 'Bambara'
	},
	{
		id: 'ba',
		name: 'Bashkir',
		nativeName: 'башҡорт теле',
		portugueseName: 'Bashkir'
	},
	{
		id: 'eu',
		name: 'Basque',
		nativeName: 'euskara, euskera',
		portugueseName: 'Basco'
	},
	{
		id: 'be',
		name: 'Belarusian',
		nativeName: 'Беларуская',
		portugueseName: 'Bielorrusso'
	},
	{
		id: 'bn',
		name: 'Bengali',
		nativeName: 'বাংলা',
		portugueseName: 'Bengali'
	},
	{
		id: 'bh',
		name: 'Bihari',
		nativeName: 'भोजपुरी',
		portugueseName: 'Bihari'
	},
	{
		id: 'bi',
		name: 'Bislama',
		nativeName: 'Bislama',
		portugueseName: 'Bislamá'
	},
	{
		id: 'bs',
		name: 'Bosnian',
		nativeName: 'bosanski jezik',
		portugueseName: 'Bósnio'
	},
	{
		id: 'br',
		name: 'Breton',
		nativeName: 'brezhoneg',
		portugueseName: 'Bretão'
	},
	{
		id: 'bg',
		name: 'Bulgarian',
		nativeName: 'български език',
		portugueseName: 'Búlgaro'
	},
	{
		id: 'my',
		name: 'Burmese',
		nativeName: 'ဗမာစာ',
		portugueseName: 'Birmanês'
	},
	{
		id: 'ca',
		name: 'Catalan; Valencian',
		nativeName: 'Català',
		portugueseName: 'Catalão'
	},
	{
		id: 'ch',
		name: 'Chamorro',
		nativeName: 'Chamoru',
		portugueseName: 'Chamorro'
	},
	{
		id: 'ce',
		name: 'Chechen',
		nativeName: 'нохчийн мотт',
		portugueseName: 'Checheno'
	},
	{
		id: 'ny',
		name: 'Chichewa; Chewa; Nyanja',
		nativeName: 'chiCheŵa, chinyanja',
		portugueseName: 'Chichewa'
	},
	{
		id: 'zh',
		name: 'Chinese',
		nativeName: '中文 (Zhōngwén), 汉语, 漢語',
		portugueseName: 'Chinês'
	},
	{
		id: 'cv',
		name: 'Chuvash',
		nativeName: 'чӑваш чӗлхи',
		portugueseName: 'Chuvash'
	},
	{
		id: 'kw',
		name: 'Cornish',
		nativeName: 'Kernewek',
		portugueseName: 'Córnico'
	},
	{
		id: 'co',
		name: 'Corsican',
		nativeName: 'corsu, lingua corsa',
		portugueseName: 'Corso'
	},
	{ id: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ', portugueseName: 'Cree' },
	{
		id: 'hr',
		name: 'Croatian',
		nativeName: 'hrvatski',
		portugueseName: 'Croata'
	},
	{
		id: 'cs',
		name: 'Czech',
		nativeName: 'česky, čeština',
		portugueseName: 'Tcheco'
	},
	{
		id: 'da',
		name: 'Danish',
		nativeName: 'dansk',
		portugueseName: 'Dinamarquês'
	},
	{
		id: 'dv',
		name: 'Divehi; Dhivehi; Maldivian;',
		nativeName: 'ދިވެހި',
		portugueseName: 'Mahl'
	},
	{
		id: 'nl',
		name: 'Dutch',
		nativeName: 'Nederlands, Vlaams',
		portugueseName: 'Holandês'
	},
	{
		id: 'eo',
		name: 'Esperanto',
		nativeName: 'Esperanto',
		portugueseName: 'Esperanto'
	},
	{
		id: 'et',
		name: 'Estonian',
		nativeName: 'eesti, eesti keel',
		portugueseName: 'Estoniano'
	},
	{ id: 'ee', name: 'Ewe', nativeName: 'Eʋegbe', portugueseName: 'Ewe' },
	{
		id: 'fo',
		name: 'Faroese',
		nativeName: 'føroyskt',
		portugueseName: 'Feroês'
	},
	{
		id: 'fj',
		name: 'Fijian',
		nativeName: 'vosa Vakaviti',
		portugueseName: 'Fijiano'
	},
	{
		id: 'fi',
		name: 'Finnish',
		nativeName: 'suomi, suomen kieli',
		portugueseName: 'Finlandês'
	},
	{
		id: 'ff',
		name: 'Fula; Fulah; Pulaar; Pular',
		nativeName: 'Fulfulde, Pulaar, Pular',
		portugueseName: 'Fula'
	},
	{
		id: 'gl',
		name: 'Galician',
		nativeName: 'Galego',
		portugueseName: 'Galego'
	},
	{
		id: 'ka',
		name: 'Georgian',
		nativeName: 'ქართული',
		portugueseName: 'Georgiano'
	},
	{
		id: 'de',
		name: 'German',
		nativeName: 'Deutsch',
		portugueseName: 'Alemão'
	},
	{
		id: 'el',
		name: 'Greek, Modern',
		nativeName: 'Ελληνικά',
		portugueseName: 'Grego Moderno'
	},
	{
		id: 'fr',
		name: 'French',
		nativeName: 'français, langue française',
		portugueseName: 'Francês'
	},
	{
		id: 'gn',
		name: 'Guaraní',
		nativeName: 'Avañeẽ',
		portugueseName: 'Guarani'
	},
	{
		id: 'gu',
		name: 'Gujarati',
		nativeName: 'ગુજરાતી',
		portugueseName: 'Gujarati'
	},
	{
		id: 'ht',
		name: 'Haitian; Haitian Creole',
		nativeName: 'Kreyòl ayisyen',
		portugueseName: 'Haitiano'
	},
	{
		id: 'ha',
		name: 'Hausa',
		nativeName: 'Hausa, هَوُسَ',
		portugueseName: 'Hauçá'
	},
	{
		id: 'hz',
		name: 'Herero',
		nativeName: 'Otjiherero',
		portugueseName: 'Herero'
	},
	{
		id: 'hi',
		name: 'Hindi',
		nativeName: 'हिन्दी, हिंदी',
		portugueseName: 'Hindi'
	},
	{
		id: 'ho',
		name: 'Hiri Motu',
		nativeName: 'Hiri Motu',
		portugueseName: 'Hiri Motu'
	},
	{
		id: 'hu',
		name: 'Hungarian',
		nativeName: 'Magyar',
		portugueseName: 'Húngaro'
	},
	{
		id: 'ia',
		name: 'Interlingua',
		nativeName: 'Interlingua',
		portugueseName: 'Interlíngua'
	},
	{
		id: 'id',
		name: 'Indonesian',
		nativeName: 'Bahasa Indonesia',
		portugueseName: 'Indonésio'
	},
	{
		id: 'ie',
		name: 'Interlingue',
		nativeName: 'Originally called Occidental; then Interlingue after WWII',
		portugueseName: 'Interlíngue'
	},
	{
		id: 'ga',
		name: 'Irish',
		nativeName: 'Gaeilge',
		portugueseName: 'Irlandês'
	},
	{
		id: 'ig',
		name: 'Igbo',
		nativeName: 'Asụsụ Igbo',
		portugueseName: 'Igbo'
	},
	{
		id: 'ik',
		name: 'Inupiaq',
		nativeName: 'Iñupiaq, Iñupiatun',
		portugueseName: 'Inupiaq'
	},
	{ id: 'io', name: 'Ido', nativeName: 'Ido', portugueseName: 'Ido' },
	{
		id: 'is',
		name: 'Icelandic',
		nativeName: 'Íslenska',
		portugueseName: 'Islandês'
	},
	{
		id: 'it',
		name: 'Italian',
		nativeName: 'Italiano',
		portugueseName: 'Italiano'
	},
	{
		id: 'iu',
		name: 'Inuktitut',
		nativeName: 'ᐃᓄᒃᑎᑐᑦ',
		portugueseName: 'Inuktitut'
	},
	{
		id: 'ja',
		name: 'Japanese',
		nativeName: '日本語 (にほんご／にっぽんご)',
		portugueseName: 'Japonês'
	},
	{
		id: 'jv',
		name: 'Javanese',
		nativeName: 'basa Jawa',
		portugueseName: 'Javanês'
	},
	{
		id: 'kl',
		name: 'Kalaallisut, Greenlandic',
		nativeName: 'kalaallisut, kalaallit oqaasii',
		portugueseName: 'Groenlandês'
	},
	{
		id: 'kn',
		name: 'Kannada',
		nativeName: 'ಕನ್ನಡ',
		portugueseName: 'Kanada'
	},
	{
		id: 'kr',
		name: 'Kanuri',
		nativeName: 'Kanuri',
		portugueseName: 'Kanuri'
	},
	{
		id: 'ks',
		name: 'Kashmiri',
		nativeName: 'कश्मीरी, كشميري‎',
		portugueseName: 'Caxemira'
	},
	{
		id: 'kk',
		name: 'Kazakh',
		nativeName: 'Қазақ тілі',
		portugueseName: 'Cazaque'
	},
	{
		id: 'km',
		name: 'Khmer',
		nativeName: 'ភាសាខ្មែរ',
		portugueseName: 'Khmer'
	},
	{
		id: 'ki',
		name: 'Kikuyu, Gikuyu',
		nativeName: 'Gĩkũyũ',
		portugueseName: 'Kikuyu'
	},
	{
		id: 'rw',
		name: 'Kinyarwanda',
		nativeName: 'Ikinyarwanda',
		portugueseName: 'Kinyarwanda'
	},
	{
		id: 'ky',
		name: 'Kirghiz, Kyrgyz',
		nativeName: 'кыргыз тили',
		portugueseName: 'Quirguiz'
	},
	{ id: 'kv', name: 'Komi', nativeName: 'коми кыв', portugueseName: 'Komi' },
	{ id: 'kg', name: 'Kongo', nativeName: 'KiKongo', portugueseName: 'Kongo' },
	{
		id: 'ko',
		name: 'Korean',
		nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
		portugueseName: 'Coreano'
	},
	{
		id: 'ku',
		name: 'Kurdish',
		nativeName: 'Kurdî, كوردی‎',
		portugueseName: 'Curdo'
	},
	{
		id: 'kj',
		name: 'Kwanyama, Kuanyama',
		nativeName: 'Kuanyama',
		portugueseName: 'Kwanyama'
	},
	{
		id: 'la',
		name: 'Latin',
		nativeName: 'latine, lingua latina',
		portugueseName: 'Latim'
	},
	{
		id: 'lb',
		name: 'Luxembourgish, Letzeburgesch',
		nativeName: 'Lëtzebuergesch',
		portugueseName: 'Luxemburguês'
	},
	{
		id: 'lg',
		name: 'Luganda',
		nativeName: 'Luganda',
		portugueseName: 'Luganda'
	},
	{
		id: 'li',
		name: 'Limburgish, Limburgan, Limburger',
		nativeName: 'Limburgs',
		portugueseName: 'Limburguês'
	},
	{
		id: 'ln',
		name: 'Lingala',
		nativeName: 'Lingála',
		portugueseName: 'Lingala'
	},
	{
		id: 'lo',
		name: 'Lao',
		nativeName: 'ພາສາລາວ',
		portugueseName: 'Laosiano'
	},
	{
		id: 'lt',
		name: 'Lithuanian',
		nativeName: 'lietuvių kalba',
		portugueseName: 'Lituano'
	},
	{
		id: 'lu',
		name: 'Luba-Katanga',
		nativeName: '',
		portugueseName: 'Luba-Katanga'
	},
	{
		id: 'lv',
		name: 'Latvian',
		nativeName: 'latviešu valoda',
		portugueseName: 'Letão'
	},
	{
		id: 'gv',
		name: 'Manx',
		nativeName: 'Gaelg, Gailck',
		portugueseName: 'Manx'
	},
	{
		id: 'mk',
		name: 'Macedonian',
		nativeName: 'македонски јазик',
		portugueseName: 'Macedônio'
	},
	{
		id: 'mg',
		name: 'Malagasy',
		nativeName: 'Malagasy fiteny',
		portugueseName: 'Malgaxe'
	},
	{
		id: 'ms',
		name: 'Malay',
		nativeName: 'bahasa Melayu, بهاس ملايو‎',
		portugueseName: 'Malaio'
	},
	{
		id: 'ml',
		name: 'Malayalam',
		nativeName: 'മലയാളം',
		portugueseName: 'Malaiala'
	},
	{
		id: 'mt',
		name: 'Maltese',
		nativeName: 'Malti',
		portugueseName: 'Maltês'
	},
	{
		id: 'mi',
		name: 'Māori',
		nativeName: 'te reo Māori',
		portugueseName: 'Maori'
	},
	{
		id: 'mr',
		name: 'Marathi (Marāṭhī)',
		nativeName: 'मराठी',
		portugueseName: 'Marathi'
	},
	{
		id: 'mh',
		name: 'Marshallese',
		nativeName: 'Kajin M̧ajeļ',
		portugueseName: 'Marshalês'
	},
	{
		id: 'mn',
		name: 'Mongolian',
		nativeName: 'монгол',
		portugueseName: 'Mongol'
	},
	{
		id: 'na',
		name: 'Nauru',
		nativeName: 'Ekakairũ Naoero',
		portugueseName: 'Nauruano'
	},
	{
		id: 'nv',
		name: 'Navajo, Navaho',
		nativeName: 'Diné bizaad, Dinékʼehǰí',
		portugueseName: 'Navajo'
	},
	{
		id: 'nb',
		name: 'Norwegian Bokmål',
		nativeName: 'Norsk bokmål',
		portugueseName: 'Norueguês Bokmål'
	},
	{
		id: 'nd',
		name: 'North Ndebele',
		nativeName: 'isiNdebele',
		portugueseName: 'Ndebele do Norte'
	},
	{
		id: 'ne',
		name: 'Nepali',
		nativeName: 'नेपाली',
		portugueseName: 'Nepali'
	},
	{
		id: 'ng',
		name: 'Ndonga',
		nativeName: 'Owambo',
		portugueseName: 'Ndonga'
	},
	{
		id: 'nn',
		name: 'Norwegian Nynorsk',
		nativeName: 'Norsk nynorsk',
		portugueseName: 'Norueguês Nynorsk'
	},
	{
		id: 'no',
		name: 'Norwegian',
		nativeName: 'Norsk',
		portugueseName: 'Norueguês'
	},
	{
		id: 'ii',
		name: 'Nuosu',
		nativeName: 'ꆈꌠ꒿ Nuosuhxop',
		portugueseName: 'Nuosu'
	},
	{
		id: 'nr',
		name: 'South Ndebele',
		nativeName: 'isiNdebele',
		portugueseName: 'Ndebele do Sul'
	},
	{
		id: 'oc',
		name: 'Occitan',
		nativeName: 'Occitan',
		portugueseName: 'Ocitano'
	},
	{
		id: 'oj',
		name: 'Ojibwe, Ojibwa',
		nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
		portugueseName: 'Ojíbua'
	},
	{
		id: 'cu',
		name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
		nativeName: 'ѩзыкъ словѣньскъ',
		portugueseName: 'Eslavo Eclesiástico Antigo'
	},
	{
		id: 'om',
		name: 'Oromo',
		nativeName: 'Afaan Oromoo',
		portugueseName: 'Oromo'
	},
	{ id: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ', portugueseName: 'Oriá' },
	{
		id: 'os',
		name: 'Ossetian, Ossetic',
		nativeName: 'ирон æвзаг',
		portugueseName: 'Ossétio'
	},
	{
		id: 'pa',
		name: 'Panjabi, Punjabi',
		nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
		portugueseName: 'Punjabi'
	},
	{ id: 'pi', name: 'Pāli', nativeName: 'पाऴि', portugueseName: 'Páli' },
	{ id: 'fa', name: 'Persian', nativeName: 'فارسی', portugueseName: 'Persa' },
	{
		id: 'pl',
		name: 'Polish',
		nativeName: 'polski',
		portugueseName: 'Polonês'
	},
	{
		id: 'ps',
		name: 'Pashto, Pushto',
		nativeName: 'پښتو',
		portugueseName: 'Pashto'
	},
	{
		id: 'qu',
		name: 'Quechua',
		nativeName: 'Runa Simi, Kichwa',
		portugueseName: 'Quéchua'
	},
	{
		id: 'rm',
		name: 'Romansh',
		nativeName: 'rumantsch grischun',
		portugueseName: 'Romanche'
	},
	{
		id: 'rn',
		name: 'Kirundi',
		nativeName: 'kiRundi',
		portugueseName: 'Kirundi'
	},
	{
		id: 'ro',
		name: 'Romanian, Moldavian, Moldovan',
		nativeName: 'română',
		portugueseName: 'Romeno, Moldavo'
	},
	{
		id: 'ru',
		name: 'Russian',
		nativeName: 'русский язык',
		portugueseName: 'Russo'
	},
	{
		id: 'sa',
		name: 'Sanskrit (Saṁskṛta)',
		nativeName: 'संस्कृतम्',
		portugueseName: 'Sânscrito'
	},
	{
		id: 'sc',
		name: 'Sardinian',
		nativeName: 'sardu',
		portugueseName: 'Sardo'
	},
	{
		id: 'sd',
		name: 'Sindhi',
		nativeName: 'सिन्धी, سنڌي، سندھی‎',
		portugueseName: 'Sindi'
	},
	{
		id: 'se',
		name: 'Northern Sami',
		nativeName: 'Davvisámegiella',
		portugueseName: 'Sami do Norte'
	},
	{
		id: 'sm',
		name: 'Samoan',
		nativeName: 'gagana faa Samoa',
		portugueseName: 'Samoano'
	},
	{
		id: 'sg',
		name: 'Sango',
		nativeName: 'yângâ tî sängö',
		portugueseName: 'Sango'
	},
	{
		id: 'sr',
		name: 'Serbian',
		nativeName: 'српски језик',
		portugueseName: 'Sérvio'
	},
	{
		id: 'gd',
		name: 'Scottish Gaelic; Gaelic',
		nativeName: 'Gàidhlig',
		portugueseName: 'Gaélico Escocês'
	},
	{ id: 'sn', name: 'Shona', nativeName: 'chiShona', portugueseName: 'Xona' },
	{
		id: 'si',
		name: 'Sinhala, Sinhalese',
		nativeName: 'සිංහල',
		portugueseName: 'Cingalês'
	},
	{
		id: 'sk',
		name: 'Slovak',
		nativeName: 'slovenčina',
		portugueseName: 'Eslovaco'
	},
	{
		id: 'sl',
		name: 'Slovene',
		nativeName: 'slovenščina',
		portugueseName: 'Esloveno'
	},
	{
		id: 'so',
		name: 'Somali',
		nativeName: 'Soomaaliga, af Soomaali',
		portugueseName: 'Somali'
	},
	{
		id: 'st',
		name: 'Southern Sotho',
		nativeName: 'Sesotho',
		portugueseName: 'Soto do Sul'
	},
	{
		id: 'su',
		name: 'Sundanese',
		nativeName: 'Basa Sunda',
		portugueseName: 'Sundanês'
	},
	{
		id: 'sw',
		name: 'Swahili',
		nativeName: 'Kiswahili',
		portugueseName: 'Suaíli'
	},
	{
		id: 'sv',
		name: 'Swedish',
		nativeName: 'svenska',
		portugueseName: 'Sueco'
	},
	{ id: 'ta', name: 'Tamil', nativeName: 'தமிழ்', portugueseName: 'Tâmil' },
	{
		id: 'te',
		name: 'Telugu',
		nativeName: 'తెలుగు',
		portugueseName: 'Telugu'
	},
	{
		id: 'tg',
		name: 'Tajik',
		nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
		portugueseName: 'Tadjique'
	},
	{ id: 'th', name: 'Thai', nativeName: 'ไทย', portugueseName: 'Tailandês' },
	{
		id: 'ti',
		name: 'Tigrinya',
		nativeName: 'ትግርኛ',
		portugueseName: 'Tigrínia'
	},
	{
		id: 'bo',
		name: 'Tibetan Standard, Tibetan, Central',
		nativeName: 'བོད་ཡིག',
		portugueseName: 'Tibetano Padrão, Tibetano, Central'
	},
	{
		id: 'tk',
		name: 'Turkmen',
		nativeName: 'Türkmen, Түркмен',
		portugueseName: 'Turcomano'
	},
	{
		id: 'tl',
		name: 'Tagalog',
		nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
		portugueseName: 'Tagalo'
	},
	{
		id: 'tn',
		name: 'Tswana',
		nativeName: 'Setswana',
		portugueseName: 'Tswana'
	},
	{
		id: 'to',
		name: 'Tonga (Tonga Islands)',
		nativeName: 'faka Tonga',
		portugueseName: 'Tonganês'
	},
	{
		id: 'tr',
		name: 'Turkish',
		nativeName: 'Türkçe',
		portugueseName: 'Turco'
	},
	{
		id: 'ts',
		name: 'Tsonga',
		nativeName: 'Xitsonga',
		portugueseName: 'Tsonga'
	},
	{
		id: 'tt',
		name: 'Tatar',
		nativeName: 'татарча, tatarça, تاتارچا‎',
		portugueseName: 'Tártaro'
	},
	{ id: 'tw', name: 'Twi', nativeName: 'Twi', portugueseName: 'Twi' },
	{
		id: 'ty',
		name: 'Tahitian',
		nativeName: 'Reo Tahiti',
		portugueseName: 'Taitiano'
	},
	{
		id: 'ug',
		name: 'Uighur, Uyghur',
		nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
		portugueseName: 'Uigur'
	},
	{
		id: 'uk',
		name: 'Ukrainian',
		nativeName: 'українська',
		portugueseName: 'Ucraniano'
	},
	{ id: 'ur', name: 'Urdu', nativeName: 'اردو', portugueseName: 'Urdu' },
	{
		id: 'uz',
		name: 'Uzbek',
		nativeName: 'zbek, Ўзбек, أۇزبېك‎',
		portugueseName: 'Uzbeque'
	},
	{
		id: 've',
		name: 'Venda',
		nativeName: 'Tshivenḓa',
		portugueseName: 'Venda'
	},
	{
		id: 'vi',
		name: 'Vietnamese',
		nativeName: 'Tiếng Việt',
		portugueseName: 'Vietnamita'
	},
	{
		id: 'vo',
		name: 'Volapük',
		nativeName: 'Volapük',
		portugueseName: 'Volapuque'
	},
	{ id: 'wa', name: 'Walloon', nativeName: 'Walon', portugueseName: 'Valão' },
	{ id: 'cy', name: 'Welsh', nativeName: 'Cymraeg', portugueseName: 'Galês' },
	{ id: 'wo', name: 'Wolof', nativeName: 'Wollof', portugueseName: 'Wolof' },
	{
		id: 'fy',
		name: 'Western Frisian',
		nativeName: 'Frysk',
		portugueseName: 'Frísio Ocidental'
	},
	{
		id: 'xh',
		name: 'Xhosa',
		nativeName: 'isiXhosa',
		portugueseName: 'Xhosa'
	},
	{
		id: 'yi',
		name: 'Yiddish',
		nativeName: 'ייִדיש',
		portugueseName: 'Yídish'
	},
	{
		id: 'yo',
		name: 'Yoruba',
		nativeName: 'Yorùbá',
		portugueseName: 'Iorubá'
	},
	{
		id: 'za',
		name: 'Zhuang, Chuang',
		nativeName: 'Saɯ cueŋƅ, Saw cuengh',
		portugueseName: 'Zhuang, Chuang'
	}
]

export default languageList
