import { locationConstants } from '../_constants'

export function countries(state = { countries: [] }, action) {
	switch (action.type) {
		case locationConstants.COUNTRY_REQUEST:
			return {
				fetchingCountries: true,
				...state
			}
		case locationConstants.COUNTRY_SUCCESS: {
			return {
				countries: action.rows
			}
		}
		case locationConstants.COUNTRY_FAILURE:
			return {}
		default:
			return state
	}
}
