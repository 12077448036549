import dayjs from 'dayjs'

import { getStatusName } from '../../utils/functions'

const FormatCellValue = ({
	value,
	format = null,
	status = false,
	customRender = null
}) => {
	if (!value && value !== 0) {
		return ''
	}
	if (format) {
		return dayjs(value).format(format)
	}

	if (status !== false) {
		return getStatusName(value)
	}

	if (customRender) {
		return customRender(value)
	}

	return value
}

export default FormatCellValue
