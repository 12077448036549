import React, { createContext, useContext, useState } from 'react'
import filter from './filter.json'

export const FilterContext = createContext({
	filter: {},
	setFilter: null,
	resetFilter: null
})

const initialState = filter
export const FilterProvider = ({ children }) => {
	const [filter, setFilter] = useState(initialState)
	const resetFilter = () => {
		setFilter(initialState)
	}
	const handleSetFilter = (props) => {
		setFilter(props)
	}

	return (
		<FilterContext.Provider
			value={{
				filter,
				setFilter: handleSetFilter,
				resetFilter
			}}
		>
			{children}
		</FilterContext.Provider>
	)
}

export const useFilter = () => useContext(FilterContext)
