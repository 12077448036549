import styled from 'styled-components'
import theme from '../../styles/theme'
import { styled as muiStyled } from '@mui/system'
import { DialogActions, DialogContent } from '@mui/material'
import * as CarouselStyle from '../../components/Carousel/styles'

export const ArrowLeft = styled(CarouselStyle.ArrowLeft).attrs((props) => ({
	hidden: props.hidden
}))`
	opacity: ${({ hidden }) => (hidden ? 0 : 1)};
	:hover {
		cursor: ${({ hidden }) => (hidden ? 'default' : 'pointer')};
	}
	position: fixed;
	height: 100vh;
	top: 0;
`
export const ArrowRight = styled(CarouselStyle.ArrowRight).attrs((props) => ({
	hidden: props.hidden
}))`
	opacity: ${({ hidden }) => (hidden ? 0 : 1)};
	:hover {
		cursor: ${({ hidden }) => (hidden ? 'default' : 'pointer')};
	}
	position: fixed;
	height: 100vh;
	top: 0;
`

export const Background = styled.div`
	z-index: 9999999999999;
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	justify-content: center;
	align-items: center;
`
export const StyledDialogContent = muiStyled(DialogContent)`
  padding: 0;
`
export const StyledDialogActions = muiStyled(DialogActions)`
  padding: 0;
`
export const IconContainer = styled.div`
	display: flex;
	position: absolute;
	top: 12px;
	right: 12px;
	& > .Icon {
		font-size: 24px;
		color: white;
		padding: 13px 18px;
		position: absolute;
		z-index: 99999;
		cursor: pointer;
		border-radius: 64px;
		background: ${({ theme }) => theme.colors.secondary};
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	}
	& > .fa-times {
		right: 0px;
		top: 0px;
	}
	& > .fa-arrow-right {
		right: -63px;
		top: 30vh;
	}
	& > .fa-arrow-left {
		left: -63px;
		top: 30vh;
	}
	@media screen and (max-width: 1050px) {
		& > .fa-times {
			right: 44px;
			top: 4px;
		}
	}
`

export const Detail = styled.div`
	width: 900px;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	@media screen and (max-width: 1050px) {
		width: calc(100% - 80px);
		margin: 0 40px;
	}
`
export const TopWrapper = styled.div`
	display: flex;
	z-index: 9;
	position: absolute;
	top: 0;
	width: 100%;
	flex-direction: column;
	padding: 4px 12px 4px 12px;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	background: #efebe9;
`

export const ContentTypeWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	i {
		font-size: 16px;
		color: #194d4894;
	}
`
export const Type = styled.p`
	color: #194d48;
	font-size: 12px;
`

export const Overview = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 16px;
	gap: 8px;
	hr {
		color: #fff;
	}
	::-webkit-scrollbar {
		width: 0.25em;
	}
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-thumb {
		background-color: rgb(25, 76, 71);
	}
`
export const Info = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 4px;
`
export const Themes = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`
export const ThemeChip = styled.a`
	display: flex;
	padding: 2px 12px;
	align-items: center;
	gap: 6px;
	border-radius: 12px;
	border: 1px solid #d7ccc8;
	background-color: #fff;
	color: #194d48;
	text-align: center;
	font-family: Roboto;
	font-size: 12px;
	transition: all 0.3s ease;
	:hover {
		background-color: #194d48;
		color: #fff;
	}
`

export const Cover = styled.div`
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	width: 100%;
	height: 350px;
	@media screen and (max-width: 782px) {
		height: 220px;
	}
`

export const InfoTitle = styled.span`
	width: 100%;
	color: ${() => theme.colors.dark};
	font-weight: bold;
`

export const InfoText = styled.span`
	color: ${() => theme.colors.dark};
`

export const InfoWrapper = styled.span`
	width: 100%;
`

export const Short = styled.p`
	font-size: 14px;
	width: 100%;
	color: ${() => theme.colors.dark};
`

export const Title = styled.p`
	width: 100%;
	font-size: 18px;
	font-weight: 700;
	color: ${() => theme.colors.primary};
	margin: 0;
	padding-top: 0 !important;
`

export const Date = styled.div`
	margin-left: 20px;
	padding-bottom: 10px;
	color: ${() => theme.colors.dark};
	font-weight: bold;
`

export const FileLink = styled.div`
	display: flex;
	height: 79px;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #fff;
	align-items: center;
	justify-content: space-between;
	padding: 16px 16px;
	gap: 8px;
	position: relative;
	z-index: 999;
`

export const GoToContentButton = styled.a`
	color: #194d48;
	white-space: nowrap;
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: 1px solid #194d48;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	height: 36px;
	padding: 0px 16px;
	:hover {
		color: #194d48;
	}
`
export const SharingButton = styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	color: ${() => theme.colors.primary};
	gap: 10px;
	border-radius: 100px;
	background: rgba(0, 0, 0, 0.05);
	:hover {
		cursor: pointer;
	}
`

export const SharingBar = styled.div`
	position: relative;
	width: 100%;
	margin-top: -77px;
	transition: margin 0.5s;
	&.clicked {
		margin-top: 0px;
	}
`
export const CopyLink = styled.a`
	text-decoration: underline;
	color: ${() => theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
`

export const DownloadButton = styled.a`
	height: 36px;
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 0px 16px;
	border-radius: 4px;
	background: #194d48;
	color: #fff;
	padding: 0px 16px;
	border: 1px solid #194d48;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	:hover {
		color: #fff;
	}
`
export const ContentFileLink = styled.div.attrs((props) => ({
	isLink: props.isLink
}))`
	flex: 1;
	transition: background-color 0.25s linear;
	font-size: 16px;
	border-radius: 4px;
	padding: 10px;
	:hover {
		cursor: pointer;
		${({ isLink, theme }) =>
			isLink
				? `
			background-color: ${theme.colors.secondaryHover};
			`
				: `
			background-color: ${theme.colors.primaryHover};
			`}
		color: white;
	}
	${({ isLink, theme }) =>
		isLink
			? `
			background-color: ${theme.colors.secondary};
			min-width: 140px;
			`
			: `
			margin-right: 10px;
			background-color: ${theme.colors.primary}; `}
`

export const InfoSerie = styled.div`
	flex: 1;
	padding: 0 20px;
	h2 {
		color: ${() => theme.colors.secondary};
	}
	> div {
		max-height: 350px;
	}
`

export const Episodes = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	h2 {
		color: ${() => theme.colors.primary};
		font-weight: bold;
	}
	@media screen and (max-width: 1280px) {
		margin-left: 20px;
	}
`
export const List = styled.div`
	flex-direction: column;
	width: 800px;
	overflow-x: auto;
	height: 400px;
	margin-right: 2px;
	@media screen and (max-width: 1280px) {
		overflow: unset;
		height: unset;
		width: unset;
	}
	.title {
		color: ${() => theme.colors.primary};
	}
	::-webkit-scrollbar {
		width: 0.25em;
	}
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-thumb {
		background-color: ${() => theme.colors.primary};
	}
`

export const Wrapper = styled.div`
	width: 100%;
	overflow: auto;
	height: 80vh;
	max-height: 850px;
	background-color: #fff;
	position: relative;
	z-index: 999;
`

export const Item = styled.div`
	display: flex;
	color: white;
	:not(:last-child) {
		border-bottom: 1px solid ${() => theme.colors.light};
	}
	margin: 5px 0;
	transition: all 0.25s linear;
	&&.selected,
	:hover {
		background-color: ${() => theme.colors.primaryHover};
		cursor: pointer;
	}
`
export const Image = styled.div.attrs((props) => ({
	img: props.img
}))`
	height: 120px;
	min-width: 200px;
	background-image: url('${({ img }) => img}');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 5px;
	padding: 20px;
	@media screen and (max-width: 800px) {
		display: none;
	}
`

export const Description = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 20px;
	position: relative;
	color: ${() => theme.colors.dark};
	font-weight: bold;
	p {
		font-size: 14px;
	}
	.minutes {
		position: absolute;
		bottom: 5px;
		right: 0;
	}
`
