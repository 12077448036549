import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Header, Image, Modal, Select } from 'semantic-ui-react'
import { userActions } from '../../../../app/_actions'

const OPTIONS = [
	{ key: 'ROLE_USER', value: 'ROLE_USER', text: 'Usuário' },
	{
		key: 'ROLE_ORGANIZATION',
		value: 'ROLE_ORGANIZATION',
		text: 'Organização'
	},
	{ key: 'ROLE_CURADOR', value: 'ROLE_CURADOR', text: 'Curador' },
	{
		key: 'ROLE_CURADOR_CHEFE',
		value: 'ROLE_CURADOR_CHEFE',
		text: 'Curador chefe'
	},
	{ key: 'ROLE_ADMIN', value: 'ROLE_ADMIN', text: 'Administrador' }
]

const EditUser = ({ open, setOpen, user, onUserUpate }) => {
	const dispatch = useDispatch()
	const [role, setRole] = useState([])

	useEffect(() => {
		if (user.roles && user.roles.length) {
			const roleNames = user.roles.map((r) => r.name)
			setRole(roleNames)
		} else {
			setRole([])
		}
	}, [open, user])

	const handleSubmit = () => {
		dispatch(
			userActions.updateUserRole(user.id, { roles: role }, onUserUpate)
		)
		setOpen(false)
	}

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			closeIcon
		>
			<Modal.Header>Editar informações do usuário</Modal.Header>
			<Modal.Content image={user.photo_id}>
				{user.photo_id && (
					<Image size="medium" src={user.photo_id} wrapped />
				)}
				<Modal.Description>
					<Header>Informações de &quot;{user.name}&quot;</Header>
					<Form size="massive">
						<Form.Field>
							<label>Escolha o perfil da conta selecionada</label>
							<Select
								placeholder="Selecione o perfil do usuário"
								options={OPTIONS}
								// @ts-ignore
								onChange={(_, { value }) => setRole(value)}
								value={role}
								multiple
							/>
						</Form.Field>
					</Form>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={() => setOpen(false)}>
					Cancelar
				</Button>
				<Button
					content="Salvar informações"
					labelPosition="right"
					icon="checkmark"
					onClick={handleSubmit}
					positive
				/>
			</Modal.Actions>
		</Modal>
	)
}

export default EditUser
