import { STATUS } from './status'

export const ACTIONS = [
	{
		popup: 'Editar/Enviar para aprovação',
		icon: 'edit',
		color: 'blue',
		type: 'edit',
		requiredStatus: [
			STATUS.AGUARDANDO_APROVACAO,
			STATUS.APROVADO,
			STATUS.SOLICITADA_CORRECAO,
			STATUS.RASCUNHO
		],
		enableStatus: [
			STATUS.AGUARDANDO_APROVACAO,
			STATUS.SOLICITADA_CORRECAO,
			STATUS.RASCUNHO,
			STATUS.REJEITADA_ANALISE
		]
	},
	{
		popup: 'Excluir',
		icon: 'trash',
		color: 'red',
		type: 'delete',
		requiredStatus: [
			STATUS.AGUARDANDO_APROVACAO,
			STATUS.APROVADO,
			STATUS.REJEITADO,
			STATUS.SOLICITADA_CORRECAO,
			STATUS.RASCUNHO
		]
	},
	{
		popup: 'Enviar para aprovação',
		icon: 'check circle outline',
		color: 'green',
		type: 'publish',
		requiredStatus: [STATUS.RASCUNHO],
		enableStatus: [STATUS.SOLICITADA_CORRECAO, STATUS.RASCUNHO]
	},
	{
		popup: 'Definir curador responsável',
		icon: 'user plus',
		color: 'blue',
		type: 'curator'
	},
	{
		popup: 'Aprovar ou Rejeitar',
		icon: 'rocket',
		color: 'orange',
		type: 'approve'
	},
	{
		popup: 'Devolver para Curador@ Chefe',
		icon: 'sync',
		color: 'red',
		type: 'reject-analysis'
	}
]
