import React from 'react'

import * as S from '../styles'
import { truncate } from '../../../utils/functions'
import CollectionContentIndicationsList from '../../../components/PublicPages/CollectionContentIndicationsList'

const CollectionInfo = ({ item, onCollectionContentClick }) => {
	return (
		<>
			<S.Overview>
				<S.Title>{item.title}</S.Title>
				<S.Short>{truncate(item.description, 525)}</S.Short>
			</S.Overview>
			<CollectionContentIndicationsList
				item={item}
				onCollectionContentClick={onCollectionContentClick}
			/>
		</>
	)
}

export default CollectionInfo
