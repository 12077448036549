import React from 'react'
import cn from 'classnames'
import { isNumber } from 'advanced-cropper'
import { CustomCropperSlider } from '../CustomCropperSlider'
import { ZoomInIcon } from './icons/ZoomInIcon'
import { ZoomOutIcon } from './icons/ZoomOutIcon'
import './styles.css'

export const CustomCropperNavigation = ({ className, onZoom, zoom }) => {
	const onZoomIn = () => {
		if (onZoom && isNumber(zoom)) {
			onZoom(Math.min(1, zoom + 0.25), true)
		}
	}

	const onZoomOut = () => {
		if (onZoom && isNumber(zoom)) {
			onZoom(Math.max(0, zoom - 0.25), true)
		}
	}

	return (
		<div className={cn('absolute-zoom-cropper-navigation', className)}>
			<button
				className="absolute-zoom-cropper-navigation__button"
				onClick={onZoomOut}
			>
				<ZoomOutIcon />
			</button>
			<CustomCropperSlider
				value={zoom}
				onChange={onZoom}
				className="absolute-zoom-cropper-navigation__slider"
			/>
			<button
				className="absolute-zoom-cropper-navigation__button"
				onClick={onZoomIn}
			>
				<ZoomInIcon />
			</button>
		</div>
	)
}
