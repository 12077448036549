import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	@media screen and (max-width: 900px) {
		flex-direction: column;
		gap: 32px;
		.ui.big.form {
			width: 100%;
		}
	}
`
