import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { contentDataActions } from '../../../../app/_actions'
import ContentTypeList from '../../ContentTypeList'

function EditContentTypeAndFile({
	inputs,
	onChange,
	onChangeFile,
	messageError
}) {
	const dispatch = useDispatch()
	const fileInput = React.useRef(null)

	const { t } = useTranslation()

	// @ts-ignore
	const { types } = useSelector((state) => state.types)

	function onChangeType(value) {
		onChange({ target: { name: 'content_type_id', value } })
	}

	useEffect(() => {
		if (!types || types.length === 0) {
			dispatch(contentDataActions.getAllTypes())
		}
	}, [dispatch, types])

	return (
		<>
			<S.StepTitle>
				{t('dashboard.content-modal.modal-title', 'Enviar conteúdo')}
			</S.StepTitle>
			<S.GridInputsContainer>
				<InputLabel>
					{t(
						'dashboard.content-modal.content-types',
						'Selecione o tipo de conteúdo'
					) +
						' ' +
						t('dashboard.mandatory', '(obrigatório)')}
				</InputLabel>
				<S.ContentTypeListContainer>
					<ContentTypeList
						rows={types}
						active={inputs['content_type_id']}
						onClick={onChangeType}
					/>
				</S.ContentTypeListContainer>
			</S.GridInputsContainer>
			<S.ContentInputsContainer>
				<S.InputContainer>
					<InputLabel>
						{t('dashboard.content-modal.url', 'URL do conteúdo')}
					</InputLabel>
					<TextField
						name="content_link"
						value={inputs.content_link}
						onChange={onChange}
						placeholder="https://exemplo.com/documento.pdf"
					/>
				</S.InputContainer>
				<S.InputContainer>
					<InputLabel>
						{t('dashboard.content-modal.file', 'Upload do arquivo')}
					</InputLabel>
					<S.FileInput>
						<S.FileButton
							variant="contained"
							onClick={() =>
								fileInput.current
									? fileInput.current.click()
									: null
							}
							size="medium"
						>
							{inputs.file ? 'Trocar...' : 'Procurar...'}
						</S.FileButton>
						<input
							type="file"
							id="file"
							ref={fileInput}
							onChange={onChangeFile}
							style={{ display: 'none' }}
						/>
						{inputs.file ? (
							<a
								style={{
									color: 'black',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap'
								}}
								target="blank"
								href={'/' + inputs.file.file}
							>
								{inputs.file.name}
							</a>
						) : (
							<S.FileInputPlaceholder>
								Nenhum arquivo adicionado
							</S.FileInputPlaceholder>
						)}
					</S.FileInput>
				</S.InputContainer>
			</S.ContentInputsContainer>
			{messageError && (
				<Alert sx={{ width: '100%' }} severity="error">
					{messageError}
				</Alert>
			)}
		</>
	)
}

export default EditContentTypeAndFile
