import { carouselConstants } from '../_constants'

export function carousel(
	state = {
		carousels: []
	},
	action
) {
	switch (action.type) {
		case carouselConstants.CLEAN:
			return {
				carousels: state.carousels.filter((carousel) =>
					carousel.key === action.carouselIndex.toString()
						? {
								...carousel,
								content: null,
								has_next: false,
								has_prev: false,
								totalResults: 0,
								page: 0
						  }
						: carousel
				)
			}
		case carouselConstants.SUCCESS: {
			return {
				carousels:
					state.carousels.length === 0
						? [
								{
									key: `${action.carouselIndex}`,
									...action.data
								}
						  ]
						: state.carousels.find(
								(carousel) =>
									carousel.key ===
									action.carouselIndex.toString()
						  )
						? state.carousels.map((carousel) =>
								carousel.key === action.carouselIndex.toString()
									? { ...carousel, ...action.data }
									: carousel
						  )
						: [
								...state.carousels,
								{
									key: `${action.carouselIndex}`,
									...action.data
								}
						  ]
			}
		}
		case carouselConstants.LOAD_MORE: {
			return {
				carousels: state.carousels.map((carousel) =>
					carousel.key === action.carouselIndex.toString()
						? {
								...carousel,
								content: [
									...carousel.content,
									...action.data.content
								],
								has_next: action.data.has_next,
								page: action.data.page
						  }
						: carousel
				)
			}
		}
		case carouselConstants.FAILURE:
		default:
			return state
	}
}
