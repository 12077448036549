import { userConstants } from '../_constants'

const initialAuthState = {
	loggingIn: false,
	user: null,
	loggedIn: false,
	fetchingForgot: false,
	emailSent: false,
	fetchingNewPassword: false,
	isVisitor: false
}

export function authentication(state = initialAuthState, action) {
	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return {
				...initialAuthState,
				loggingIn: true
			}
		case userConstants.LOGIN_SUCCESS: {
			return {
				...initialAuthState,
				loggedIn: true,
				user: action.user
			}
		}
		case userConstants.LOGIN_FAILURE:
			return {
				...initialAuthState,
				loggedIn: false
			}
		case userConstants.FORGOT_REQUEST:
			return {
				...initialAuthState,
				fetchingForgot: true
			}
		case userConstants.FORGOT_SUCCESS: {
			return {
				...initialAuthState,
				emailSent: true
			}
		}
		case userConstants.FORGOT_FAILURE:
			return initialAuthState
		case userConstants.NEWPASSWORD_REQUEST:
			return {
				...initialAuthState,
				fetchingNewPassword: true
			}
		case userConstants.NEWPASSWORD_SUCCESS: {
			return {
				...initialAuthState,
				changedNewPassword: true
			}
		}
		case userConstants.NEWPASSWORD_FAILURE:
			return initialAuthState
		case userConstants.LOGOUT:
			return initialAuthState
		case userConstants.IS_VISITOR:
			return {
				...initialAuthState,
				isVisitor: true
			}
		default:
			return state
	}
}
