import React from 'react'
import { Header } from 'semantic-ui-react'
import ContentToApproval from '../DashboardPanel/ContentToApproval'

function ManageAll() {
	return (
		<div style={{ width: '100%' }}>
			<Header>Conteúdos pendentes de aprovação</Header>
			<ContentToApproval isAdmin={true} />
		</div>
	)
}

export default ManageAll
