import LoadingButton from '@mui/lab/LoadingButton'
import styled from 'styled-components'

export const Background = styled.div`
	z-index: 9999999999999;
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	justify-content: center;
	align-items: center;
`

export const Wrapper = styled.div`
	width: 100%;
	.MuiStepLabel-root {
		gap: 8px;
	}
	.MuiStepLabel-label {
		font-size: 18px;
		display: flex;
		flex-direction: column;
	}
	.MuiTypography-caption {
		font-size: 13px;
		color: var(--black-06, rgba(0, 0, 0, 0.6));
	}
	.MuiStep-root {
		padding: 18px !important;
	}
	.MuiStepIcon-text {
		font-size: 13px;
	}
	.MuiSvgIcon-root {
		transform: scale(1.6);
	}
	.MuiSvgIcon-root.Mui-active {
		color: ${({ theme }) => theme.colors.secondary};
	}
`

export const StepperContainer = styled.div`
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const StepContainer = styled.div`
	display: flex;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	overflow: auto;
	height: 55vh;
	max-height: 620px;
`

export const Panel = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 70% !important;
	max-width: 1300px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.2);

	form {
		width: 100%;
	}
`

export const WrapInput = styled.div`
	label {
		font-weight: bold;
	}
	> div {
		margin: 0 0 1em;
	}
`

export const ContentFooter = styled.div`
	display: flex;
	padding: 16px 24px 24px 24px;
	justify-content: space-between;
	align-items: flex-end;
`

export const FooterButton = styled(LoadingButton)``
