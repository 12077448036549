import { Button } from '@mui/material'
import styled from 'styled-components'

export const StepTitle = styled.span`
	font-family: Roboto;
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.primary};
	@media screen and (max-width: 782px) {
		order: 1;
	}
`

export const GridInputsContainer = styled.div`
	width: 100%;
	@media screen and (max-width: 782px) {
		order: 3;
	}
`

export const ContentInputsContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-stretch;
	gap: 16px;
	@media screen and (max-width: 782px) {
		flex-direction: column;
		order: 2;
	}
`

export const ContentTypeListContainer = styled(ContentInputsContainer)`
	@media screen and (max-width: 782px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-right: 10px;
	padding-vertical: 16px;
	max-width: 50%;
	flex: 1 0 0;
	.MuiFormControl-root {
		width: 100%;
	}
	input::placeholder {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.35);
	}
	input {
		color: rgba(0, 0, 0, 0.65) !important;
	}
	@media screen and (max-width: 782px) {
		width: 100%;
		max-width: unset;
		padding-right: 0;
	}
`

export const FileInput = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
`

export const FileButton = styled(Button)`
	&.MuiButtonBase-root {
		text-transform: none;
		font-size: 15px;
		min-width: 100px;
	}
`

export const FileInputPlaceholder = styled.span`
	color: rgba(0, 0, 0, 0.35);
`
