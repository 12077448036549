import styled from 'styled-components'
import theme from '../../../styles/theme'

export const StyledHeader = styled.span`
	font-family: Roboto;
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
	color: ${() => theme.colors.primary};
	padding: 24px 0px 16px 0px;
`

export const AcceptTerm = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
	a {
		color: ${() => theme.colors.secondary};
	}
`
export const InputContainer = styled.div`
	margin: 0 !important;
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	color: ${() => theme.colors.black06};
`

export const Label = styled.label`
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	color: ${() => theme.colors.black06};
	margin: 0;
`

const dot = () => ({
	alignItems: 'center',
	display: 'flex',
	fontSize: '15px',
	borderColor: '#85b7d9',
	margin: 0
})

const placeholder = () => ({
	fontFamily: 'Roboto',
	fontSize: '15px',
	color: 'rgb(199 199 204)',
	margin: 0
})

const single = () => ({
	fontFamily: 'Roboto',
	fontSize: '15px',
	margin: 0
})

export const colourStyles = {
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	input: (styles) => ({ ...styles, ...dot() }),
	placeholder: (styles) => ({ ...styles, ...placeholder() }),
	singleValue: (styles) => ({ ...styles, ...single() }),
	option: (styles) => ({ ...styles, ...single() })
}
