import React from 'react'

// Content:
import { ReactComponent as ArtigoIcon } from '../assets/icons/artigo.svg'
import { ReactComponent as PublicacaoIcon } from '../assets/icons/publicacao.svg'
import { ReactComponent as BaseDadosIcon } from '../assets/icons/base_de_dados.svg'
import { ReactComponent as NoticiaIcon } from '../assets/icons/noticia.svg'
import { ReactComponent as WebToolIcon } from '../assets/icons/web_tool.svg'
import { ReactComponent as FiguraIcon } from '../assets/icons/figura.svg'
import { ReactComponent as VideoIcon } from '../assets/icons/video.svg'
import { ReactComponent as OutroIcon } from '../assets/icons/outro.svg'

// Collection:
import { ReactComponent as SeriesIcon } from '../assets/icons/serie.svg'
import { ReactComponent as CursoIcon } from '../assets/icons/curso.svg'
import { ReactComponent as ProjetoIcon } from '../assets/icons/projeto.svg'

const contentTypeIcons = {
	39: ArtigoIcon,
	23: OutroIcon,
	41: PublicacaoIcon,
	40: BaseDadosIcon,
	36: NoticiaIcon,
	25: WebToolIcon,
	42: FiguraIcon,
	28: VideoIcon,
	100: SeriesIcon,
	101: ProjetoIcon,
	102: CursoIcon
}

const ContentOrCollectionTypeIcon = ({ id, size = 16, color = '#194D48' }) => {
	if (!id) {
		return <></>
	}
	const Icon = contentTypeIcons[id]
	if (!Icon) {
		console.error(`There is no icon with typeId = ${id}`)
		return <></>
	}
	return (
		<Icon
			size={size}
			fill={color}
			style={{ width: `${size}px`, height: `${size}px` }}
		/>
	)
}

export default ContentOrCollectionTypeIcon
