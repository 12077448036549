import { orgsConstants } from '../_constants'
import { orgsService } from '../_services'
import { alertActions } from './alert.actions'

export const orgsActions = {
	getAllPaginated,
	getAll,
	delete: _delete,
	create,
	createBatch,
	addTemporaryMember
}

function _delete(id, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request(id))
			orgsService.delete(id).then(
				(data) => {
					dispatch(success(id))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(alertActions.error('Erro'))
					dispatch(failure(id, error.toString()))
					reject()
				}
			)
		})
	}

	function request(id) {
		return { type: orgsConstants.DELETE_REQUEST, id }
	}
	function success(id) {
		return { type: orgsConstants.DELETE_SUCCESS, id }
	}
	function failure(id, error) {
		return { type: orgsConstants.DELETE_FAILURE, id, error }
	}
}

function getAllPaginated(page, perPage, q) {
	return (dispatch) => {
		dispatch(request())

		orgsService.getAllPaginated(page, perPage, q).then(
			(rows) => dispatch(success(rows)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: orgsConstants.GETALL_REQUEST }
	}
	function success(rows) {
		return { type: orgsConstants.GETALL_SUCCESS, rows }
	}
	function failure(error) {
		return { type: orgsConstants.GETALL_FAILURE, error }
	}
}

function getAll() {
	return (dispatch) => {
		dispatch(request())

		orgsService.getAll().then(
			(rows) => dispatch(success(rows)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: orgsConstants.GET_ALL_REQUEST }
	}
	function success(rows) {
		return { type: orgsConstants.GET_ALL_SUCCESS, rows }
	}
	function failure(error) {
		return { type: orgsConstants.GET_ALL_FAILURE, error }
	}
}

function addTemporaryMember(newMember) {
	return (dispatch) => {
		dispatch(success(newMember))
	}

	function success(newMember) {
		return { type: orgsConstants.ADD_TEMPORARY_SUCCESS, newMember }
	}
}

function create(newMember, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			orgsService.create(newMember).then(
				(data) => {
					dispatch(success(data))
					dispatch(
						alertActions.success('Organização criada com sucesso')
					)
					resolve(data)
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject(error)
				}
			)
		})
	}

	function request() {
		return { type: orgsConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: orgsConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: orgsConstants.CREATE_FAILURE, error }
	}
}

function createBatch(newOrgs, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			orgsService.createBatch(newOrgs).then(
				(data) => {
					dispatch(success(data))
					dispatch(
						alertActions.success(
							newOrgs.orgs.length === 1
								? `Organização "${newOrgs.orgs[0].name}" criada com sucesso`
								: 'Organizações criadas com sucesso'
						)
					)
					resolve(data)
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject(error)
				}
			)
		})
	}

	function request() {
		return { type: orgsConstants.CREATE_BATCH_REQUEST }
	}
	function success(data) {
		return { type: orgsConstants.CREATE_BATCH_SUCCESS, data }
	}
	function failure(error) {
		return { type: orgsConstants.CREATE_BATCH_FAILURE, error }
	}
}
