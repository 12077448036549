import { applyMiddleware } from '@reduxjs/toolkit'
import { legacy_createStore as createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import rootReducer from './_reducers'

// const loggerMiddleware = createLogger({
// 	predicate: () => process.env.NODE_ENV === 'development'
// })

// const store = createStore(
// 	rootReducer,
// 	applyMiddleware(thunkMiddleware, loggerMiddleware)
// )
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

export default store

export const createProtejaStore = () =>
	createStore(rootReducer, applyMiddleware(thunkMiddleware))
