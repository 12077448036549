import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	background-color: #fff;
	border-radius: 4px;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	svg {
		width: 16px;
		height: 16px;
	}
`
export const PaginationContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`
