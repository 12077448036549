import { collectionsConstants } from '../_constants'
import { collectionsService } from '../_services'
import { alertActions } from './alert.actions'

export const collectionsActions = {
	getAllCollections,
	getCollectionsByType,
	loadMoreCollectionsByType,
	createCollectionByType,
	getCollectionById,
	updateCollection,
	getAllCollectionsByUser,
	getCollectionsByTypeAndUser,
	cleanCollection,
	setCover,
	deleteCollection,
	getCollectionByUrl,
	sendCollectionToApproval,
	getAllCollectionsToApproval,
	getAllCollectionsToApprovalByType,
	setCurator,
	publishCollection,
	defineContent,
	actionContent
}

function getAllCollections(page, perPage) {
	return (dispatch) => {
		dispatch(request())
		collectionsService.getAllCollections(page, perPage).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: collectionsConstants.REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.SUCCESS, data, page }
	}
	function failure(error) {
		return { type: collectionsConstants.FAILURE, error }
	}
}

function getCollectionsByType(
	collectionType,
	page,
	perPage,
	sortColumn = null,
	sortDirection = null,
	search = null
) {
	return (dispatch) => {
		dispatch(request())
		collectionsService
			.getCollectionsByType(
				collectionType,
				page,
				perPage,
				sortColumn,
				sortDirection,
				search
			)
			.then(
				(data) => {
					dispatch(success(data))
				},
				(error) => {
					dispatch(failure(error.toString()))
				}
			)
	}

	function request() {
		return { type: collectionsConstants.REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.SUCCESS, data, page }
	}
	function failure(error) {
		return { type: collectionsConstants.FAILURE, error }
	}
}

function loadMoreCollectionsByType(collectionType, page, perPage) {
	return (dispatch) => {
		dispatch(request())
		collectionsService
			.getCollectionsByType(collectionType, page, perPage)
			.then(
				(data) => {
					dispatch(success(data))
				},
				(error) => {
					dispatch(failure(error.toString()))
				}
			)
	}

	function request() {
		return { type: collectionsConstants.LOAD_MORE_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.LOAD_MORE_SUCCESS, data, page }
	}
	function failure(error) {
		return { type: collectionsConstants.LOAD_MORE_FAILURE, error }
	}
}

function getAllCollectionsByUser(idUser, page, perPage) {
	return (dispatch) => {
		dispatch(request())

		collectionsService.getAllCollectionsByUser(idUser, page, perPage).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: collectionsConstants.USER_COLLECTION_REQUEST }
	}
	function success(data) {
		return {
			type: collectionsConstants.USER_COLLECTION_SUCCESS,
			data,
			page
		}
	}
	function failure(error) {
		return { type: collectionsConstants.USER_COLLECTION_FAILURE, error }
	}
}

function getCollectionsByTypeAndUser(
	idUser,
	collectionType,
	page,
	perPage,
	sortColumn,
	sortDirection,
	search
	// onlyPublished = false
) {
	return (dispatch) => {
		dispatch(request())

		collectionsService
			.getCollectionsByTypeAndUser(
				idUser,
				collectionType,
				page,
				perPage,
				sortColumn,
				sortDirection,
				search
				// onlyPublished
			)
			.then(
				(data) => dispatch(success(data)),
				(error) => dispatch(failure(error.toString()))
			)
	}

	function request() {
		return { type: collectionsConstants.USER_COLLECTION_REQUEST }
	}
	function success(data) {
		return {
			type: collectionsConstants.USER_COLLECTION_SUCCESS,
			data,
			page
		}
	}
	function failure(error) {
		return { type: collectionsConstants.USER_COLLECTION_FAILURE, error }
	}
}

function getCollectionById(collectionId) {
	return (dispatch) => {
		dispatch(request())
		collectionsService.getCollectionById(collectionId).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: collectionsConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.CREATE_FAILURE, error }
	}
}

function createCollectionByType(
	collectionTypeSlug,
	collection,
	onUpdate = null,
	onError = null
) {
	return (dispatch) => {
		dispatch(request())
		collectionsService
			.createCollectionByType(collectionTypeSlug, collection)
			.then(
				(data) => {
					dispatch(success(data))
					dispatch(
						alertActions.success('Dados atualizados com sucesso!')
					)
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error('Erro atualizando os dados.'))
					if (onError) {
						onError(error)
					}
				}
			)
	}

	function request() {
		return { type: collectionsConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.CREATE_FAILURE, error }
	}
}

function updateCollection(
	collectionId,
	collection,
	onUpdate = null,
	onError = null
) {
	return (dispatch) => {
		dispatch(request())
		collectionsService.updateCollection(collectionId, collection).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
				if (onUpdate) {
					onUpdate(data)
				}
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
				if (onError) {
					onError(error)
				}
			}
		)
	}

	function request() {
		return { type: collectionsConstants.UPDATE_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.UPDATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.UPDATE_FAILURE, error }
	}
}

function cleanCollection() {
	return (dispatch) => {
		dispatch({ type: collectionsConstants.CLEAN })
	}
}

function setCover(collectionId, cover, onUpdate = null, onError = null) {
	return (dispatch) => {
		dispatch(request())
		collectionsService.setCover(collectionId, cover).then(
			(data) => {
				dispatch(success(data))
				if (onUpdate) {
					onUpdate()
				}
			},
			(error) => {
				dispatch(failure(error.toString()))
				if (onError) {
					onError(error)
				}
			}
		)
	}

	function request() {
		return { type: collectionsConstants.SET_COVER_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.SET_COVER_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.SET_COVER_FAILURE, error }
	}
}

function deleteCollection(collectionId, onUpdate = null) {
	return (dispatch) => {
		dispatch(request())
		collectionsService.deleteCollection(collectionId).then(
			(data) => {
				dispatch(success(collectionId))
				dispatch(alertActions.success(data.message))
				if (onUpdate) {
					onUpdate()
				}
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: collectionsConstants.DELETE_REQUEST }
	}
	function success(id) {
		return { type: collectionsConstants.DELETE_SUCCESS, id }
	}
	function failure(error) {
		return { type: collectionsConstants.DELETE_FAILURE, error }
	}
}

function getCollectionByUrl(url) {
	return (dispatch) => {
		dispatch(request(url))

		collectionsService.getCollectionByUrl(url).then(
			(data) => dispatch(success(data)),
			() => dispatch(failure())
		)
	}

	function request(url) {
		return {
			type: collectionsConstants.REQUEST_URL,
			url
		}
	}
	function success(collection) {
		return {
			type: collectionsConstants.SUCCESS_URL,
			data: collection
		}
	}
	function failure() {
		return {
			type: collectionsConstants.FAILURE_URL
		}
	}
}

function sendCollectionToApproval(collectionId) {
	return (dispatch) => {
		dispatch(request())
		collectionsService.sendCollectionToApproval(collectionId).then(
			(data) => {
				dispatch(success(collectionId))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: collectionsConstants.SEND_TO_APPROVAL }
	}
	function success(id) {
		return { type: collectionsConstants.SUCCESS_SEND_TO_APPROVAL, id }
	}
	function failure(error) {
		return { type: collectionsConstants.FAILURE_SEND_TO_APPROVAL, error }
	}
}

function getAllCollectionsToApproval(page, perPage, curators) {
	return (dispatch) => {
		dispatch(request())
		collectionsService
			.getAllCollectionsToApproval(page, perPage, curators)
			.then(
				(data) => {
					dispatch(success(data))
				},
				(error) => {
					dispatch(failure(error.toString()))
				}
			)
	}

	function request() {
		return { type: collectionsConstants.REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.SUCCESS, data, page }
	}
	function failure(error) {
		return { type: collectionsConstants.FAILURE, error }
	}
}

function getAllCollectionsToApprovalByType(
	page,
	perPage,
	curators,
	collectionType,
	onUpdate = null
) {
	return (dispatch) => {
		dispatch(request())
		collectionsService
			.getAllCollectionsToApprovalByType(
				curators,
				collectionType,
				page,
				perPage
			)
			.then(
				(data) => {
					dispatch(success(data))
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
				}
			)
	}

	function request() {
		return { type: collectionsConstants.GET_BY_TYPE_REQUEST }
	}
	function success(data) {
		return {
			type: collectionsConstants.GET_BY_TYPE_SUCCESS,
			data,
			page,
			collectionTypeSlug: `${collectionType}s`
		}
	}
	function failure(error) {
		return { type: collectionsConstants.GET_BY_TYPE_FAILURE, error }
	}
}

function setCurator(collectionId, curator_id, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			collectionsService.setCurator(collectionId, curator_id).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: collectionsConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.SET_FAILURE, error }
	}
}

function publishCollection(collectionId, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			collectionsService.publishCollection(collectionId).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate()
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: collectionsConstants.PUBLISH_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.PUBLISH_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.PUBLISH_FAILURE, error }
	}
}

function defineContent(collectionId, type, message, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			collectionsService.defineContent(collectionId, type, message).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: collectionsConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: collectionsConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: collectionsConstants.SET_FAILURE, error }
	}
}

function actionContent(action, collectionId) {
	return (dispatch) => {
		dispatch(request())

		collectionsService.actionContent(action, collectionId).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return {
			type: collectionsConstants.COLLECTION_ACTION_REQUEST,
			action,
			id: collectionId
		}
	}
	function success(data) {
		return {
			type: collectionsConstants.COLLECTION_ACTION_SUCCESS,
			action,
			data,
			id: collectionId
		}
	}
	function failure(error) {
		return {
			type: collectionsConstants.COLLECTION_ACTION_FAILURE,
			action,
			error,
			id: collectionId
		}
	}
}
