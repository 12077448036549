import { contentDataConstants } from '../_constants'
import { contentDataService } from '../_services'
import { alertActions } from './alert.actions'

export const contentDataActions = {
	getAllTypes,
	getAllThemes,
	updateContenType,
	getThemesWithTotals
}

function getAllTypes() {
	return (dispatch) => {
		dispatch(request())

		contentDataService.getAllTypes().then(
			(rows) => dispatch(success(rows)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: contentDataConstants.REQUEST }
	}
	function success(rows) {
		return { type: contentDataConstants.SUCCESS, rows }
	}
	function failure(error) {
		return { type: contentDataConstants.FAILURE, error }
	}
}

function getAllThemes() {
	return (dispatch) => {
		dispatch(request())

		contentDataService.getAllThemes().then(
			(rows) => dispatch(success(rows)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: contentDataConstants.REQUEST_THEMES }
	}
	function success(rows) {
		return { type: contentDataConstants.SUCCESS_THEMES, rows }
	}
	function failure(error) {
		return { type: contentDataConstants.FAILURE_THEMES, error }
	}
}

function getThemesWithTotals() {
	return (dispatch) => {
		dispatch(request())

		contentDataService.getThemesWithTotals().then(
			(rows) => dispatch(success(rows)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: contentDataConstants.REQUEST_THEMES }
	}
	function success(rows) {
		return { type: contentDataConstants.SUCCESS_THEMES, rows }
	}
	function failure(error) {
		return { type: contentDataConstants.FAILURE_THEMES, error }
	}
}

function updateContenType(id, body) {
	return (dispatch) => {
		dispatch(request())
		contentDataService.updateContenType(id, body).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
				dispatch(getAllTypes())
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: contentDataConstants.UPDATE_TYPE_REQUEST }
	}
	function success(data) {
		return { type: contentDataConstants.UPDATE_TYPE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentDataConstants.UPDATE_TYPE_FAILURE, error }
	}
}
