import { combineReducers } from 'redux'

import { authentication } from './authentication.reducer'
import { registration } from './registration.reducer'
import { users } from './users.reducer'
import { orgs } from './orgs.reducer'
import { countries } from './country.reducer'
import { states } from './state.reducer'
import { cities } from './city.reducer'
import { biomes } from './biomes.reducer'
import { landCategories } from './landCategory.reducer'
import { content } from './content.reducer'
import { collections } from './collections.reducer'
import { types } from './types.reducer'
import { themes } from './themes.reducer'
import { alert } from './alert.reducer'
import { metrics } from './metrics.reducer'
import { carousel } from './carousel.reducer'
import { search } from './search.reducer'
import boolFiltered from './filtered.reducer'

const rootReducer = combineReducers({
	authentication,
	registration,
	users,
	orgs,
	countries,
	states,
	cities,
	biomes,
	landCategories,
	content,
	collections,
	types,
	themes,
	alert,
	boolFiltered,
	metrics,
	carousel,
	search
})

export default rootReducer
