import { searchConstants } from '../_constants'

const defaultResultsModel = {
	totalResults: null,
	results: null,
	has_next: false,
	has_prev: false,
	loading: false,
	page: null
}

export function search(
	state = {
		contentsAndCollections: defaultResultsModel,
		contents: defaultResultsModel,
		collections: defaultResultsModel
	},
	action
) {
	switch (action.type) {
		case searchConstants.GET_FILTERED_SUCCESS: {
			return {
				...state,
				[action.searchResultsType]: {
					...action.data,
					loading: false,
					page: 1
				}
			}
		}
		case searchConstants.GET_FILTERED_REQUEST: {
			return {
				...state,
				[action.searchResultsType]: {
					results: [],
					page: 0,
					prevPage: false,
					nextPage: false,
					loading: true
				}
			}
		}
		case searchConstants.RESET_FILTERED: {
			return {
				...state,
				[action.searchResultsType]: {
					results: [],
					page: 0,
					prevPage: false,
					nextPage: false,
					loading: false
				}
			}
		}
		case searchConstants.LOAD_MORE_FILTERED_SUCCESS: {
			return {
				...state,
				[action.searchResultsType]: {
					...state[action.searchResultsType],
					results: [
						...state[action.searchResultsType].results,
						...action.data.results
					],
					has_next: action.data.has_next,
					page: action.page,
					loadingMore: false
				}
			}
		}
		case searchConstants.LOAD_MORE_FILTERED_REQUEST: {
			return {
				...state,
				[action.searchResultsType]: {
					...state[action.searchResultsType],
					loading: false,
					loadingMore: true
				}
			}
		}
		case searchConstants.LOAD_MORE_FILTERED_FAILURE:
		case searchConstants.GET_FILTERED_FAILURE: {
			return {
				...state,
				[action.searchResultsType]: {
					...state[action.searchResultsType],
					loading: false,
					loadingMore: false
				}
			}
		}
		default:
			return state
	}
}
