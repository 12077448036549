import styled from 'styled-components'

export const Wrapp = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 0;
`

export const Text = styled.div`
	padding: 0 20vw;
	text-align: justify;
	p {
		font-size: 16px;
		margin: 5px 0;
	}
`
