import styled from 'styled-components'
import theme from '../styles/theme'

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`

export const FlipCard = styled.div`
	flex: 1 0 30%;
	height: 295px;
	perspective: 1000px;
	background-color: transparent;
	&:hover > div {
		transform: rotateY(180deg);
	}
	@media screen and (max-width: 990px) {
		flex: 1 0 45%;
	}
	@media screen and (max-width: 768px) {
		flex: unset;
		width: 100%;
	}
`
export const FlipCardInner = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.8s;
	transform-style: preserve-3d;
`

const ThemeSide = styled.div`
	color: #fff;
	backface-visibility: hidden;
	border-radius: 8px;
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 24px 60px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
`

export const ThemeFront = styled(ThemeSide)`
	background-color: ${() => theme.colors.primary};
	gap: 12px;
`
export const ThemeBack = styled(ThemeSide)`
	transform: rotateY(180deg);
	background-color: ${() => theme.colors.secondary};
	justify-content: center;
`

export const FrontTile = styled.span`
	font-family: Roboto;
	font-size: 23px;
	font-weight: 400;
`
export const Totals = styled.span`
	font-family: Roboto;
	font-size: 24px;
	font-weight: 500;
`
export const BackTile = styled.span`
	font-family: Roboto;
	font-size: 21px;
	font-weight: 400;
`
export const FrontDescription = styled.span`
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
`

export const Button = styled.a`
	border-radius: 4px;
	border: 1px solid #fff;
	color: #fff;
	display: flex;
	padding: 4px 16px;
	align-items: center;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 500;
	&:hover {
		color: #fff;
	}
`
