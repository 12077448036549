import styled from 'styled-components'

export const DocumentContainer = styled.div`
	margin-top: 16px;
	height: 600px;
	border: 1px solid #d1d1d1;
	overflow: auto;
	background-color: #d1d1d1 !important;
	.react-pdf__Page {
		display: flex;
		justify-content: center;
		padding: 16px 0px !important;
		background-color: #d1d1d1 !important;
		margin: 0 16px;
		.react-pdf__Page__canvas {
			box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.35);
		}
	}
	.react-pdf__Page__annotations.annotationLayer,
	.react-pdf__Page__textContent.textLayer {
		height: auto !important;
		width: auto !important;
	}

	@media screen and (max-width: 1050px) {
		.react-pdf__Page__canvas {
			width: 100% !important;
			height: 100% !important;
		}
	}
`
export const PaginationContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 16px;
	padding-top: 32px;
`

export const PdfPaginationButton = styled.button`
	background: #fff;
	:hover {
		cursor: pointer;
	}
`
