import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'

import * as S from './styles'
import LandingPageContentBlock from '../../../../components/PublicPages/LandingPageContentBlock'
import CustomPagination from '../../../../components/Dashboard/CustomPagination'
import noImg from '../../../../assets/no-image.jpg'
import { contentActions } from '../../../../app/_actions'
import { useTranslation } from 'react-i18next'

const defaultSortModel = {
	field: 'created_at',
	sort: 'asc'
}

function List({ searchValue, searchValueRef }) {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
	})
	const { i18n } = useTranslation()

	const dispatch = useDispatch()
	// @ts-ignore
	const { data } = useSelector((state) => state.content)
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const onBlockClick = (content) => {
		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${content.friendly_url}`, '_blank')
				: window.open(`/conteudo/${content.friendly_url}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const getData = useCallback(() => {
		if (user) {
			if (
				searchValue !== searchValueRef.current &&
				paginationModel.page > 0
			) {
				setPaginationModel({
					page: 0,
					pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
				})
				searchValueRef.current = searchValue
				return
			}
			dispatch(
				contentActions.getAllPublishedContentByUser(
					paginationModel.page + 1,
					paginationModel.pageSize,
					defaultSortModel.field,
					defaultSortModel.sort,
					user.id,
					searchValue
				)
			)
			searchValueRef.current = searchValue
		}
	}, [dispatch, paginationModel, user, searchValue, searchValueRef])

	useEffect(() => {
		getData()
	}, [getData])

	return (
		<S.Wrapper>
			{data && data.content.length > 0 ? (
				data.content.map((content, idx) => (
					<Fragment key={content.id}>
						<LandingPageContentBlock
							title={content.title}
							description={content.description}
							img={
								content.cover_id
									? content.cover_id + '.thumb'
									: noImg
							}
							onClick={() => onBlockClick(content)}
							contentType={content.content_type}
						></LandingPageContentBlock>
						{idx < data.content.length - 1 && (
							<Divider
								style={{
									width: '100%',
									borderColor: 'rgba(0, 0, 0, 0.0)'
								}}
							/>
						)}
					</Fragment>
				))
			) : (
				<Alert severity="info" sx={{ width: '100%' }}>
					Não há conteúdos publicados
					{searchValue ? ` para a busca "${searchValue}".` : '.'}
				</Alert>
			)}
			<S.PaginationContainer>
				<CustomPagination
					totalResults={data ? data.totalResults : null}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
				/>
			</S.PaginationContainer>
		</S.Wrapper>
	)
}

export default withRouter(List)
