import React from 'react'

import * as S from './styles'
import ContentOrCollectionTypeIcon from '../../components/ContentOrCollectionTypeIcon'
import { truncate } from '../../utils/functions'

/**
 * @function ContentBlockForGrid
 */
const ContentBlockForGrid = ({
	img,
	title,
	onClick,
	contentType,
	fontColor = undefined,
	isFixedWidth = true
}) => {
	return (
		<S.Wrapper
			style={isFixedWidth ? { width: '100%', padding: '0 10px' } : {}}
			onClick={onClick}
		>
			<S.Block>
				{contentType && (
					<S.TopWrapper>
						<S.ContentTypeWrapper>
							<ContentOrCollectionTypeIcon id={contentType.id} />
							<S.Type>{contentType.name}</S.Type>
						</S.ContentTypeWrapper>
					</S.TopWrapper>
				)}
				<img alt={title} src={img} />
			</S.Block>
			<S.Title color={fontColor}>{truncate(title, 60)}</S.Title>
		</S.Wrapper>
	)
}

export default ContentBlockForGrid
