import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GridColumn, Grid, Button, Message, Header } from 'semantic-ui-react'

import { userActions } from '../../../app/_actions'

import * as S from './styles'

const EmailConfirm = ({ history }) => {
	const dispatch = useDispatch()
	const token = new URLSearchParams(history.location.search).get('token')
	const { type, message } = useSelector((state) => state.alert)

	if (!token) {
		history.replace('/')
		//precisa atualizar a tela para trocar o menu do wordpress
		window.location.reload()
	}

	useEffect(() => {
		dispatch(userActions.confirmEmail(token))
	}, [token, dispatch])

	const ReturnToLogin = () => (
		<NavLink to="/">
			<Button type="button" style={{ margin: '5px 0' }} fluid size="big">
				Voltar para a home
			</Button>
		</NavLink>
	)

	return (
		<S.Container className="page">
			<Grid stackable container centered style={{ margin: '5em 0 10px' }}>
				<GridColumn width={8}>
					<Header>Confirmação de e-mail</Header>
					<Message color={type} header={message} />
					<ReturnToLogin />
				</GridColumn>
			</Grid>
		</S.Container>
	)
}

export default EmailConfirm
