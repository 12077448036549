import React from 'react'
import { Box, Pagination, Stack } from '@mui/material'

const CustomPagination = ({
	totalResults,
	paginationModel,
	setPaginationModel
}) => {
	return (
		<Stack direction="row" spacing={2}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{paginationModel.pageSize * paginationModel.page + 1} -{' '}
				{totalResults &&
				paginationModel.pageSize * (paginationModel.page + 1) >
					totalResults
					? totalResults
					: paginationModel.pageSize * paginationModel.page +
					  paginationModel.pageSize}{' '}
				de {totalResults || 0}
			</Box>
			<Pagination
				color="primary"
				count={
					totalResults
						? Math.ceil(totalResults / paginationModel.pageSize)
						: 0
				}
				page={paginationModel.page + 1}
				onChange={(_, value) =>
					setPaginationModel({
						page: value - 1,
						pageSize:
							parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
					})
				}
			/>
		</Stack>
	)
}

export default CustomPagination
