import React from 'react'

import { truncate } from '../../../utils/functions'

import * as S from './styles'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'

/**
 * @function ContentBlock
 */
const LandingPageContentBlock = ({
	img,
	title,
	description,
	onClick,
	contentType
}) => {
	return (
		<S.Wrapper>
			<S.Block className="img" onClick={onClick}>
				<img alt={title} src={img} />
				{contentType.id && (
					<S.Icon>
						<ContentOrCollectionTypeIcon id={contentType.id} />
						<S.Type className="content-type">
							{contentType.name}
						</S.Type>
					</S.Icon>
				)}
			</S.Block>
			<S.ContentInfoContainer>
				<S.Title onClick={onClick}>{truncate(title, 100)}</S.Title>
				<S.Description>{truncate(description, 290)}</S.Description>
				{/* <S.KeywordsContainer>
					{keywords &&
						keywords
							.split(', ')
							.map((keyword, idx) => (
								<S.Keyword key={idx}>{keyword}</S.Keyword>
							))}
				</S.KeywordsContainer> */}
			</S.ContentInfoContainer>
		</S.Wrapper>
	)
}

export default LandingPageContentBlock
