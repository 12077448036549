import styled from 'styled-components'
import theme from '../../../styles/theme'

export const LoginButton = styled.a`
	height: 36px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	border-radius: 4px;
	background: ${() => theme.colors.secondary};
	color: #fff;
	padding: 0px 16px;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	:hover {
		color: #fff;
		cursor: pointer;
	}
	i {
		height: auto;
		width: auto;
	}
`
export const LoginButtonText = styled.span`
	@media screen and (max-width: 1024px) {
		display: none;
	}
`
