export const steps = [
	{
		title: 'Conteúdo',
		description: 'Arquivo e/ou link'
	},
	{
		title: 'Descrição',
		description: 'Informações básicas'
	},
	{
		title: 'Ficha Técnica',
		description: 'Informações adicionais'
	}
]

export const stepInputs = {
	// STEP 1:
	content_type_id: '',
	type: '',
	content_link: '',
	file: null,

	// STEP 2:
	title: '',
	description: '',
	cover_id: '',
	cover_filename: '',

	// STEP 3:
	author: [],
	keywords: [],
	how_cite: [],
	organizations: [],
	year: null,
	language_id: [],
	pais_id: [],
	estado_id: [],
	bioma_id: [],
	land_categories: [],
	themes: [],

	// UNTREATED:
	municipio_id: [],
	uc_id: [],
	ti_id: []
}
