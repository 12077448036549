import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import { Form, Checkbox as SemanticCheckbox } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import ptBr from 'react-phone-number-input/locale/pt-BR.json'

import * as S from './styles'
import * as SGlobal from '../../styles/global'
import { alertActions, orgsActions, userActions } from '../../../app/_actions'
import Icon from '../../../components/Icon'
import { isTmpId } from '../../../utils/functions'
import AutocompleteEditable from '../../../components/Dashboard/Autocomplete/AutocompleteEditable'

import 'react-phone-number-input/style.css'

const initialState = {
	name: '',
	email: '',
	password: '',
	confirmationPassword: '',
	profile: 0,
	organization_id: null,
	phone: '',
	receive_email_news: false,
	receive_whatsapp_news: false
}

const SignUp = ({ setRegisterSuccess }) => {
	const { t } = useTranslation()

	const firstStepFields = [
		{
			type: 'text',
			name: 'name',
			label: t('login.signup-name', 'Nome'),
			placeholder: t('login.signup-name-placeholder', 'Nome completo'),
			required: true
		},
		{
			type: 'email',
			name: 'email',
			label: t('login.signup-email', 'E-mail'),
			placeholder: 'exemplo@exemplo.com.br',
			required: true
		},
		{
			type: 'password',
			name: 'password',
			label: t('login.signup-password', 'Senha'),
			placeholder: t(
				'login.signup-password-placeholder',
				'Informe a senha'
			),
			required: true
		},
		{
			type: 'password',
			name: 'confirmationPassword',
			label: t('login.signup-confirm-password', 'Confirmar a senha'),
			placeholder: t(
				'login.signup-confirm-password-placeholder',
				'Repita a senha'
			),
			required: true
		}
	]
	const secondStepFields = [
		{
			type: 'organization',
			name: 'organization_id',
			label: t(
				'login.signup-organization',
				'Você faz parte de uma instituição ou organização?'
			),
			placeholder: t(
				'login.signup-organization-placeholder',
				'Digite o nome da organização'
			)
		},
		{
			type: 'phone',
			name: 'phone',
			label: t('login.signup-phone', 'Telefone'),
			placeholder: '(--) - ---- ----'
		},
		{
			type: 'checkbox',
			label: t(
				'login.signup-news',
				'Como gostaria de receber as novidades do portal Proteja?'
			),
			options: [
				{
					name: 'receive_email_news',
					label: t('login.signup-email', 'Por e-mail')
				},
				{
					name: 'receive_whatsapp_news',
					label: t('login.signup-whatsapp', 'Por Whatsapp')
				}
			]
		}
	]

	const dispatch = useDispatch()
	// @ts-ignore
	const { orgs, fetchingOrgs } = useSelector((state) => state.orgs)
	// @ts-ignore
	const { registering } = useSelector((state) => state.registration)
	// @ts-ignore
	const { type, message, component } = useSelector((state) => state.alert)
	const [user, setUser] = useState(initialState)
	const [accept, setAccept] = useState(false)
	const [firstStepSubmitted, setFirstStepSubmitted] = useState(false)
	const [secondStepSubmitted, setSecondStepSubmitted] = useState(false)
	const [showSecondStep, setShowSecondStep] = useState(false)
	const fields = showSecondStep ? secondStepFields : firstStepFields
	const submitted = showSecondStep ? secondStepSubmitted : firstStepSubmitted

	function handleChange(e) {
		const { name, value } = e.target
		setUser((user) => ({ ...user, [name]: value }))
	}
	function handleChangeCheckbox(e) {
		const { name, checked } = e.target
		setUser((user) => ({ ...user, [name]: checked }))
	}
	function handleChangeTelephone(name, value) {
		setUser((user) => ({ ...user, [name]: value.slice(0, 12) }))
	}

	function handleChangeOrg({ target }) {
		const { name: fieldKey, value: newValueRaw } = target

		if (!isTmpId(newValueRaw.id)) {
			setUser((user) => ({
				...user,
				[fieldKey]: newValueRaw,
				organization_id: newValueRaw.id
			}))
			return
		}

		dispatch(
			orgsActions.create({ name: newValueRaw.name }, (newValue) => {
				setUser((user) => ({
					...user,
					[fieldKey]: newValue,
					organization_id: newValue.id
				}))
			})
		)
	}

	function validateName() {
		const regFullName = /([a-zA-Z]{1,})+\s+([a-zA-Z]{1,})+[a-zA-Z\s]*/
		var regex = new RegExp(regFullName)
		if (!regex.test(user.name)) {
			dispatch(
				alertActions.error(
					t(
						'error.login.must-full-name',
						'Preencha seu nome completo'
					),
					'signup'
				)
			)
			return false
		}
		return true
	}

	function handleFirstStepSubmit(e) {
		e.preventDefault()
		setFirstStepSubmitted(true)
		if (
			user.name &&
			user.email &&
			user.password &&
			user.confirmationPassword &&
			validateName()
		) {
			setFirstStepSubmitted(false)
			setShowSecondStep(true)
			dispatch(alertActions.error(null, null))
		}
	}

	function handleSubmit(e) {
		e.preventDefault()

		setSecondStepSubmitted(true)
		if (accept) {
			dispatch(
				userActions.register(user, () => {
					setUser(initialState)
					setSecondStepSubmitted(false)
					setAccept(false)
					setRegisterSuccess(true)
				})
			)
		} else {
			dispatch(
				alertActions.error(
					t(
						'error.login.must-accept-terms',
						'É preciso aceitar os Termos e Condições de uso da plataforma!'
					),
					'signup'
				)
			)
		}
	}

	useEffect(() => {
		if (!orgs || orgs.length === 0) {
			dispatch(orgsActions.getAll())
		}
	}, [dispatch, orgs])

	return (
		<SGlobal.ModalWrapper style={{ paddingBottom: '92px' }}>
			{type && message && component === 'signup' && (
				<SGlobal.AlertMessage
					className={registering ? '' : 'shake'}
					color={type}
					header={message}
				/>
			)}
			<SGlobal.StyledHeader
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%'
				}}
			>
				<Icon
					type="fa fa-arrow-left"
					onClick={() => setShowSecondStep(false)}
					style={{
						opacity: showSecondStep ? 1 : 0
					}}
				/>
				{showSecondStep
					? t('login.almost-there', 'Estamos quase lá!')
					: t('login.register', 'Cadastrar')}
				<Icon type="fa fa-arrow-left" style={{ opacity: 0 }} />
			</SGlobal.StyledHeader>
			<Form size="large" onSubmit={handleSubmit}>
				<SGlobal.InputWrapper>
					{fields.map((field, key) => {
						if (field.type === 'organization') {
							return (
								<AutocompleteEditable
									key="organization"
									multiple={false}
									label={t(
										'dashboard.organizations',
										'Organização ou instituição'
									)}
									fieldKey="organization"
									placeholder={t(
										'dashboard.organization-placeholder',
										'Adicionar organização ou instituição'
									)}
									data={orgs}
									loading={fetchingOrgs}
									onChange={handleChangeOrg}
									value={user ? user.organization : null}
									allowCreateRelated={true}
								/>
							)
						} else if (field.type == 'checkbox') {
							return (
								<S.InputContainer key={key}>
									<S.Label>{field.label}</S.Label>
									<FormGroup>
										{field.options.map((check, idx) => {
											return (
												<FormControlLabel
													key={idx}
													label={check.label}
													control={
														<Checkbox
															style={{
																fontSize: '14px'
															}}
															name={check.name}
															checked={
																user[check.name]
															}
															sx={{
																'& .MuiSvgIcon-root':
																	{
																		fontSize: 24
																	}
															}}
															onChange={
																handleChangeCheckbox
															}
														/>
													}
												/>
											)
										})}
									</FormGroup>
								</S.InputContainer>
							)
						} else if (field.type == 'phone') {
							return (
								<S.InputContainer key={key}>
									<S.Label>{field.label}</S.Label>
									<PhoneInput
										key={key}
										defaultCountry="BR"
										labels={ptBr}
										placeholder={field.placeholder}
										value={user[field.name]}
										onChange={(value) => {
											handleChangeTelephone(
												field.name,
												value
											)
										}}
									/>
								</S.InputContainer>
							)
						} else {
							return (
								<Form.Input
									key={key}
									type={field.type}
									label={
										<>
											{field.label}
											{field.required && '*'}
										</>
									}
									error={
										submitted &&
										field.required &&
										!user[field.name]
									}
									name={field.name}
									placeholder={field.placeholder}
									value={user[field.name]}
									onChange={handleChange}
									autoComplete="on"
									size="massive"
								/>
							)
						}
					})}
					{showSecondStep && (
						<S.AcceptTerm>
							<SemanticCheckbox
								checked={accept}
								onChange={(_, { checked }) => {
									setAccept(checked)
								}}
								label={
									<label>
										{t(
											'login.accept-terms.part-1',
											'Ao clicar em Cadastrar, você confirma que leu e reconhece o'
										)}{' '}
										<a href="/termo-e-condicoes-de-uso">
											{t(
												'login.terms-conditions',
												'Termo e Condições'
											)}
										</a>{' '}
										{t(
											'login.accept-terms.part-2',
											'de uso da plataforma.'
										)}
									</label>
								}
							/>
						</S.AcceptTerm>
					)}
					{showSecondStep ? (
						<SGlobal.LoginButton>
							{t('login.sign-up', 'Cadastrar')}
						</SGlobal.LoginButton>
					) : (
						<SGlobal.LoginButton onClick={handleFirstStepSubmit}>
							{t('login.sign-up-next', 'Continuar')}
						</SGlobal.LoginButton>
					)}
				</SGlobal.InputWrapper>
			</Form>
		</SGlobal.ModalWrapper>
	)
}

export default SignUp
