import { locationConstants } from '../_constants'

export function landCategories(state = { landCategories: [] }, action) {
	switch (action.type) {
		case locationConstants.LAND_CATEGORY_REQUEST:
			return {
				fetchingLandCategories: true,
				...state
			}
		case locationConstants.LAND_CATEGORY_SUCCESS: {
			return {
				landCategories: action.rows
			}
		}
		case locationConstants.LAND_CATEGORY_FAILURE:
			return {
				landCategories: []
			}
		default:
			return state
	}
}
