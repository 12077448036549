import { Skeleton } from '@mui/material'
import React from 'react'
import * as S from './styles'

function SkeletonCustom() {
	return (
		<S.SkeletonWrap>
			<Skeleton
				variant="rectangular"
				width={220}
				height={150}
				style={{ borderRadius: '8px' }}
			/>
			<S.SkeletonText>
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
			</S.SkeletonText>
		</S.SkeletonWrap>
	)
}

export default SkeletonCustom
