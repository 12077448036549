import styled from 'styled-components'

export const StepWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 24px;
	width: 100%;
	@media screen and (max-width: 782px) {
		flex-direction: column;
		gap: 16px;
	}
`
export const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
	align-items: flex-start;
	padding-right: 10px;
	padding-vertical: 16px;
	flex: 1 0 0;
	.MuiFormControl-root {
		width: 100%;
	}
	input::placeholder,
	textarea::placeholder {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.35);
	}
	input,
	textarea {
		color: rgba(0, 0, 0, 0.65) !important;
	}
	@media screen and (max-width: 782px) {
		width: 100%;
	}
`

export const InputWrapper = styled.div`
	width: 100%;
`
