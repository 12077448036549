import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

import ModalShare from '../ModalShare'
import Icon from '../../Icon'

import * as S from './styles'
import theme from '../../../styles/theme'
import { collectionsActions, contentActions } from '../../../app/_actions'
import { useTranslation } from 'react-i18next'

function ContentUserActions({ id, url, item, onClose, isCollection = false }) {
	const { t, i18n } = useTranslation()

	const actions = {
		favorite: {
			icon: 'far fa-bookmark',
			title: 'Salvar',
			action: 'favorite',
			activeIcon: 'fas fa-bookmark',
			key: 'is_favorited_by_current_user',
			active: item.is_favorited_by_current_user
		},
		like: {
			icon: 'far fa-thumbs-up',
			title: 'Salvar',
			action: 'like',
			activeIcon: 'fas fa-thumbs-up',
			key: 'is_liked_by_current_user',
			active: item.is_liked_by_current_user
		},
		dislike: {
			icon: 'far fa-thumbs-down',
			title: 'Salvar',
			action: 'dislike',
			activeIcon: 'fas fa-thumbs-down',
			key: 'is_disliked_by_current_user',
			active: item.is_disliked_by_current_user
		}
	}

	// @ts-ignore
	const { loggedIn } = useSelector((state) => state.authentication)
	const [confirmLoginOpen, setConfirmLoginOpen] = useState(false)
	const [openShare, setOpenShare] = useState(false)
	const dispatch = useDispatch()

	const genericActions = isCollection ? collectionsActions : contentActions
	const handleAction = (type, active) => {
		if (type === 'share') {
			setOpenShare(true)
			return
		}

		if (loggedIn) {
			if (active) {
				switch (type) {
					case 'favorite':
						dispatch(genericActions.actionContent('unfavorite', id))
						return
					case 'like':
					case 'dislike':
						dispatch(
							genericActions.actionContent('remove-like', id)
						)
						return
				}
			}

			dispatch(genericActions.actionContent(type, id))
			return
		}

		setConfirmLoginOpen(true)
	}

	const handleConfirmLoginClose = () => {
		setConfirmLoginOpen(false)
	}

	const handleGoToLogin = () => {
		handleConfirmLoginClose()
		if (onClose) {
			onClose()
		}
		const loginElements = document.getElementsByClassName('proteja-login')
		if (loginElements) {
			// @ts-ignore
			loginElements[0].children[0].click()
		}
	}

	return (
		<>
			<S.Actions>
				<S.SharingButton>
					{Object.values(actions)
						.filter((row) => row.action !== 'favorite')
						.map((actionItem, idx, arr) => (
							<Box key={actionItem.action}>
								<Button
									color="primary"
									onClick={() =>
										handleAction(
											actionItem.action,
											actionItem.active
											// actionItem.counterType,
											// actionItem.key,
											// actionItem.counterKey,
											// item
										)
									}
									startIcon={
										<Icon
											type={
												actionItem.active
													? actionItem.activeIcon
													: actionItem.icon
											}
											style={{
												color: theme.colors.primary,
												fontSize: '18px'
											}}
											data-tip={actionItem.tip}
											id={actionItem.icon}
										/>
									}
								>
									{actionItem.action === 'like' && (
										<>
											{item.number_of_likes
												? item.number_of_likes
												: '0'}
										</>
									)}
								</Button>
								{idx < arr.length - 1 && (
									<Divider orientation="vertical" flexItem />
								)}
							</Box>
						))}
				</S.SharingButton>
				<Button
					key={actions.favorite.action}
					data-tip={actions.favorite.tip}
					onClick={() =>
						handleAction(
							actions.favorite.action,
							actions.favorite.active
							// actions.favorite.counterType,
							// actions.favorite.key,
							// actions.favorite.counterKey,
							// item
						)
					}
					startIcon={
						<Icon
							type={
								actions.favorite.active
									? actions.favorite.activeIcon
									: actions.favorite.icon
							}
							data-tip={actions.favorite.tip}
							id={actions.favorite.icon}
						/>
					}
				/>

				<ModalShare
					open={openShare}
					handleOpen={setOpenShare}
					url={
						i18n.language == 'es-ES'
							? 'contenido/' + url
							: 'conteudo/' + url
					}
				/>
			</S.Actions>
			<Dialog open={confirmLoginOpen} onClose={handleConfirmLoginClose}>
				<DialogTitle>
					{t(
						'user-actions.login.sing-up',
						'Entre ou faça o seu cadastro!'
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t(
							'user-actions.login.message',
							'Para curtir e salvar conteúdos, entre ou crie uma conta no Proteja!'
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirmLoginClose}>
						{t('user-actions.login.cancel', 'Cancelar')}
					</Button>
					<Button
						onClick={handleGoToLogin}
						color="primary"
						variant="contained"
					>
						{t('user-actions.login.login', 'Entrar ou criar conta')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ContentUserActions
