import { Box, Grid } from '@mui/material'
import React from 'react'
import * as S from './styles'
import languageList from '../../../constants/languages'
import { useTranslation } from 'react-i18next'
import { collectionTypeSlugs } from '../../../app/_constants'

function CollectionSheet({ item, showEmptyFields = false }) {
	const { t } = useTranslation()

	let itemFields = [
		{
			key: 'content_indications',
			label: t('item-sheet.content_indications', 'Conteúdos'),
			customFormat: (value) =>
				value.length > 0
					? `${value.length} ${
							value.length === 1 ? ' conteúdo' : ' conteúdos'
					  }`
					: ''
		},
		{
			key: 'curso_type',
			label: t('item-sheet.curso_type', 'Tipo de curso'),
			customFormat: (value) => (value ? value.name : '')
		},
		{
			key: 'curso_workload',
			label: t('item-sheet.curso_workload', 'Carga horária')
		},
		{
			key: 'curso_program',
			label: t('item-sheet.curso_program', 'Ementa/Programa')
		},
		{
			key: 'projeto_financers',
			label: t('item-sheet.projeto_financers', 'Financiador(es)'),
			customFormat: (value) => (value ? value.replace(/[,]/g, ', ') : '')
		},
		{
			key: 'projeto_url',
			label: t('item-sheet.projeto_url', 'Link do projeto'),
			customFormat: (value) =>
				value ? (
					<S.ContentLink
						href={value}
						target="_blank"
						rel="noreferrer"
					>
						{value}
					</S.ContentLink>
				) : (
					''
				)
		},
		{
			key: 'date_start',
			label: t('item-sheet.date_start', 'Data de início')
		},
		{
			key: 'date_end',
			label: t('item-sheet.date_end', 'Data de encerramento')
		},
		{
			key: 'language_ids',
			label: t('item-sheet.language', 'Idioma'),
			customFormat: (values) => {
				if (!values) {
					return ''
				}
				return values
					.map((id) => {
						const languageFound = languageList.find(
							(language) => language.id === id
						)
						return languageFound ? languageFound.portugueseName : id
					})
					.join(', ')
			}
		},
		{
			key: 'themes',
			label: t('item-sheet.themes', 'Tema(s)'),
			customFormat: (values) =>
				values ? values.map((value) => value.name).join(', ') : ''
		},
		{ key: 'keywords', label: t('item-sheet.keywords', 'Palavras-chave') }
	]

	let fieldsToRemove = []
	switch (item.collection_type.slug) {
		case collectionTypeSlugs.SERIE:
			fieldsToRemove = [
				'date_start',
				'date_end',
				'curso_type',
				'curso_workload',
				'curso_program',
				'projeto_financers',
				'projeto_url'
			]
			break
		case collectionTypeSlugs.CURSO:
			fieldsToRemove = ['projeto_financers', 'projeto_url']
			break
		case collectionTypeSlugs.PROJETO:
			fieldsToRemove = ['curso_type', 'curso_workload', 'curso_program']
	}

	itemFields = itemFields.filter((row) => !fieldsToRemove.includes(row.key))

	const visibleFields = showEmptyFields
		? itemFields
		: itemFields.filter((row) => item[row.key])

	return (
		<S.Wrapper>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container component="div" spacing={0}>
					{visibleFields.map((row, index) => (
						<React.Fragment key={`item_sheet_${index}`}>
							<Grid item xs={12} sm={3} className="content-type">
								{row.label}
							</Grid>
							<Grid item xs={12} sm={9} className="content-info">
								{row.customFormat
									? row.customFormat(item[row.key])
									: Array.isArray(item[row.key])
									? item[row.key].join(', ')
									: item[row.key]}
							</Grid>
						</React.Fragment>
					))}
				</Grid>
			</Box>
		</S.Wrapper>
	)
}

export default CollectionSheet
