import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import * as S from './styles'
import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { useTranslation } from 'react-i18next'

function PDFViewer({ fileId, fileContentType }) {
	const { t } = useTranslation()
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)

	// @ts-ignore
	pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
	}
	function goToPrevPage() {
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)
	}

	function goToNextPage() {
		setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)
	}

	return (
		<>
			{fileContentType === 'application/pdf' && (
				<>
					<S.DocumentContainer>
						<Document
							file={
								process.env.REACT_APP_API.split('api')[0] +
								fileId
							}
							onLoadSuccess={onDocumentLoadSuccess}
							loading={t(
								'content-viewer.loading-pdf',
								'Carregando PDF...'
							)}
						>
							<Page pageNumber={pageNumber} />
						</Document>
					</S.DocumentContainer>
					<S.PaginationContainer>
						{numPages > 1 && (
							<S.PdfPaginationButton onClick={goToPrevPage}>
								{t('content-viewer.previous', 'Anterior')}
							</S.PdfPaginationButton>
						)}
						{t('content-viewer.page', 'Página')} {pageNumber}{' '}
						{t('content-viewer.of', 'de')} {numPages}
						{numPages > 1 && (
							<S.PdfPaginationButton onClick={goToNextPage}>
								{t('content-viewer.next', 'Próxima')}
							</S.PdfPaginationButton>
						)}
					</S.PaginationContainer>
				</>
			)}
		</>
	)
}

export default PDFViewer
