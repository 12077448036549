import { searchConstants } from '../_constants'
import { searchService } from '../_services'

export const searchActions = {
	resetFilteredResults,
	getFilteredResults,
	// getFilteredCollections,
	// getFilteredContents,
	loadMoreFilteredResults
}

function getFilteredResults(
	params,
	page,
	perPage,
	language,
	order = null,
	orderDirection = null,
	onUpdate = null
) {
	let searchResultsType = 'contentsAndCollections'
	if (params.collection_type) {
		searchResultsType = 'collections'
	}
	if (params.contents_only) {
		searchResultsType = 'contents'
	}
	return (dispatch) => {
		dispatch(request())
		searchService
			.getFilteredResults(
				params,
				page,
				perPage,
				language,
				order,
				orderDirection
			)
			.then(
				(data) => {
					if (onUpdate) {
						onUpdate(data)
					}
					dispatch(success(data))
				},
				(error) => dispatch(failure(error))
			)
	}

	function request() {
		return { type: searchConstants.GET_FILTERED_REQUEST, searchResultsType }
	}

	function success(data) {
		return {
			type: searchConstants.GET_FILTERED_SUCCESS,
			data,
			searchResultsType
		}
	}

	function failure(error) {
		return {
			type: searchConstants.GET_FILTERED_FAILURE,
			message: error.message,
			searchResultsType
		}
	}
}

function resetFilteredResults() {
	return (dispatch) => {
		dispatch({ type: searchConstants.RESET_FILTERED })
	}
}

function loadMoreFilteredResults(
	params,
	page,
	perPage,
	language,
	order = null,
	orderDirection = null,
	onUpdate = null
) {
	let searchResultsType = 'contentsAndCollections'
	if (params.collection_type) {
		searchResultsType = 'collections'
	}
	if (params.contents_only) {
		searchResultsType = 'contents'
	}
	return (dispatch) => {
		dispatch(request())
		searchService
			.loadMoreFilteredResults(
				params,
				page,
				perPage,
				language,
				order,
				orderDirection
			)
			.then(
				(data) => {
					if (onUpdate) {
						onUpdate(data)
					}
					dispatch(success(data))
				},
				(error) => dispatch(failure(error))
			)
	}

	function request() {
		return {
			type: searchConstants.LOAD_MORE_FILTERED_REQUEST,
			searchResultsType
		}
	}

	function success(data) {
		return {
			type: searchConstants.LOAD_MORE_FILTERED_SUCCESS,
			data,
			page,
			searchResultsType
		}
	}

	function failure(error) {
		return {
			type: searchConstants.LOAD_MORE_FILTERED_FAILURE,
			message: error.message,
			searchResultsType
		}
	}
}
