import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API,
	apiIbgeUrl: process.env.REACT_APP_IBGE,
	apiIsaUrl: process.env.REACT_APP_ISA,
	apiIsaKey: process.env.REACT_APP_ISA_KEY
}

export const locationService = {
	getCountries,
	getStates,
	getBiomes,
	getCities,
	getLandCategories
}

function getCountries() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiIbgeUrl}/localidades/paises`, requestOptions)
		.then((response) => response.json())
		.then((data) => {
			return data
		})
}

function getStates() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiIbgeUrl}/localidades/estados`, requestOptions)
		.then((response) => response.json())
		.then((data) => {
			return data
		})
}

function getCities(state) {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiIbgeUrl}/localidades/estados/${state}/municipios`,
		requestOptions
	)
		.then((response) => response.json())
		.then((data) => {
			return data
		})
}

function getBiomes() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiIsaUrl}/bioma?apikey=${config.apiIsaKey}`,
		requestOptions
	)
		.then((response) => response.json())
		.then(({ data }) => {
			return data
		})
}

function getLandCategories() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/land-categories/`, requestOptions).then(
		handleResponse
	)
}
