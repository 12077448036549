import { useEffect, useRef } from 'react'

/**
 * Hook to get previous state value of a ref
 * @param {Any} value
 */

function usePrevious(value) {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export default usePrevious
