import { styled as muiStyled } from '@mui/system'
// import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export const StyledButton = muiStyled(Button)({})
export const HideOnMobileButton = muiStyled(Button)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`
export const ShowOnMobileButton = muiStyled(Button)`
  @media screen and (min-width: 480px) {
    display: none;
  }
`
export const StyledDialog = muiStyled(Dialog)`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  @media screen and (max-width: 782px) {
    .MuiPaper-root {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
`
export const StyledDialogActions = muiStyled(DialogActions)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	padding: '16px 24px',
	borderTop: '1px solid rgba(0, 0, 0, 0.15)'
})
export const StyledDialogContent = muiStyled(DialogContent)({
	padding: 0
})
export const StyledDialogContentText = muiStyled(DialogContentText)({})
export const StyledDialogTitle = muiStyled(DialogTitle)((props) => ({
	background: props.theme.palette.primary.main,
	color: 'white',
	fontSize: 16
}))

export const StyledRightButtons = muiStyled('div')({
	display: 'flex',
	flexDirection: 'row',
	gap: '16px'
})
