import { useState, useEffect } from 'react'
import { userService } from '../../../app/_services'
import { Comment, Container, Header, Loader, Dimmer } from 'semantic-ui-react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import Icon from '../../Icon'
import ReactTooltip from 'react-tooltip'

const host = process.env.REACT_APP_HOSTNAME
const MyComments = () => {
	const shareUrl = `${host}/conteudo/`
	dayjs.extend(relativeTime)
	dayjs.locale('pt-br')
	const [payload, setPayload] = useState(null)
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		ReactTooltip.rebuild()
		userService.getMyComments(1, 1000).then((response) => {
			setPayload(response)
			setLoading(false)
		})
	}, [setPayload, setLoading])
	return (
		<>
			<Dimmer active={loading} inverted>
				<Loader />
			</Dimmer>
			<Container>
				{payload && payload.comments.length > 0 && (
					<Comment.Group size="large">
						<Header as="h3" dividing>
							Comentários
						</Header>
						{payload.comments.map((t, key) => (
							<Comment key={key}>
								<Comment.Avatar
									src={
										t.user.photo_id ? (
											t.user.photo_id
										) : (
											<Icon
												type="fa fa-user"
												style={{
													fontSize: '25px',
													margin: '0'
												}}
											/>
										)
									}
								/>
								<Comment.Content>
									<Comment.Author as="a">
										{t.user.name.toUpperCase()}
									</Comment.Author>
									<Comment.Metadata
										data-tip={dayjs(t.created_at).format(
											'DD/MM/YYYY - HH:mm:ss'
										)}
										data-for="content"
									>
										- {dayjs(t.created_at).fromNow()}
									</Comment.Metadata>
									<Comment.Metadata
										as="a"
										href={
											shareUrl +
											t.content_indication.friendly_url
										}
									>
										- {t.content_indication.title}
									</Comment.Metadata>
									<Comment.Text>{t.comment}</Comment.Text>
								</Comment.Content>
							</Comment>
						))}
					</Comment.Group>
				)}
				{payload && payload.totalResults == 0 && <p>teste</p>}
				<ReactTooltip id="content" />
			</Container>
		</>
	)
}

export default MyComments
