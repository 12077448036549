import React, { useState } from 'react'
import { Button, Form, Grid, Header, Message } from 'semantic-ui-react'
import { contactService } from '../../../app/_services'
const Contact = () => {
	const [inputs, setInputs] = useState({
		name: '',
		email: '',
		message: ''
	})
	const [response, setResponse] = useState({
		type: '',
		message: '',
		sending: false
	})

	const handleChange = ({ target }) => {
		const { name, value } = target
		setInputs((form) => ({ ...form, [name]: value }))
	}

	const handleSubmit = () => {
		contactService.sendContactForm(inputs).then(
			(data) => {
				setResponse({
					...response,
					type: 'success',
					message: data.message,
					sending: false
				})
			},
			(error) => {
				setResponse({
					...response,
					type: 'error',
					message: error.message,
					sending: true
				})
			}
		)
	}

	return (
		<div className="page">
			<Grid columns={2} centered style={{ margin: '100px 0' }}>
				<Grid.Column>
					{response.type && response.message && (
						<Message
							className={response.sending ? '' : 'shake'}
							color={response.type}
							header={response.message}
						/>
					)}
					<Header as="h1">
						Fale conosco
						<Header.Subheader>
							Envie-nos uma mensagem com suas dúvidas, sugestões
							ou reclamações
						</Header.Subheader>
					</Header>
					<Form size="big" onSubmit={handleSubmit}>
						<Form.Field>
							<label>Nome</label>
							<input
								value={inputs['name']}
								name="name"
								onChange={handleChange}
								placeholder="Informe o seu nome"
							/>
						</Form.Field>
						<Form.Field>
							<label>E-mail</label>
							<input
								value={inputs['email']}
								name="email"
								type="email"
								onChange={handleChange}
								placeholder="Informe o e-mail para contato"
							/>
						</Form.Field>
						<Form.Field>
							<label>Mensagem</label>
							<textarea
								value={inputs['message']}
								name="message"
								onChange={handleChange}
								placeholder="Digite a mensagem"
							></textarea>
						</Form.Field>
						<Button size="big" className="styled">
							Enviar
						</Button>
					</Form>
				</Grid.Column>
			</Grid>
		</div>
	)
}

export default Contact
