import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const orgsService = {
	getAllPaginated,
	getAll,
	delete: _delete,
	loadOrgsSelection,
	create,
	createBatch
}

function getAllPaginated(page, perPage, q = '') {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(
		`${config.apiUrl}/organizations/paginate/${page}/${perPage}?q=${q}`,
		requestOptions
	).then(handleResponse)
}

function getAll() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(`${config.apiUrl}/organizations/list`, requestOptions).then(
		handleResponse
	)
}

function create(newOrg) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(newOrg)
	}

	return fetch(`${config.apiUrl}/organizations/`, requestOptions).then(
		handleResponse
	)
}

function createBatch(newOrgs) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(newOrgs)
	}

	return fetch(
		`${config.apiUrl}/organizations/create_batch`,
		requestOptions
	).then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	}

	return fetch(`${config.apiUrl}/organizations/${id}`, requestOptions).then(
		handleResponse
	)
}

async function loadOrgsSelection(q, loadedOptions, { page }) {
	const response = await fetch(
		`${config.apiUrl}/organizations/paginate/${page}/10?q=${q}`
	)
	const responseJSON = await response.json()
	const hasMore = responseJSON.has_next
	return {
		options: responseJSON.organizations.map((o) => ({
			value: o.id,
			label: o.name
		})),
		hasMore,
		additional: {
			page: hasMore ? page + 1 : page
		}
	}
}
