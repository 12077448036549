import { ReactComponent as series } from '../../assets/dashboard-menu/series.svg'
import { ReactComponent as cursos } from '../../assets/dashboard-menu/cursos.svg'
import { ReactComponent as projetos } from '../../assets/dashboard-menu/projetos.svg'

export const collectionsConstants = {
	REQUEST: 'COLLECTIONS_REQUEST',
	SUCCESS: 'COLLECTIONS_SUCCESS',
	FAILURE: 'COLLECTIONS_FAILURE',

	GET_BY_TYPE_REQUEST: 'COLLECTIONS_GET_BY_TYPE_REQUEST',
	GET_BY_TYPE_SUCCESS: 'COLLECTIONS_GET_BY_TYPE_SUCCESS',
	GET_BY_TYPE_FAILURE: 'COLLECTIONS_GET_BY_TYPE_FAILURE',

	LOAD_MORE_REQUEST: 'COLLECTIONS_LOAD_MORE_REQUEST',
	LOAD_MORE_SUCCESS: 'COLLECTIONS_LOAD_MORE_SUCCESS',
	LOAD_MORE_FAILURE: 'COLLECTIONS_LOAD_MORE_FAILURE',

	USER_COLLECTION_REQUEST: 'GET_USER_COLLECTION_REQUEST',
	USER_COLLECTION_SUCCESS: 'GET_USER_COLLECTION_SUCCESS',
	USER_COLLECTION_FAILURE: 'GET_USER_COLLECTION_FAILURE',

	CREATE_REQUEST: 'CREATE_COLLECTIONS_REQUEST',
	CREATE_SUCCESS: 'CREATE_COLLECTIONS_SUCCESS',
	CREATE_FAILURE: 'CREATE_COLLECTIONS_FAILURE',

	UPDATE_REQUEST: 'UPDATE_COLLECTION_REQUEST',
	UPDATE_SUCCESS: 'UPDATE_COLLECTION_SUCCESS',
	UPDATE_FAILURE: 'UPDATE_COLLECTION_FAILURE',

	DELETE_REQUEST: 'DELETE_COLLECTION_REQUEST',
	DELETE_SUCCESS: 'DELETE_COLLECTION_SUCCESS',
	DELETE_FAILURE: 'DELETE_COLLECTION_FAILURE',

	CLEAN: 'CLEAN_COLLECTION',

	SET_COVER_REQUEST: 'SET_COVER_COLLECTIONS_REQUEST',
	SET_COVER_SUCCESS: 'SET_COVER_COLLECTIONS_SUCCESS',
	SET_COVER_FAILURE: 'SET_COVER_COLLECTIONS_FAILURE',

	REQUEST_URL: 'COLLECTIONS_URL_REQUEST',
	SUCCESS_URL: 'COLLECTIONS_URL_SUCCESS',
	FAILURE_URL: 'COLLECTIONS_URL_FAILURE',

	SEND_TO_APPROVAL: 'COLLECTIONS_SEND_TO_APPROVAL',
	SUCCESS_SEND_TO_APPROVAL: 'COLLECTIONS_SEND_TO_APPROVAL_SUCESS',
	FAILURE_SEND_TO_APPROVAL: 'COLLECTIONS_SEND_TO_APPROVAL_FAILURE',

	SET_REQUEST: 'SET_COLLECTION_REQUEST',
	SET_SUCCESS: 'SET_COLLECTION_SUCCESS',
	SET_FAILURE: 'SET_COLLECTION_FAILURE',

	COLLECTION_ACTION_REQUEST: 'COLLECTION_ACTION_REQUEST',
	COLLECTION_ACTION_SUCCESS: 'COLLECTION_ACTION_SUCCESS',
	COLLECTION_ACTION_FAILURE: 'COLLECTION_ACTION_FAILURE',

	PUBLISH_REQUEST: 'COLLECTION_PUBLISH_REQUEST',
	PUBLISH_SUCCESS: 'COLLECTION_PUBLISH_SUCCESS',
	PUBLISH_FAILURE: 'COLLECTION_PUBLISH_FAILURE',

	COLLECTION_TYPE_SLUG: {
		SERIE: 'serie',
		CURSO: 'curso',
		PROJETO: 'projeto'
	},
	COLLECTION_TYPE_ID: {
		serie: 100,
		projeto: 101,
		curso: 102
	},
	COLLECTION_TYPES: {
		serie: {
			id: 100,
			slug: 'serie',
			name: 'Série',
			plural: 'Séries',
			genre: 'a',
			icon: series
		},
		curso: {
			id: 102,
			slug: 'curso',
			name: 'Curso',
			plural: 'Cursos',
			genre: 'o',
			icon: cursos
		},
		projeto: {
			id: 101,
			slug: 'projeto',
			name: 'Projeto',
			plural: 'Projetos',
			genre: 'o',
			icon: projetos
		}
	}
}

export const collectionTypes = collectionsConstants.COLLECTION_TYPES
export const collectionTypeList = Object.values(
	collectionsConstants.COLLECTION_TYPES
)
export const collectionTypeSlugs = collectionsConstants.COLLECTION_TYPE_SLUG
export const collectionTypeIds = collectionsConstants.COLLECTION_TYPE_ID
