import styled from 'styled-components'

export const GridWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	row-gap: 24px;
	align-items: center;
	justify-content: flex-start;
	margin: 0 150px;
	@media screen and (max-width: 1350px) {
		margin: 0 75px;
	}
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
	}
`
export const ListWrap = styled.div`
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: 24px;
	max-width: 720px;
	width: calc(100% - 260px);
	margin: 0 230px;
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
		width: calc(100% - 60px);
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
		width: calc(100% - 32px);
	}
`
export const PaginationWrap = styled.div`
	opacity: 0;
	display: inline-flex;
	justify-content: flex-start;
	padding-top: 40px;
	padding-bottom: 40px;
	align-items: center;
	margin: 0 230px;
	.Mui-selected {
		box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12),
			0px 9px 10px 0px rgba(0, 0, 0, 0.14),
			0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	}
	.MuiPaginationItem-text {
		font-size: 14px;
	}
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
	}
`
