import React from 'react'

const CustomTabPanel = (props) => {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{ width: '100%' }}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

export default CustomTabPanel
