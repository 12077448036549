export const orgsConstants = {
	GETALL_REQUEST: 'ORGS_GETALL_REQUEST',
	GETALL_SUCCESS: 'ORGS_GETALL_SUCCESS',
	GETALL_FAILURE: 'ORGS_GETALL_FAILURE',

	CREATE_REQUEST: 'ORGS_CREATE_REQUEST',
	CREATE_SUCCESS: 'ORGS_CREATE_SUCCESS',
	CREATE_FAILURE: 'ORGS_CREATE_FAILURE',

	CREATE_BATCH_REQUEST: 'ORGS_CREATE_BATCH_REQUEST',
	CREATE_BATCH_SUCCESS: 'ORGS_CREATE_BATCH_SUCCESS',
	CREATE_BATCH_FAILURE: 'ORGS_CREATE_BATCH_FAILURE',

	DELETE_REQUEST: 'ORGS_DELETE_REQUEST',
	DELETE_SUCCESS: 'ORGS_DELETE_SUCCESS',
	DELETE_FAILURE: 'ORGS_DELETE_FAILURE',

	GET_ALL_REQUEST: 'ORGS_GET_ALL_REQUEST',
	GET_ALL_SUCCESS: 'ORGS_GET_ALL_SUCCESS',
	GET_ALL_FAILURE: 'ORGS_GET_ALL_FAILURE',

	ADD_TEMPORARY_SUCCESS: 'ORGS_ADD_TEMPORARY_SUCCESS'
}
