import React, { forwardRef } from 'react'
import { ImageRestriction, FixedCropper } from 'react-advanced-cropper'
import { CustomCropperWrapper } from '../CustomCropperWrapper'

export const CustomCropper = forwardRef(
	// @ts-ignore
	({ stencilProps, stencilSize, onComplete, onCancel, ...props }, ref) => {
		return (
			<FixedCropper
				ref={ref}
				stencilProps={{
					handlers: false,
					lines: true,
					movable: false,
					resizable: false,
					...stencilProps
				}}
				stencilSize={stencilSize}
				imageRestriction={ImageRestriction.stencil}
				wrapperComponent={CustomCropperWrapper}
				wrapperProps={{ onComplete, onCancel }}
				{...props}
			/>
		)
	}
)

CustomCropper.displayName = 'CustomCropper'
