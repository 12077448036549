import styled from 'styled-components'

export const ContentBlockWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	gap: 8px;
	background: var(--black-006, rgba(0, 0, 0, 0.06));
`

export const InfoTitle = styled.span`
	width: 100%;
	color: ${({ theme }) => theme.colors.dark};
	font-weight: bold;
`

export const InfoText = styled.span`
	color: ${({ theme }) => theme.colors.dark};
`

export const InfoWrapper = styled.span`
	width: 100%;
`
