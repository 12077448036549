import styled from 'styled-components'

export const Container = styled.div`
	overflow: hidden;
	display: flex;
	margin: 20px 0;
	justify-content: center;
	flex-direction: column;
	gap: 24px;
	h2 {
		margin: 20px 0 10px;
		color: ${({ theme }) => theme.colors.primary};
		@media screen and (max-width: 1024px) {
			font-size: 18px;
		}
	}
	@media screen and (max-width: 1050px) {
		margin: 100px 0;
	}
`
export const ResultsHeader = styled.div`
	display: flex;
	margin: 0 230px;
	gap: 8px;
	flex-wrap: wrap;
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
	}
`
export const FilterChip = styled.div`
	display: flex;
	padding: 2px 12px;
	align-items: center;
	gap: 6px;
	border-radius: 18px;
	background-color: #194d48;
	color: #fff;
	text-align: center;
	font-family: Roboto;
	font-size: 12px;
	span {
		margin-top: 2px;
	}
	:hover {
		cursor: pointer;
	}
`
export const FilterChipIcon = styled.div`
	border-radius: 14px;
	width: 13px;
	height: 13px;
	background-color: #fff;
	i {
		font-size: 9px;
		position: relative;
		top: -3.5px;
		left: 1px;
	}
`

export const FilterChips = styled.div`
	display: flex;
	gap: 16px;
	padding-left: 16px;
`

export const DisplayModeButton = styled.div`
	display: flex;
	padding: 6px 12px;
	font-size: 14px;
	align-items: center;
	gap: 6px;
	flex-direction: row;
	:hover {
		cursor: pointer;
	}
`

export const Title = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
  color: var(--black-06, rgba(0, 0, 0, 0.60));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
	}
`
export const SearchText = styled.div`
	color: var(--primary, #194d48);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.25px;
`
export const TotalResults = styled.div`
	color: var(--black-06, rgba(0, 0, 0, 0.6));
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.25px;
`
export const TypeFilterTitle = styled.div`
	color: var(--primary, #194d48);
	font-family: Roboto;
	font-size: 14px;
	font-weight: 700;
`
export const ListWrap = styled.div`
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: 24px;
	max-width: 720px;
	width: calc(100% - 260px);
	margin: 0 230px;
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
		width: calc(100% - 60px);
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
		width: calc(100% - 32px);
	}
`
export const GridWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	row-gap: 24px;
	align-items: center;
	justify-content: flex-start;
	margin: 10px 230px;
	@media screen and (max-width: 1350px) {
		margin: 0 75px;
	}
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
	}
`
export const NoContent = styled.div`
	margin: 0 230px;
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
		width: calc(100% - 60px);
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
		width: calc(100% - 32px);
	}
`
export const PaginationWrap = styled.div`
	opacity: 0;
	display: inline-flex;
	justify-content: flex-start;
	padding-top: 40px;
	padding-bottom: 40px;
	align-items: center;
	margin: 0 230px;
	.Mui-selected {
		box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.12),
			0px 9px 10px 0px rgba(0, 0, 0, 0.14),
			0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	}
	.MuiPaginationItem-text {
		font-size: 14px;
	}
	@media screen and (max-width: 1200px) {
		margin: 0 30px;
	}
	@media screen and (max-width: 782px) {
		margin: 0 16px;
	}
`

export const Buttons = styled.div`
	position: absolute;
	width: unset !important;
	display: flex;
	> button.default {
		font-size: 1.5em;
		height: 35px;
		padding: 0 1em;
		border-radius: 4px;
		:last-child {
			background-color: ${({ theme }) => theme.colors.primary};
			:hover {
				background-color: ${({ theme }) => theme.colors.primaryDark};
			}
		}
	}
`
