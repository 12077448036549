import styled from 'styled-components'

export const SectionImage = styled.div`
	text-align: center;
	img {
		width: 100%;
		max-width: 1400px;
	}
`

export const Section = styled.section`
	padding: 64px;
`

export const Heading = styled.h1`
	padding: 16px;
	letter-spacing: 5px;
	background-color: ${({ theme }) => theme.colors.secondary};
	color: #fff;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	text-transform: uppercase;
	text-align: center;
`

export const Info = styled.div`
	display: flex;

	${({ right }) =>
		right
			? `
	    justify-content: flex-end;
        text-align: right;
        `
			: `
	    justify-content: flex-start;
        
        `}

	h2 {
		max-width: 600px;
		font-size: 2em;
		font-weight: 400;
	}
`

export const WrapPartners = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const List = styled.ul`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, 256px);
	grid-gap: 32px;
	justify-content: center;
`

export const WrapImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 128px;
	margin: 16px 0;
	img {
		max-width: 256px;
		max-height: 128px;
	}
`

export const Description = styled.div`
	position: relative;
	max-height: 200px;
	padding: 0 8px;
	overflow: hidden;
	font-size: 1.1em;
	:after {
		position: absolute;
		content: '';
		height: 64px;
		width: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0) 0%,
			white 90%,
			white 100%
		);
	}

	:hover {
		overflow: auto;
		:after {
			opacity: 0;
		}
	}
	::-webkit-scrollbar {
		width: 3px;
	}
`

export const WrapGovernance = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const WrapDocs = styled.div`
	border: 2px solid ${({ theme }) => theme.colors.primary};
	border-radius: 4px;
`

export const SliderImage = styled.div`
	h2 {
		padding: 8px;
		color: ${({ theme }) => theme.colors.primary};
	}
	img {
		height: auto;
		width: 100%;
	}
`
