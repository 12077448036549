import styled from 'styled-components'

export const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	font-size: 20px;
	gap: 8px;
	color: ${({ theme }) => theme.colors.primary};
`
export const Title = styled.div`
	font-size: 15px;
	color: #194d48;
`
export const Button = styled.div`
	display: flex;
	gap: 10px;
	border-radius: 100px;
	background: rgba(0, 0, 0, 0.05);
	padding: 12px 16px;
	align-items: center;
	:hover {
		cursor: pointer;
	}
	i {
		font-size: 16px;
	}
`

export const SharingButton = styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	color: ${({ theme }) => theme.colors.primary};
	gap: 8px;
	border-radius: 100px;
	background: rgba(0, 0, 0, 0.05);
	.MuiButton-root {
		min-width: unset;
		gap: 8px;
		padding: 0px;
	}
	.MuiButton-startIcon {
		margin-right: 0px;
		margin-left: 0px;
	}
	:hover {
		cursor: pointer;
	}
`
