import React from 'react'
import { Switch, withRouter, Redirect } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'

import Management from './Management'
import PrivateRoute from '../../components/Dashboard/PrivateRoute'
import LeftMenu from '../../components/Dashboard/LeftMenu'

import DashboardPanel from '../../components/Dashboard/DashboardPanel'
import ManageContentWrapper from './Content/ManageContentWrapper'
import ManageCollectionsWrapper from './Collections/ManageCollectionsWrapper'
import EditCollectionPage from './EditCollectionPage'
import Account from './Account'
import Curation from './Curation'

import * as S from './styles'

const Dashboard = () => {
	return (
		<S.Dashboard>
			<LeftMenu isWordpressMenu={false} />
			<S.ContentWrapper>
				<>
					{/* <TransitionablePortal onClose={handleClosePortal} open={isOpen}>
					<Message
						style={{
							right: '30px',
							position: 'fixed',
							top: '80px',
							zIndex: 1000
						}}
						size="large"
						color={type}
					>
						<Message.Header>
							{type === 'green' && <>Sucesso!</>}
							{type === 'red' && <>Ocorreu um erro</>}
						</Message.Header>
						{message}
					</Message>
				</TransitionablePortal> */}
				</>
				<Switch>
					<PrivateRoute
						exact
						path={['/painel', '/panel']}
						component={DashboardPanel}
					/>
					<PrivateRoute
						path="/painel/minha-conta"
						component={Account}
					/>
					<PrivateRoute
						path="/painel/conteúdo"
						component={ManageContentWrapper}
						requiredRoles={[
							'ROLE_USER',
							'ROLE_ADMIN',
							'ROLE_ORGANIZATION',
							'ROLE_CURADOR'
						]}
					/>
					<PrivateRoute
						path="/painel/curadoria"
						component={Curation}
						requiredRoles={[
							'ROLE_ADMIN',
							'ROLE_ORGANIZATION',
							'ROLE_CURADOR_CHEFE',
							'ROLE_CURADOR'
						]}
					/>
					<PrivateRoute
						path="/painel/coleções/:collectionTypeParam"
						component={ManageCollectionsWrapper}
						requiredRoles={[
							'ROLE_USER',
							'ROLE_ADMIN',
							'ROLE_ORGANIZATION',
							'ROLE_CURADOR'
						]}
					/>
					<PrivateRoute
						path={`/painel/editar/:friendlyUrl`}
						component={EditCollectionPage}
						requiredRoles={[
							'ROLE_USER',
							'ROLE_ADMIN',
							'ROLE_ORGANIZATION',
							'ROLE_CURADOR'
						]}
					/>
					<PrivateRoute
						path="/painel/administração"
						component={Management}
						requiredRoles={['ROLE_ADMIN']}
					/>
					<Redirect from="/painel/*" to="/painel" />
				</Switch>
			</S.ContentWrapper>
		</S.Dashboard>
	)
}

export default withRouter(Dashboard)
