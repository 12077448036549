import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'

import * as S from './styles'
import AutocompleteEditable from '../../Autocomplete/AutocompleteEditable'
import AutocompleteMultipleFreeSolo from '../../Autocomplete/AutocompleteMultipleFreeSolo'
import languageList from '../../../../constants/languages'
import {
	contentDataActions,
	locationActions,
	orgsActions
} from '../../../../app/_actions'

const fieldsMultiple = [
	{
		name: 'author',
		label: 'Autoria (obrigatório)',
		placeholder:
			'Use ENTER, vírgula ou ponto e vírgula para adicionar mais de uma autoria',
		required: true
	},
	{
		name: 'keywords',
		label: 'Palavras-chave (obrigatório)',
		placeholder:
			'Use ENTER, vírgula ou ponto e vírgula para adicionar mais de uma palavra-chave',
		required: true
	}
]

const EditContentExtras = ({
	inputs,
	onChange,
	messageError,
	messageErrorRef
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [isFillContentAlertOpened, setIsFillContentAlertOpened] =
		useState(true)

	const years = useMemo(
		() =>
			[...Array.from(Array(new Date().getFullYear() + 1).keys()), '']
				.reverse()
				.map((i) => i.toString()),
		[]
	)

	// @ts-ignore
	const { themes } = useSelector((state) => state.themes)
	// @ts-ignore
	const { orgs } = useSelector((state) => state.orgs)
	// @ts-ignore
	const { biomes } = useSelector((state) => state.biomes)

	const languages = useMemo(
		() =>
			languageList
				.map((row) => ({
					id: row.id,
					name: row.portugueseName
				}))
				.sort(function (x, y) {
					return x.name === 'Brasil'
						? -1
						: y.name === 'Brasil'
						? 1
						: 0
				}),
		[]
	)

	// @ts-ignore
	const { countries: countriesRaw } = useSelector((state) => state.countries)
	const countries = useMemo(
		() =>
			countriesRaw
				.map((row) => ({
					id: row.id.M49,
					name: row.nome
				}))
				.sort(function (x, y) {
					return x.name === 'Brasil'
						? -1
						: y.label === 'Brasil'
						? 1
						: 0
				}),
		[countriesRaw]
	)
	const brazilCountry =
		countriesRaw && countriesRaw.find((item) => item.nome == 'Brasil')

	// @ts-ignore
	const { states: statesRaw } = useSelector((state) => state.states)
	const states = useMemo(
		() =>
			statesRaw.map((row) => ({
				id: row.id,
				name: row.sigla + ' ' + row.nome
			})),
		[statesRaw]
	)

	// @ts-ignore
	const { landCategories } = useSelector((state) => state.landCategories)

	useEffect(() => {
		if (!themes || themes.length === 0) {
			dispatch(contentDataActions.getAllThemes())
		}
		if (!orgs || orgs.length === 0) {
			dispatch(orgsActions.getAll())
		}
		if (!biomes || biomes.length === 0) {
			dispatch(locationActions.getBiomes())
		}
		if (!countriesRaw || countriesRaw.length === 0) {
			dispatch(locationActions.getCountries())
		}
		if (!states || states.length === 0) {
			dispatch(locationActions.getStates())
		}
		if (!landCategories || landCategories.length === 0) {
			dispatch(locationActions.getLandCategories())
		}
	}, [dispatch, themes, orgs, biomes, countriesRaw, states, landCategories])

	const handleCountryChange = ({ target }) => {
		onChange({ target })

		// Limpa seleção do estado e bioma se pais nao for brasil
		if (
			brazilCountry &&
			(target.value.length !== 1 ||
				target.value[0] !== brazilCountry.id.M49)
		) {
			onChange({ target: { name: 'estado_id', value: [] } })
			onChange({ target: { name: 'bioma_id', value: [] } })
		}
	}

	function handleThemesChange({ target }) {
		if (target.value.length < 4) {
			onChange({ target })
		}
	}

	const handleYearChange = (_, value) => {
		onChange({
			target: {
				name: 'year',
				value
			}
		})
	}

	return (
		<>
			{isFillContentAlertOpened && (
				<S.FillContentDisclaimer>
					{t(
						'dashboard.fill-content-disclaimer',
						'Facilite e potencialize o acesso a esta publicação preenchendo todos os campos da ficha técnica'
					)}
					<IconButton
						onClick={() => {
							setIsFillContentAlertOpened(false)
						}}
					>
						<CloseIcon sx={{ color: '#fff' }} />
					</IconButton>
				</S.FillContentDisclaimer>
			)}
			{fieldsMultiple.map((field, key) => {
				return (
					<S.InputWrapper key={`multiple_${key}`}>
						<InputLabel>{field.label}</InputLabel>
						<AutocompleteMultipleFreeSolo
							fieldKey={field.name}
							initialValue={inputs[field.name]}
							onChange={onChange}
							placeholder={field.placeholder}
							isRequired={field.required}
							messageError={messageError}
						/>
					</S.InputWrapper>
				)
			})}
			<S.InputWrapper>
				<InputLabel>{t('dashboard.how_cite', 'Como citar')}</InputLabel>
				<S.StyledTextField
					name="how_cite"
					fullWidth
					value={inputs['how_cite']}
					onChange={onChange}
					placeholder="Como citar esse conteúdo?"
				/>
			</S.InputWrapper>
			<AutocompleteEditable
				label={t(
					'dashboard.organizations',
					'Organização ou instituição'
				)}
				fieldKey="organizations"
				placeholder={t(
					'dashboard.organizations-placeholder',
					'Adicionar organização(ões) ou instituição(ões)'
				)}
				data={orgs}
				// loading={fetchingOrgs}
				onChange={onChange}
				value={inputs.organizations}
				allowCreateRelated={true}
			/>
			<S.DoubleColumnInputs>
				<S.InputWrapper>
					<InputLabel>
						{t('dashboard.publication-year', 'Ano de publicação')}
					</InputLabel>
					<S.StyledAutocomplete
						disablePortal
						// isOptionEqualToValue={(option, value) =>
						// 	// @ts-ignore
						// 	option.value === value
						// }
						fullWidth
						options={years}
						noOptionsText={t(
							'dashboard.no-options',
							'Não há opções'
						)}
						value={inputs['year']}
						onChange={handleYearChange}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={t(
									'dashboard.publication-year-placeholder',
									'Adicionar ano de publicação'
								)}
							/>
						)}
					/>
				</S.InputWrapper>
				<AutocompleteEditable
					label={t('dashboard.language', 'Idioma')}
					fieldKey="language_id"
					placeholder={t(
						'dashboard.language-placeholder',
						'Adicionar um ou mais idiomas'
					)}
					data={languages}
					onChange={onChange}
					value={inputs.language_id}
				/>
			</S.DoubleColumnInputs>
			<AutocompleteEditable
				label={t('dashboard.country', 'País')}
				fieldKey="pais_id"
				placeholder={t(
					'dashboard.country-placeholder',
					'Selecione o país'
				)}
				// loading={fetchingCountries}
				data={countries}
				onChange={handleCountryChange}
				value={inputs.pais_id}
			/>
			<AutocompleteEditable
				label={t('dashboard.state', 'Estado')}
				fieldKey="estado_id"
				placeholder={t(
					'dashboard.state-placeholder',
					'Selecione o estado'
				)}
				// loading={fetchingStates}
				disabled={
					!inputs.pais_id.length ||
					inputs.pais_id.length > 1 ||
					(brazilCountry &&
						inputs.pais_id[0].id !== brazilCountry.id.M49)
				}
				data={states}
				onChange={onChange}
				value={inputs.estado_id}
			/>
			<AutocompleteEditable
				label={t('dashboard.biome', 'Bioma')}
				fieldKey="bioma_id"
				placeholder={t(
					'dashboard.biome-placeholder',
					'Selecione o bioma'
				)}
				// loading={fetchingBiomes}
				data={biomes}
				onChange={onChange}
				value={inputs.bioma_id}
				disabled={
					!inputs.pais_id.length ||
					inputs.pais_id.length > 1 ||
					(brazilCountry &&
						inputs.pais_id[0].id !== brazilCountry.id.M49)
				}
			/>
			<AutocompleteEditable
				label={t('dashboard.land-category', 'Categoria fundiária')}
				fieldKey="land_categories"
				placeholder={t(
					'dashboard.land-category-placeholder',
					'Adicionar categoria(s) fundiária(s)'
				)}
				// loading={fetchingLandCategories}
				data={landCategories}
				onChange={onChange}
				value={inputs.land_categories}
			/>
			<AutocompleteEditable
				label={
					t('dashboard.themes', 'Tema(s)') +
					' ' +
					t('dashboard.mandatory', '(obrigatório)')
				}
				fieldKey="themes"
				placeholder={t(
					'dashboard.themes-placeholder',
					'Selecione até três temas que se relacionam com o conteúdo'
				)}
				// loading={fetchingThemes}
				data={themes}
				onChange={handleThemesChange}
				value={inputs.themes}
				error={messageError && !inputs.themes.length ? true : false}
			/>

			<Alert
				sx={{
					width: '100%',
					visibility: messageError ? 'unset' : 'hidden'
				}}
				ref={messageErrorRef}
				severity="error"
			>
				{messageError}
			</Alert>
		</>
	)
}

export default EditContentExtras
