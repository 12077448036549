import { Segment } from 'semantic-ui-react'
import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		background-color: ${({ theme }) => theme.colors.secondary};

		:hover {
			background-color: rgb(125 46 0);
		}
	}
	@media screen and (max-width: 800px) {
		flex-direction: column;
	}
`

export const InputWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: -650px;
	margin-bottom: 10px;
	align-items: center;
	button {
		padding: 0;
		height: 0;
	}
	@media screen and (max-width: 800px) {
		width: 100%;
		button {
			min-width: 0px;
		}
		i {
			right: 50px;
		}
	}
`

export const Title = styled.div`
	color: #efebe9;
	text-align: center;
	font-family: Roboto;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 56px;
`

export const WrapFilter = styled.div`
	display: flex;
	justify-content: center;
	height: 0;
	overflow: hidden;
	transition: height 0.75s ease-in-out;
	&&.show {
		height: auto;
		display: flex;
	}
`
export const WrapFilterButton = styled.div`
	display: flex;
	justify-content: center;
	height: 0;
	overflow: hidden;
	transition: height 1s ease-in-out;
	&&.show {
		height: 70px;
		display: flex;
	}
`

export const Advanced = styled(Segment)`
	display: flex;
	max-width: 1280px;
	width: 50vw;
	justify-content: center;
	> div {
		width: 100%;
		.ui.input {
			width: 100%;
		}
	}
	h1 {
		color: ${({ theme }) => theme.colors.primary};
		min-height: 25px;
	}
	@media screen and (max-width: 800px) {
		width: 100%;
	}
`

export const Buttons = styled.div`
	position: absolute;
	width: unset !important;
	display: flex;
	bottom: 5px;
	right: 35px;
	> button.default {
		font-size: 1.5em;
		height: 35px;
		padding: 0 1em;
		background-color: ${({ theme }) => theme.colors.secondary};
		border-radius: 4px;
		:last-child {
			background-color: ${({ theme }) => theme.colors.primary};
			:hover {
				background-color: ${({ theme }) => theme.colors.primaryDark};
			}
		}
	}
`

export const Close = styled.div`
	position: absolute;
	width: unset !important;
	display: flex;
	top: 5px;
	right: 35px;
	z-index: 2;
	> button.default {
		height: 35px;
		padding: 0;
		background-color: unset;
		color: ${({ theme }) => theme.colors.darkHover};
	}
`

export const AdvancedButton = styled.div`
	display: flex;
	justify-content: center;
	margin: 20px 0;
	> div.ui.item.menu {
		width: 50vw;
	}
`
