import React from 'react'

import * as S from './styles'
import { truncate } from '../../../utils/functions'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'

/**
 * @function ContentBlockForGrid
 */
const LandingPageContentBlockForGrid = ({
	img,
	title,
	onClick,
	contentType
}) => {
	return (
		<S.Wrapper onClick={onClick} className="proteja-wrapper">
			<S.Block className="proteja-block">
				{contentType.id && (
					<S.TopWrapper className="proteja-top-wrapper">
						<S.ContentTypeWrapper className="proteja-content-type-wrapper">
							<ContentOrCollectionTypeIcon id={contentType.id} />
							<S.Type className="proteja-content-type">
								{contentType.name}
							</S.Type>
						</S.ContentTypeWrapper>
					</S.TopWrapper>
				)}
				<img alt={title} src={img} />
			</S.Block>
			<S.Title className="proteja-title">{truncate(title, 60)}</S.Title>
		</S.Wrapper>
	)
}

export default LandingPageContentBlockForGrid
