import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'

import * as S from '../styles'

const AutocompleteMultipleFreeSolo = ({
	fieldKey,
	placeholder,
	isRequired,
	messageError,
	initialValue,
	onChange
}) => {
	const [value, setValue] = useState([])

	useEffect(() => {
		setValue((currValue) => {
			if (
				initialValue !== currValue ||
				JSON.stringify(initialValue || '') !==
					JSON.stringify(currValue || '')
			) {
				return initialValue
			}
			return currValue
		})
	}, [initialValue])

	const handleChangeValue = (_, values) => {
		if (!values) {
			setValue(values)
			return
		}
		const newValues = []
		for (let valueRaw of values) {
			const splitValues = valueRaw.split(',')
			for (let newValue of splitValues) {
				newValues.push(newValue)
			}
		}
		setValue(newValues)
	}

	const handleRenderTags = (value, getTagProps) => {
		return value.map((option, index) => {
			const { key, ...props } = getTagProps({
				index
			})
			return (
				<S.StyledChip
					key={key}
					// @ts-ignore
					label={option}
					{...props}
				/>
			)
		})
	}

	const handleBlur = (e) => {
		if (e.target.value.length > 0) {
			const splitValues = e.target.value.split(',').map((v) => v.trim())
			const newValue = [...value, ...splitValues]
			setValue(newValue)
			e.target.value = ''
			onChange({
				target: {
					name: fieldKey,
					value: newValue
				}
			})
			return
		}
		onChange({
			target: {
				name: fieldKey,
				value
			}
		})
	}

	return (
		<S.StyledAutocomplete
			multiple={true}
			value={value}
			// disablePortal={true}
			// name={field.name}
			fullWidth
			options={[]}
			onChange={handleChangeValue}
			freeSolo
			renderTags={handleRenderTags}
			clearOnBlur={true}
			renderInput={(params) => {
				params.inputProps.onKeyDown = (e) => {
					// @ts-ignore
					if (['Comma', 'Slash'].includes(e.code) && e.target.value) {
						e.preventDefault()
						e.stopPropagation()
						// @ts-ignore
						if (e.target.value.length > 0) {
							// @ts-ignore
							const splitValues = e.target.value
								.split(',')
								.map((v) => v.trim())
							const newValue = [...value, ...splitValues]
							onChange({
								target: {
									name: fieldKey,
									value: newValue
								}
							})
						}
					}
				}
				return (
					<TextField
						{...params}
						placeholder={placeholder}
						error={isRequired && messageError && !value.length}
						helperText={
							isRequired && messageError && !value.length
								? 'Campo obrigatório'
								: ''
						}
						onBlur={handleBlur}
					/>
				)
			}}
		/>
	)
}

export default AutocompleteMultipleFreeSolo
