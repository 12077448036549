const cursoTypeList = [
	{
		id: 'presencial',
		name: 'Presencial'
	},
	{
		id: 'virtual',
		name: 'À distância'
	},
	{
		id: 'hibrido',
		name: 'Híbrido'
	},
	{
		id: 'alternancia',
		name: 'Alternância'
	}
]

export default cursoTypeList
