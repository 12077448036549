import { locationConstants } from '../_constants'
import { locationService } from '../_services'

export const locationActions = {
	getCountries,
	getStates,
	getCities,
	getBiomes,
	getLandCategories
}

function getCountries() {
	return (dispatch) => {
		dispatch(request())
		locationService.getCountries().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.COUNTRY_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.COUNTRY_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.COUNTRY_FAILURE, error }
	}
}

function getStates() {
	return (dispatch) => {
		dispatch(request())
		locationService.getStates().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.STATE_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.STATE_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.STATE_FAILURE, error }
	}
}

function getCities(state) {
	return (dispatch) => {
		dispatch(request())
		locationService.getCities(state).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.CITY_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.CITY_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.CITY_FAILURE, error }
	}
}

function getBiomes() {
	return (dispatch) => {
		dispatch(request())
		locationService.getBiomes().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.BIOME_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.BIOME_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.BIOME_FAILURE, error }
	}
}

function getLandCategories() {
	return (dispatch) => {
		dispatch(request())
		locationService.getLandCategories().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.LAND_CATEGORY_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.LAND_CATEGORY_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.LAND_CATEGORY_FAILURE, error }
	}
}
