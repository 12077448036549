import styled from 'styled-components'

export const Wrap = styled.div`
	max-width: 1400px;
	margin: 0 auto;
`

export const Areas = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	@media screen and (max-width: 800px) {
		flex-direction: column;
	}
`
export const AreaIcon = styled.img`
	max-width: 180px;
`

export const SectionImage = styled.img`
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
`
