import React from 'react'

import * as S from './styles'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'
import theme from '../../../styles/theme'

const ContentTypeList = ({ rows, active, onClick }) => {
	return rows.map((item) => {
		return (
			<S.ContentTypesBlock
				key={item.id}
				active={item.id === active}
				onClick={() => onClick(item.id)}
			>
				<ContentOrCollectionTypeIcon
					id={item.id}
					color={item.id === active ? '#fff' : theme.colors.primary}
				/>
				<S.ContentTypesBlockTitle active={item.id === active}>
					{item.name}
				</S.ContentTypesBlockTitle>
			</S.ContentTypesBlock>
		)
	})
}

export default ContentTypeList
