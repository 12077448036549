import React from 'react'
import * as S from './styles'

import { InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Icon } from 'semantic-ui-react'

const CropImage = ({ setImageSrc, currentCoverImage, setIsEditing }) => {
	// const [fileName, setFileName] = useState(null)
	const { t } = useTranslation()
	const coverInput = React.useRef()

	const onFileCoverChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			// setFileName(file.name)
			let imageDataUrl = await readFile(file)
			setImageSrc(imageDataUrl)
			setIsEditing(true)
		}
	}

	function readFile(file) {
		return new Promise((resolve) => {
			const reader = new FileReader()
			reader.addEventListener('load', () => resolve(reader.result), false)
			reader.readAsDataURL(file)
		})
	}

	const currentCoverImageSrc =
		!currentCoverImage ||
		(!currentCoverImage.image && !currentCoverImage.canvas)
			? null
			: currentCoverImage.image
			? currentCoverImage.image
			: currentCoverImage.canvas.toDataURL()

	return (
		<S.FillImageContainer>
			<InputLabel>
				{t('dashboard.content-modal.cover-image', 'Imagem de capa') +
					' ' +
					t('dashboard.mandatory', '(obrigatório)')}
			</InputLabel>
			<S.InputContainer>
				<S.FileInput>
					<S.FileButton
						variant="contained"
						onClick={() =>
							coverInput.current
								? // @ts-ignore
								  coverInput.current.click()
								: {}
						}
					>
						{currentCoverImageSrc ? 'Trocar...' : 'Procurar...'}
					</S.FileButton>
					<input
						type="file"
						accept="image/*"
						ref={coverInput}
						onChange={onFileCoverChange}
						style={{ display: 'none' }}
					/>
					{/*fileName ? (
						<a style={{ color: 'black' }}>{fileName}</a>
					) : (
						<S.FileInputPlaceholder>
							Nenhum arquivo adicionado
						</S.FileInputPlaceholder>
					)*/}
				</S.FileInput>
			</S.InputContainer>
			{currentCoverImageSrc && (
				<S.CurrentImageContainer>
					<S.ActionButtonContainer>
						<Icon
							name="edit"
							size="small"
							onClick={() => {
								setImageSrc(currentCoverImageSrc)
								setIsEditing(true)
							}}
						/>
					</S.ActionButtonContainer>
					<S.CoverImage src={currentCoverImageSrc} />
				</S.CurrentImageContainer>
			)}
		</S.FillImageContainer>
	)
}

export default CropImage
