import React, { memo, useContext, useMemo } from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FilterContext } from '../../../../context/advancedFilter'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { searchActions } from '../../../../app/_actions'
import languageList from '../../../../constants/languages'

const GroupLocation = ({
	fetchingBiomes,
	dataBiomes,
	fetchingLandCategories,
	dataLandCategories,
	fetchingCountries,
	dataCountries,
	fetchingStates,
	dataStates,
	topics
}) => {
	const dispatch = useDispatch()
	const { filter, setFilter } = useContext(FilterContext)
	const { t } = useTranslation()

	const dataLanguages = useMemo(
		() =>
			languageList.map((row) => ({
				key: row.id,
				text: row.portugueseName,
				value: row.id
			})),
		[]
	)

	const brazilCountry =
		dataCountries && dataCountries.find((item) => item.text == 'Brasil')

	function getLabelObjectFilter(obj_name, value) {
		let obj_qs = null
		if (obj_name == 'bioma_id') {
			obj_qs = dataBiomes
		}
		if (obj_name == 'language_id') {
			obj_qs = dataLanguages
		}
		if (obj_name == 'land_category_id') {
			obj_qs = dataLandCategories
		}
		if (obj_name == 'pais_id') {
			obj_qs = dataCountries
		}
		if (obj_name == 'estado_id') {
			obj_qs = dataStates
		}
		if (obj_name == 'theme_id') {
			obj_qs = topics
		}
		if (obj_name == 'year') {
			return value
		}

		return obj_qs.find((item) => item.value == value).text
	}
	function handleChange({ target }) {
		const { name, value } = target
		setFilter((filter) => ({ ...filter, [name]: value }))
		dispatch(
			searchActions.getFilteredResults(
				{ ...filter, [name]: value },
				1,
				10
			)
		)
		const filterToApply = { ...filter }
		if (value.length > 1) {
			for (let index in value) {
				const idx = parseInt(index)
				let label = getLabelObjectFilter(name, value[idx])
				if (idx === 0) {
					filterToApply[name] = [{ id: value[idx], label: label }]
				} else {
					filterToApply[name].push({ id: value[idx], label: label })
				}
			}
			return
		}

		const label = getLabelObjectFilter(name, value[0])
		filterToApply[name] = [{ id: value[0], label: label }]
	}

	const handleCountryChange = ({ target }) => {
		handleChange({ target })
		// Limpa seleção do estado se pais nao for brasil
		if (
			brazilCountry &&
			!target.value.find((i) => i == brazilCountry.value) &&
			filter.estado_id.length > 0
		) {
			handleChange({ target: { name: 'estado_id', value: [] } })
		}
	}

	const yearList = Array.from(Array(new Date().getFullYear() + 1).keys())

	return (
		<S.Wrapper>
			<LocationSearch
				name="year"
				placeholder={t('search.year', 'Ano')}
				data={[...yearList].reverse().map((i) => {
					return { key: i, text: i, value: i }
				})}
				onChange={handleChange}
				value={filter.year}
				loading={false}
				disabled={false}
			/>
			<LocationSearch
				name="language_id"
				placeholder={t('search.language', 'Idioma')}
				data={dataLanguages}
				onChange={handleChange}
				value={filter.language_id}
				loading={false}
				disabled={false}
			/>
			<LocationSearch
				name="bioma_id"
				placeholder={t('search.biome', 'Bioma')}
				loading={fetchingBiomes}
				data={dataBiomes}
				onChange={handleChange}
				value={filter.bioma_id}
				disabled={false}
			/>
			<LocationSearch
				name="land_category_id"
				placeholder={t(
					'search.land_category',
					'Tipo de área protegida'
				)}
				loading={fetchingLandCategories}
				data={dataLandCategories}
				onChange={handleChange}
				value={filter.land_category_id}
				disabled={false}
			/>
			<LocationSearch
				name="pais_id"
				placeholder={t('search.country', 'País')}
				loading={fetchingCountries}
				data={dataCountries}
				onChange={handleCountryChange}
				value={filter.pais_id}
				disabled={false}
			/>
			<LocationSearch
				name="estado_id"
				placeholder={t('search.state', 'Estado')}
				loading={fetchingStates}
				data={dataStates}
				onChange={handleChange}
				disabled={
					brazilCountry &&
					!filter.pais_id.find((i) => i == brazilCountry.value)
				}
				value={filter.estado_id}
			/>
			<LocationSearch
				name="theme_id"
				placeholder={t('search.theme', 'Tema')}
				data={topics}
				onChange={handleChange}
				value={filter.theme_id}
				loading={false}
				disabled={false}
			/>
		</S.Wrapper>
	)
}

export default memo(GroupLocation)

export const WrapInput = styled.div`
	display: flex;
	height: 40px;
	width: 195px;
	align-items: center;
	border-radius: 4px;
	label {
		font-weight: bold;
	}
	> div {
		padding: 8px 12px;
	}
	div.text {
		display: inline-block !important;
		position: relative !important;
		padding: 0 !important;
		margin: 0 !important;
		color: var(--black-058, rgba(0, 0, 0, 0.58)) !important;
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		/* line-height: 24px; */
		letter-spacing: 0.5px;
	}
	span.text {
		font-size: 13px;
	}
	input.search {
		position: absolute !important;
	}
	a.ui.label {
		display: none !important;
	}
	> div > div {
		width: max-content;
	}
	.ui.dropdown {
		padding: 0.78571429em 2.1em 0.78571429em 1em;
	}
	@media screen and (max-width: 1900px) {
		width: 100%;
	}
`

function LocationSearch({
	name,
	placeholder,
	loading,
	data,
	value,
	disabled,
	onChange
}) {
	const handleMultipleSelect = (_, object) => {
		onChange({
			target: {
				name,
				value: object.value
			}
		})
	}
	return (
		<Form size="big">
			<WrapInput>
				<Dropdown
					placeholder={placeholder}
					fluid
					selection
					clearable
					lazyLoad
					multiple={true}
					upward={false}
					disabled={disabled}
					value={value}
					onChange={handleMultipleSelect}
					noResultsMessage="Nenhum valor encontrado."
					search
					loading={loading}
					options={data}
				/>
			</WrapInput>
		</Form>
	)
}
