import styled from 'styled-components'

export const InputContainer = styled.div.attrs((props) => ({
	isHome: props.isHome
}))`
	${({ isHome }) =>
		isHome
			? `
          width: calc(100% + 16px);
      `
			: `
          width: 715px;
  `}
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.2);
	i {
		font-size: 20px;
	}
	@media screen and (min-width: 1050px) and (max-width: 1200px) {
		${({ isHome }) =>
			!isHome &&
			`
        width: 510px;
  `}
	}
	@media screen and (max-width: 782px) {
		${({ isHome }) =>
			!isHome &&
			`
        width: calc(100% - 16px);
  `}
	}
`

export const InputBackground = styled.div`
	display: flex;
	overflow: hidden;
	flex: 1;
	align-items: center;
	height: 54px;
	padding: 8px 0px 8px 16px;
	gap: 16px;
	color: ${({ theme }) => theme.colors.primary};
	border-radius: 16px;
	background: #fff;
	::placeholder {
		color: ${({ theme }) => theme.colors.primary};
		opacity: 0.5;
	}
`

export const Input = styled.input`
	flex: 1;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 400;
	:focus,
	:hover {
		outline: none;
		border-color: #fff;
	}
`
