import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import App from './App'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'

import * as serviceWorker from './serviceWorker'
import GlobalStyles from './styles/global'
import theme from './styles/theme'
import './i18n'
import Login from './login/Login'
import Carousel from './components/Carousel'
import store from './app/store'
import muiTheme from './styles/muiTheme'
// const ThemesGrid = lazy(() => import('./themes-grid/ThemesGrid'))
import ThemesGrid from './themes-grid/ThemesGrid'
import LeftMenu from './components/Dashboard/LeftMenu'
import history from './app/history'
import { Router } from 'react-router-dom'

const rootElement = document.getElementById('root')
if (rootElement) {
	const root = createRoot(rootElement)
	root.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<App />
				<GlobalStyles />
			</ThemeProvider>
		</Provider>
	)
}

const rootLoginElements = Array.from(
	document.getElementsByClassName('proteja-login')
)
rootLoginElements.map((rootElement) => {
	const root = createRoot(rootElement)
	root.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<Login />
			</ThemeProvider>
		</Provider>
	)
})

// const rootLoginElement = document.getElementById('proteja-login')
// if (rootLoginElement) {
// 	const root = createRoot(rootLoginElement)
// 	root.render(
// 		<Provider store={store}>
// 			<ThemeProvider theme={theme}>
// 				<Login />
// 			</ThemeProvider>
// 		</Provider>
// 	)
// }

const rootCarouselElements = Array.from(
	document.getElementsByClassName('eita-carousel-root')
)

rootCarouselElements.map((rootElement, carouselIndex) => {
	const themes = rootElement.getAttribute('themes')
		? rootElement.getAttribute('themes').split(',')
		: []
	const pages = rootElement.getAttribute('pages') || ''
	const searchText = rootElement.getAttribute('search_text') || ''
	const mode = rootElement.getAttribute('mode') || 'horizontal'
	const fontColor = rootElement.getAttribute('font_color') || ''
	const margin = rootElement.getAttribute('margin') || ''

	const root = createRoot(rootElement)
	root.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={muiTheme}>
					<Carousel
						carouselIndex={carouselIndex}
						themes={themes}
						pages={pages}
						searchText={searchText}
						mode={mode}
						fontColor={fontColor}
						margin={margin}
					/>
					<GlobalStyles />
				</MuiThemeProvider>
			</ThemeProvider>
		</Provider>
	)
})

const rootThemesGridElement = document.getElementById('themes-card-grid-root')
if (rootThemesGridElement) {
	// const themesGridStore = createProtejaStore()
	const root = createRoot(rootThemesGridElement)
	root.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<ThemesGrid />
			</ThemeProvider>
		</Provider>
	)
}

const rootPanelMenulement = document.getElementById('panel-menu-root')
if (rootPanelMenulement) {
	// const themesGridStore = createProtejaStore()
	const root = createRoot(rootPanelMenulement)
	root.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<Router history={history}>
					<LeftMenu isWordpressMenu={true} />
				</Router>
			</ThemeProvider>
		</Provider>
	)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
