import React from 'react'
import cn from 'classnames'
import { CropperFade } from 'react-advanced-cropper'
import {
	getAbsoluteZoom,
	getZoomFactor
} from 'advanced-cropper/extensions/absolute-zoom'
import { Icon } from 'semantic-ui-react'
import { CustomCropperNavigation } from '../CustomCropperNavigation'
import './styles.css'

export const CustomCropperWrapper = ({
	cropper,
	children,
	loaded,
	className,
	onComplete,
	onCancel
}) => {
	const state = cropper.getState()

	const settings = cropper.getSettings()

	const absoluteZoom = getAbsoluteZoom(state, settings)

	const onZoom = (value, transitions = false) => {
		cropper.zoomImage(getZoomFactor(state, settings, value), {
			transitions: !!transitions
		})
	}

	return (
		<CropperFade
			className={cn('custom-wrapper', className)}
			visible={state && loaded}
		>
			<div className="custom-wrapper__actionButtons">
				<div className="custom-wrapper__actionButton">
					<Icon
						name="check"
						size="large"
						onClick={() => {
							if (onComplete) {
								onComplete()
							}
						}}
					/>
				</div>
				<div className="custom-wrapper__actionButton">
					<Icon
						name="close"
						size="large"
						onClick={() => {
							if (onCancel) {
								onCancel()
							}
						}}
					/>
				</div>
			</div>
			{children}
			<CustomCropperNavigation
				className="custom-wrapper__navigation"
				zoom={absoluteZoom}
				onZoom={onZoom}
			/>
		</CropperFade>
	)
}
