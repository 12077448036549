import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

function LoadingDimmer() {
	return (
		<thead>
			<tr>
				<td>
					<Dimmer active inverted>
						<Loader>Carregando...</Loader>
					</Dimmer>
				</td>
			</tr>
		</thead>
	)
}

export default LoadingDimmer
