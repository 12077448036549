import styled from 'styled-components'

export const ContentTypesBlock = styled.div.attrs((props) => ({
	active: props.active
}))`
	flex: 1 0 0;
	display: flex;
	padding: 16px 12px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	cursor: pointer;
	background-color: ${({ theme, active }) =>
		active ? theme.colors.secondary : 'transparent'};
	@media screen and (max-width: 782px) {
		flex-direction: row;
		gap: 8px;
	}
`

export const ContentTypesBlockTitle = styled.div.attrs((props) => ({
	active: props.active
}))`
	color: ${({ theme, active }) => (active ? '#fff' : theme.colors.primary)};
	text-align: center;
`
