import styled from 'styled-components'
import theme from '../../../styles/theme'

export const ForgotPassword = styled.div`
	a {
		color: ${() => theme.colors.primary};
		font-family: Roboto;
		font-size: 15px;
		font-weight: 400;
		text-decoration-line: underline;
	}
`

export const Recaptcha = styled.div`
	display: flex;
	justify-content: center;
	padding: 8px 0;
	margin-left: 4px;
`

export const CreateAccount = styled.span`
	color: ${() => theme.colors.black06};
	display: inline-block;
	font-family: Roboto;
	font-size: 15px;
	padding: 40px 8px;

	a {
		color: ${() => theme.colors.primary};
		font-family: Roboto;
		font-size: 15px;
		font-weight: 700;
		text-decoration-line: underline;
	}
`
