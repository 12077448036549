import React from 'react'
import { Link } from 'react-router-dom'

import * as S from './styles'

import heading from '../../../assets/program/heading.jpg'
import library from '../../../assets/program/library.jpg'
import talks from '../../../assets/program/talks.jpg'
import podcasts from '../../../assets/program/podcasts.jpg'
import about from '../../../assets/program/about.jpg'

function Program() {
	return (
		<S.Wrap className="page">
			<S.SectionImage
				src={heading}
				alt="Proteja - Áreas protegidas do Brasil"
				loading="lazy"
			/>
			<S.Areas>
				<Link to="/">
					<S.AreaIcon
						loading="lazy"
						src={library}
						alt="Acesse a biblioteca"
					/>
				</Link>
				<a
					target="blank"
					href="https://www.youtube.com/watch?v=VLV4AcSoZhQ&list=PLY-ieMbDz-LwsJOSkFkD_TBt-weo9ALGQ"
				>
					<S.AreaIcon
						loading="lazy"
						src={talks}
						alt="Acessos os conteúdos do Proteja Talks"
					/>
				</a>
				<a target="blank" href="https://anchor.fm/protejabrasil">
					<S.AreaIcon
						loading="lazy"
						src={podcasts}
						alt="Acesse os Podcasts do Portal"
					/>
				</a>
			</S.Areas>
			<S.SectionImage
				style={{ marginBottom: 50 }}
				src={about}
				loading="lazy"
				alt="O Portal Proteja engloba áreas protegidas de todos os biomas do Brasil."
			/>
			<div style={{ textAlign: 'center' }}>
				<iframe
					width="100%"
					height="720"
					src="https://www.youtube.com/embed/w-2bteu9W2k"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>
		</S.Wrap>
	)
}

export default Program
