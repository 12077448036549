import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import { Button } from '@mui/material'
export const FillImageContainer = styled.div`
	position: relative;
	width: 100%;
`

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
`

export const CoverImage = styled.img`
	width: 100%;
`

export const FileInput = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

export const CurrentImageContainer = styled.div`
	margin-top: 8px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const ActionButtonContainer = styled.div`
	position: absolute;
	top: 4px;
	right: 4px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 100px;
	padding: 4px 6px;
	:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
	display: none;
`

export const FileButton = styled(Button)`
	align-self: flex-start;
	&.MuiButtonBase-root {
		text-transform: none;
		font-size: 14px;
	}
`

export const FileInputPlaceholder = styled.span`
	color: rgba(0, 0, 0, 0.35);
`

export const Panel = styled(Grid)`
	background-color: white;
	form {
		width: 100%;
	}
`

export const Wrapper = styled.div`
	margin-top: 16px;
	position: relative;
	width: 400px;
	height: 300px;
`

export const WrappInput = styled.div`
	margin-top: 12px;
	width: 100%;
`

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

export const WrappButton = styled.div`
	width: 100%;
	margin: 30px 10px 0;
`
