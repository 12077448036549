import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import * as S from './styles'
import { useDispatch, useSelector } from 'react-redux'
import languageList from '../../../constants/languages'
import { locationActions } from '../../../app/_actions'
import { useTranslation } from 'react-i18next'

// const host = process.env.REACT_APP_WP_HOSTNAME
// const baseUrl = `${host}/conteudo/`
function ContentSheet({ item, showEmptyFields = false }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	// @ts-ignore
	const { states } = useSelector((state) => state.states)
	// @ts-ignore
	const { countries } = useSelector((state) => state.countries)
	// @ts-ignore
	const { biomes } = useSelector((state) => state.biomes)
	// @ts-ignore
	const { landCategories } = useSelector((state) => state.landCategories)

	useEffect(() => {
		if (!states || states.length === 0) {
			dispatch(locationActions.getStates())
		}
		if (!countries || countries.length === 0) {
			dispatch(locationActions.getCountries())
		}
		if (!biomes || biomes.length === 0) {
			dispatch(locationActions.getBiomes())
		}
		if (!landCategories || landCategories.length === 0) {
			dispatch(locationActions.getLandCategories())
		}
	}, [dispatch, biomes, countries, states, landCategories])
	let itemFields = [
		{
			key: 'author',
			label: t('item-sheet.author', 'Autoria'),
			customFormat: (value) => (value ? value.replace(/[,]/g, ', ') : '')
		},
		// { key: 'contributors', label: 'Colaboradoras' },
		{ key: 'keywords', label: t('item-sheet.keywords', 'Palavras-chave') },
		{ key: 'how_cite', label: 'Como citar' },
		{
			key: 'organizations',
			label: t('item-sheet.organizations', 'Organização ou Instituição'),
			customFormat: (values) =>
				values ? values.map((value) => value.name).join(', ') : ''
		},
		{ key: 'year', label: t('item-sheet.year', 'Ano de publicação') },
		{
			key: 'language_ids',
			label: t('item-sheet.language', 'Idioma'),
			customFormat: (values) => {
				if (!values) {
					return ''
				}
				return values
					.map((id) => {
						const languageFound = languageList.find(
							(language) => language.id === id
						)
						return languageFound ? languageFound.portugueseName : id
					})
					.join(', ')
			}
		},
		{
			key: 'pais_ids',
			label: t('item-sheet.country', 'País'),
			customFormat: (values) => {
				if (!values || !countries) {
					return ''
				}
				return values
					.map((id) => {
						const countryFound = countries.find(
							(country) => country.id.M49 === id
						)
						return countryFound ? countryFound.nome : id
					})
					.join(', ')
			}
		},
		{
			key: 'bioma_ids',
			label: t('item-sheet.biomes', 'Bioma(s)'),
			customFormat: (values) => {
				if (!values || !biomes) {
					return ''
				}
				return values
					.map((id) => {
						const biomeFound = biomes.find(
							(biome) => biome.id === id
						)
						return biomeFound ? biomeFound.name : id
					})
					.join(', ')
			}
		},
		{
			key: 'estado_ids',
			label: t('item-sheet.state', 'Estado(s)'),
			customFormat: (values) => {
				if (!values || !states) {
					return ''
				}
				return values
					.map((id) => {
						const stateFound = states.find(
							(state) => state.id === id
						)
						return stateFound ? stateFound.nome : id
					})
					.join(', ')
			}
		},
		{
			key: 'land_categories',
			label: t('item-sheet.land-category', 'Categoria fundiária'),
			customFormat: (values) => {
				if (!values || !landCategories) {
					return ''
				}
				return values
					.map((value) => {
						const landCategoryFound = landCategories.find(
							(landCategory) => landCategory.id === value.id
						)
						return landCategoryFound
							? landCategoryFound.name
							: value.id
					})
					.join(', ')
			}
		},
		{
			key: 'themes',
			label: t('item-sheet.themes', 'Tema(s)'),
			customFormat: (values) =>
				values ? values.map((value) => value.name).join(', ') : ''
		},
		{
			key: 'content_link',
			label: t('item-sheet.content-link', 'Link externo'),
			customFormat: (value) =>
				value ? (
					<S.ContentLink
						href={value}
						target="_blank"
						rel="noreferrer"
					>
						{value}
					</S.ContentLink>
				) : (
					''
				)
		}
	]

	if (!item.pais_ids || !item.pais_ids.includes(76)) {
		itemFields = itemFields.filter(
			(row) => !['estado_ids', 'bioma_ids'].includes(row.key)
		)
	}

	const visibleFields = showEmptyFields
		? itemFields
		: itemFields.filter((row) => item[row.key])

	return (
		<S.Wrapper>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container component="div" spacing={0}>
					{visibleFields.map((row, index) => (
						<React.Fragment key={`item_sheet_${index}`}>
							<Grid item xs={12} sm={3} className="content-type">
								{row.label}
							</Grid>
							<Grid item xs={12} sm={9} className="content-info">
								{row.customFormat
									? row.customFormat(item[row.key])
									: Array.isArray(item[row.key])
									? item[row.key].join(', ')
									: item[row.key]}
							</Grid>
						</React.Fragment>
					))}
				</Grid>
			</Box>
		</S.Wrapper>
	)
}

export default ContentSheet
