import React from 'react'
import * as S from './styles'
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from 'react-share'
import { TextField } from '@mui/material'
import theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'

function SharingBar({ item }) {
	const isCollection = !item.content_type
	const { t, i18n } = useTranslation()
	function getContentLink(item) {
		if (isCollection) {
			return i18n.language === 'es-ES'
				? `${process.env.REACT_APP_HOSTNAME}${item.collection_type.slug}-es/${item.friendly_url}`
				: `${process.env.REACT_APP_HOSTNAME}${item.collection_type.slug}/${item.friendly_url}`
		} else {
			return i18n.language === 'es-ES'
				? `${process.env.REACT_APP_HOSTNAME}contenido/${item.friendly_url}`
				: `${process.env.REACT_APP_HOSTNAME}conteudo/${item.friendly_url}`
		}
	}

	function handleCopy(item) {
		const link = getContentLink(item)
		navigator.clipboard.writeText(link)
	}
	return (
		<S.SharingBar>
			<S.CopyLink onClick={() => handleCopy(item)}>
				{t('sharing-bar.copy-link', 'Copiar link')}
			</S.CopyLink>
			<TextField
				label=""
				variant="outlined"
				value={getContentLink(item)}
			/>
			<S.SharingBarRow>
				<WhatsappShareButton
					title={item.title}
					url={getContentLink(item)}
				>
					<WhatsappIcon
						size={42}
						round={true}
						bgStyle={{
							fill: theme.colors.primary
						}}
					/>
				</WhatsappShareButton>
				<TwitterShareButton
					title={item.title}
					url={getContentLink(item)}
				>
					<TwitterIcon
						size={42}
						round={true}
						bgStyle={{
							fill: theme.colors.primary
						}}
					/>
				</TwitterShareButton>
				<LinkedinShareButton
					title={item.title}
					url={getContentLink(item)}
				>
					<LinkedinIcon
						size={42}
						round={true}
						bgStyle={{
							fill: theme.colors.primary
						}}
					/>
				</LinkedinShareButton>
				<FacebookShareButton
					title={item.title}
					url={getContentLink(item)}
				>
					<FacebookIcon
						size={42}
						round={true}
						bgStyle={{
							fill: theme.colors.primary
						}}
					/>
				</FacebookShareButton>
				<EmailShareButton
					title={item.title}
					subject={'Conteúdo novo no proteja!'}
					body={'Confira:'}
					url={getContentLink(item)}
				>
					<EmailIcon
						size={42}
						round={true}
						bgStyle={{
							fill: theme.colors.primary
						}}
					/>
				</EmailShareButton>
			</S.SharingBarRow>
		</S.SharingBar>
	)
}

export default SharingBar
