import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
	ContentBlockForGrid,
	ContentBlock,
	ContentOrCollectionDetailDialog
} from '../../eita-react-components'
import * as S from './styles'
import SharingBar from '../PublicPages/SharingBar'
import noImg from '../../assets/no-image.jpg'
import { contentActions, carouselActions } from '../../app/_actions'
import { useTranslation } from 'react-i18next'

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height
	}
}

function Carousel({
	carouselIndex,
	pages,
	themes,
	searchText,
	mode,
	fontColor,
	margin
}) {
	const { i18n } = useTranslation()
	const dispatch = useDispatch()
	const { object: activeItem } = useSelector(
		// @ts-ignore
		(state) => state.content
	)
	// @ts-ignore
	const { carousels } = useSelector((state) => state.carousel)
	const filtered = carousels.find(
		(carousel) => carousel.key === carouselIndex.toString()
	)

	const [contentDetailOpen, setContentDetailOpen] = useState(false)
	const [activeItemPointer, setActiveItemPointer] = useState(null)
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)
	const sliderRef = React.useRef(null)

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions())
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		const params = {
			theme_id: themes,
			search_by_names: true,
			q: searchText
		}
		dispatch(carouselActions.getContent(carouselIndex, params, 1, pages))
	}, [carouselIndex, themes, searchText, pages, dispatch])

	useEffect(() => {
		if (activeItemPointer) {
			dispatch(contentActions.getContentById(activeItemPointer.itemId))
			return
		}

		dispatch(contentActions.cleanContent())
	}, [activeItemPointer, dispatch])

	const hasNext =
		activeItemPointer &&
		filtered &&
		filtered.content &&
		filtered.content.length > 0 &&
		activeItemPointer.itemIndex < filtered.content.length - 1

	const hasPrev = activeItemPointer && activeItemPointer.itemIndex > 0

	const nextItem = useCallback(() => {
		setActiveItemPointer((currActiveItemPointer) => {
			if (!filtered || !filtered.content) {
				return currActiveItemPointer
			}
			if (
				currActiveItemPointer &&
				currActiveItemPointer.itemIndex < filtered.content.length - 1
			) {
				const newItemIndex = currActiveItemPointer.itemIndex + 1
				return {
					itemId: filtered.content[newItemIndex].id,
					itemIndex: newItemIndex
				}
			}
			return currActiveItemPointer
		})
	}, [filtered])

	const previousItem = useCallback(() => {
		setActiveItemPointer((currActiveItemPointer) => {
			if (!filtered || !filtered.content) {
				return currActiveItemPointer
			}
			if (currActiveItemPointer && currActiveItemPointer.itemIndex > 0) {
				const newItemIndex = currActiveItemPointer.itemIndex - 1
				return {
					itemId: filtered.content[newItemIndex].id,
					itemIndex: newItemIndex
				}
			}
			return currActiveItemPointer
		})
	}, [filtered])

	const onBlockClick = (newItemId, newItemIndex, newItemFriendlyUrl) => {
		if (windowDimensions.width > 782) {
			setActiveItemPointer({
				itemIndex: newItemIndex,
				itemId: newItemId
			})
			setContentDetailOpen(true)
			return
		}

		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${newItemFriendlyUrl}`, '_blank')
				: window.open(`/conteudo/${newItemFriendlyUrl}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const handleContentDetailClose = () => {
		setActiveItemPointer(null)
		setContentDetailOpen(false)
	}

	const slickSettings = {
		dots: false,
		infinite: false,
		accessibility: true,
		speed: 500,
		slidesToShow: 4,
		variableWidth: true,
		swipeToSlide: true,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		],
		nextArrow: (
			<S.ArrowRight>
				<S.ArrowIconContainer>›</S.ArrowIconContainer>
			</S.ArrowRight>
		),
		prevArrow: (
			<S.ArrowLeft>
				<S.ArrowIconContainer>‹</S.ArrowIconContainer>
			</S.ArrowLeft>
		)
	}

	return (
		<div>
			{mode === 'horizontal' && (
				<S.SliderWrapper
					{...slickSettings}
					margin={margin}
					ref={sliderRef}
				>
					{filtered &&
						filtered.content &&
						filtered.content.map((item, index) => {
							return (
								<ContentBlockForGrid
									key={index}
									title={item.title}
									fontColor={fontColor}
									// keywords={item.keywords}
									img={item.cover_id || noImg}
									onClick={() => {
										onBlockClick(
											item.id,
											index,
											item.friendly_url
										)
									}}
									// icon={item.content_type.id}
									contentType={item.content_type}
									isFixedWidth={false}
								/>
							)
						})}
				</S.SliderWrapper>
			)}
			{mode === 'vertical' && (
				<S.ContentBlockWrapper>
					{filtered &&
						filtered.content &&
						filtered.content.map((item, index) => {
							return (
								<ContentBlock
									key={`filtered_${item.id}`}
									title={item.title}
									description={item.description}
									// keywords={item.keywords}
									img={item.cover_id}
									onClick={() =>
										onBlockClick(
											item.id,
											index,
											item.friendly_url
										)
									}
									contentType={item.content_type}
								/>
							)
						})}
				</S.ContentBlockWrapper>
			)}
			<ContentOrCollectionDetailDialog
				item={activeItem}
				open={contentDetailOpen}
				handleClose={handleContentDetailClose}
				nextItem={nextItem}
				previousItem={previousItem}
				hasNext={hasNext}
				hasPrev={hasPrev}
			>
				<SharingBar item={activeItem} />
			</ContentOrCollectionDetailDialog>
		</div>
	)
}

export default Carousel
