export const term = `
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: center;
		margin-top: 0pt;
		margin-bottom: 14pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>TERMO E CONDI&Ccedil;&Otilde;ES DE USO</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span>S&atilde;o partes deste instrumento, de um lado:</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="font-weight: 700;"
		>INSTITUTO DO HOMEM E MEIO AMBIENTE DA AMAZ&Ocirc;NIA - IMAZON</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>, associa&ccedil;&atilde;o civil, inscrito no CNPJ sob o n&ordm;</span
	><span
		style="
			font-size: 11pt;
			font-family: Calibri, sans-serif;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>34.891.085/0001-67, com endere&ccedil;o situado na Trav. Dom Romualdo
		de Seixas, 1698 Edif&iacute;cio Zion Business, 11&ordm; andar Bairro
		Umarizal&nbsp;&ndash; CEP: 66.055-200;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>INSTITUTO SOCIOAMBIENTAL - ISA</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>, associa&ccedil;&atilde;o civil, inscrito no CNPJ sob o n&ordm;
		00.081.906/0001-88, com endere&ccedil;o situado na SCLN, 210, Bloco C
		sala 112, CEP: 70862-530 &ndash; Bras&iacute;lia/DF; e</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>INSTITUTO DE PESQUISA AMBIENTAL DA AMAZ&Ocirc;NIA - IPAM</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>, associa&ccedil;&atilde;o civil, inscrito no CNPJ sob o n&ordm;
		00.627.727/0001-01 com endere&ccedil;o na SCLN 211, Bloco B sala 201,
		CEP: 70863-520, Bras&iacute;lia/DF;&nbsp;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>E, do outro,&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>&ldquo;USU&Aacute;RIO&rdquo;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>, pessoa f&iacute;sica ou jur&iacute;dica, cujos dados foram inseridos
		no momento da efetiva&ccedil;&atilde;o de seu cadastro no site
		proteja.org ou proteja.org.br, o que constitui aprova&ccedil;&atilde;o
		do presente Termo e Condi&ccedil;&otilde;es de Uso.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>Neste ato os Institutos IMAZON e ISA s&atilde;o representados pelo IPAM
		e delimitam da seguinte maneira:</span
	>
</p>
<h3
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 2pt;
		margin-bottom: 0pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>1. Objeto.</span
	>
</h3>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>Este portal adota pr&aacute;ticas que objetivam o acesso &agrave;s
		informa&ccedil;&otilde;es institucionais com privacidade e
		credibilidade. Al&eacute;m de proporcionar ao Usu&aacute;rio a
		publica&ccedil;&atilde;o e disponibiliza&ccedil;&atilde;o de seu
		conte&uacute;do na plataforma.</span
	>
</p>
<h3
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 2pt;
		margin-bottom: 0pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>2. Pol&iacute;ticas Gerais.</span
	>
</h3>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>2.1. O Portal Institucional tem car&aacute;ter gratuito. Por&eacute;m,
		a utiliza&ccedil;&atilde;o de alguns servi&ccedil;os e canais somente
		poder&aacute; ser feita mediante o registro do Usu&aacute;rio e logo
		ap&oacute;s, o acesso aos dados ser&aacute; feito em qualquer momento
		mediante&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>login&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>na plataforma.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>2.2. Os Institutos n&atilde;o se responsabilizam por danos a terceiros
		que decorram de falhas de acesso, transmiss&atilde;o, difus&atilde;o ou
		disponibiliza&ccedil;&atilde;o de conte&uacute;do e de servi&ccedil;os
		do portal.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>2.3. A oferta de servi&ccedil;os e/ou conte&uacute;do desse portal
		obedecem a crit&eacute;rios de acessibilidade.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>3. Tratamento de Informa&ccedil;&otilde;es.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>3.1. Os Institutos n&atilde;o divulgam o endere&ccedil;o
		eletr&ocirc;nico, nem outras informa&ccedil;&otilde;es pessoais dos
		Usu&aacute;rios que utilizam os servi&ccedil;os do portal;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 0pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>3.2. &nbsp;O Portal utiliza cookies (recurso padr&atilde;o encontrado
		em seu navegador de internet) para registrar cada equipamento usado para
		acessar o Portal. Um n&uacute;mero de identifica&ccedil;&atilde;o
		an&ocirc;nimo e aleat&oacute;rio fica registrado em seu computador. Esse
		cookie &eacute; usado para contabilizarmos quantas vezes voc&ecirc;
		acessa o Portal. Tamb&eacute;m s&atilde;o coletadas
		informa&ccedil;&otilde;es como endere&ccedil;o IP,
		localiza&ccedil;&atilde;o geogr&aacute;fica, fonte de refer&ecirc;ncia,
		tipo de navegador, dura&ccedil;&atilde;o da visita e p&aacute;ginas
		visitadas. Os cookies identificam o computador usado para acessar o
		Portal, n&atilde;o podem acessar informa&ccedil;&otilde;es pessoais do
		Usu&aacute;rio contidas no disco r&iacute;gido ou mem&oacute;ria do
		aplicativo.&nbsp;</span
	>
</p>
<p><br /></p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 0pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>3.3. A identifica&ccedil;&atilde;o do Usu&aacute;rio ser&aacute; feita
		apenas por meio do cadastramento volunt&aacute;rio ao Portal. O Portal
		n&atilde;o liga as informa&ccedil;&otilde;es dos cookies de
		identifica&ccedil;&atilde;o do computador com as
		informa&ccedil;&otilde;es pessoais de identifica&ccedil;&atilde;o dos
		Usu&aacute;rios cadastrados.</span
	>
</p>
<p><br /></p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 11pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>3.4.&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>Voc&ecirc; pode desabilitar os cookies a qualquer momento, mudando as
		prefer&ecirc;ncias do seu navegador de Internet. Informamos que nossos
		patrocinadores, ou anunciantes terceirizados, podem deixar registrados
		seus pr&oacute;prios cookies no seu navegador, um processo que
		n&atilde;o podemos controlar.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>3.5. A qualquer momento o Usu&aacute;rio, por meio de
		solicita&ccedil;&atilde;o, poder&aacute; requerer que seus dados sejam
		exclu&iacute;dos do sistema.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>4. Condi&ccedil;&otilde;es de uso da Plataforma.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>4.1. Ao utilizar a Plataforma, o Usu&aacute;rio se compromete a
		observar o Termo e Condi&ccedil;&otilde;es de Uso, as normas e a
		legisla&ccedil;&atilde;o brasileira, a utilizar termos aceit&aacute;veis
		socialmente, e a n&atilde;o desrespeitar a ordem p&uacute;blica.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>4.2. O Usu&aacute;rio n&atilde;o dever&aacute; utilizar a plataforma
		para a pr&aacute;tica de atos proibidos pela lei brasileira e pelo
		presente Termo e Condi&ccedil;&otilde;es de Uso, ou atos que possam
		danificar, inutilizar, sobrecarregar ou deteriorar a plataforma, os
		equipamentos inform&aacute;ticos de outros usu&aacute;rios ou de outros
		internautas (</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>hardware</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>&nbsp;e&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>software</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>), assim como os documentos, arquivos e toda classe de conte&uacute;do
		armazenados nos seus equipamentos inform&aacute;ticos (</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>cracking</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>) ou impedir a normal utiliza&ccedil;&atilde;o da referida ferramenta,
		equipamentos inform&aacute;ticos e documentos, arquivos e
		conte&uacute;dos por parte dos demais usu&aacute;rios e de outros
		internautas.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>4.3. O Usu&aacute;rio se compromete a n&atilde;o utilizar qualquer
		sistema automatizado, inclusive, mas sem se limitar a
		&quot;rob&ocirc;s&quot;, &quot;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>spiders</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>&quot; ou &quot;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>offline readers</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>,&quot; que acessem a Plataforma de maneira a enviar mais mensagens de
		solicita&ccedil;&otilde;es ao servidor em um dado per&iacute;odo de
		tempo do que seja humanamente poss&iacute;vel responder no mesmo
		per&iacute;odo por interm&eacute;dio de um navegador convencional.
		&Eacute; igualmente vedada a coleta de qualquer informa&ccedil;&atilde;o
		pessoal dos demais usu&aacute;rios da Plataforma.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>4.4. O Usu&aacute;rio n&atilde;o dever&aacute; utilizar a Plataforma
		e/ou publicar conte&uacute;dos com a finalidade de distribuir ou
		incentivar qualquer ideia e/ou opini&atilde;o que por si mesma ou cuja
		transmiss&atilde;o:</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>a) Incite e/ou promova o descumprimento da lei, seja
		difamat&oacute;ria, infamante, violenta, degradante,
		pornogr&aacute;fica, ou, em geral, contr&aacute;ria &agrave; ordem
		p&uacute;blica;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>b) Incite e/ou promova a&ccedil;&otilde;es ou ideias
		discriminat&oacute;rias em raz&atilde;o de ra&ccedil;a, g&ecirc;nero,
		orienta&ccedil;&atilde;o sexual, religi&atilde;o, cren&ccedil;a,
		defici&ecirc;ncia, etnia, nacionalidade ou condi&ccedil;&atilde;o
		social;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>c) Constitua comportamento predat&oacute;rio,
		persegui&ccedil;&atilde;o, amea&ccedil;as, ass&eacute;dios,
		intimida&ccedil;&otilde;es ou chantagem a terceiros;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>d) Incite pr&aacute;ticas perigosas, de risco ou nocivas &agrave;
		sa&uacute;de e ao equil&iacute;brio ps&iacute;quico;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>e) Viole segredos empresariais de terceiros;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>f) Seja contr&aacute;ria &agrave; honra, &agrave; intimidade pessoal e
		familiar ou &agrave; pr&oacute;pria imagem das pessoas;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>g) Viole propriedade intelectual ou industrial de terceiros, ou
		contribua para tais pr&aacute;ticas;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>h) Facilite a dissemina&ccedil;&atilde;o ou contenha material com
		v&iacute;rus, dados corrompidos, ou qualquer outro elemento nocivo ou
		danoso;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>i) Desrespeite a legisla&ccedil;&atilde;o eleitoral e
		partid&aacute;ria;</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>j) Utilize a Plataforma para fins comerciais ou publicit&aacute;rios,
		compreendidos, inclusive:&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>spam</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>, correspond&ecirc;ncia corporativa e comunica&ccedil;&otilde;es com
		finalidade comercial (prospec&ccedil;&atilde;o de neg&oacute;cios, venda
		de servi&ccedil;os e mercadorias, ainda que relacionados &agrave; pessoa
		f&iacute;sica, etc.) ou uso relacionado com neg&oacute;cios, ou que
		anuncie ou ofere&ccedil;a a venda de produtos ou servi&ccedil;os (com ou
		sem fins lucrativos) ou que solicitem outros usu&aacute;rios ou
		terceiros (incluindo pedidos para contribui&ccedil;&otilde;es ou
		donativos).</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>4.5. Caso o Usu&aacute;rio utilize a plataforma ou publique
		conte&uacute;do contrariando as proibi&ccedil;&otilde;es descritas na
		cl&aacute;usula 4.4 ou qualquer outra disposi&ccedil;&atilde;o deste
		Termo e Condi&ccedil;&otilde;es de Uso, os Institutos poder&atilde;o,
		por mero crit&eacute;rio, bloquear, excluir quaisquer conte&uacute;do
		e/ou eliminar o cadastro do Usu&aacute;rio, a qualquer tempo e sem
		qualquer aviso pr&eacute;vio.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>5. Cess&atilde;o de direitos.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>5.1. Ao aceitar este Termo e Condi&ccedil;&otilde;es de Uso, o
		Usu&aacute;rio concede aos Institutos bem como a terceiros eventualmente
		por eles indicados, com exclusividade, autom&aacute;tica e
		gratuitamente, em car&aacute;ter irrestrito, irretrat&aacute;vel e
		irrevog&aacute;vel, autoriza&ccedil;&atilde;o para fins
		publica&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o
		e exibi&ccedil;&atilde;o do conte&uacute;do disponibilizado pelo
		Usu&aacute;rio.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>5.2. Os Institutos podem livremente dispor dos conte&uacute;dos, sem
		que o Usu&aacute;rio receba qualquer remunera&ccedil;&atilde;o ou
		compensa&ccedil;&atilde;o. Nenhuma das utiliza&ccedil;&otilde;es aqui
		previstas tem limita&ccedil;&atilde;o de tempo (salvo em caso de
		retirada do conte&uacute;do do portal, a partir da data da retirada) ou
		de n&uacute;mero de vezes, podendo ocorrer no Brasil e/ou no exterior, a
		crit&eacute;rio exclusivo dos Institutos.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>5.3. O Usu&aacute;rio, ao aceitar o Termo e Condi&ccedil;&otilde;es de
		Uso, manifesta a sua concord&acirc;ncia com a utiliza&ccedil;&atilde;o,
		pelos Institutos, segundo seus exclusivos crit&eacute;rios, dos
		conte&uacute;dos postados por interm&eacute;dio da plataforma em
		qualquer m&iacute;dia, incluindo&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>cards</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>&nbsp;de&nbsp;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>fan pages</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>, associados ou n&atilde;o &agrave; plataforma, mediante a
		divulga&ccedil;&atilde;o do nome do Usu&aacute;rio, e, ainda, reconhece
		que os Institutos poder&atilde;o adaptar para adequa&ccedil;&atilde;o do
		formato de cada m&iacute;dia ou plataforma tecnol&oacute;gica.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>6. Exclus&atilde;o de garantias e de responsabilidade.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>6.1. Os Institutos n&atilde;o t&ecirc;m obriga&ccedil;&otilde;es de
		controlar a utiliza&ccedil;&atilde;o dos Usu&aacute;rios na Plataforma.
		Inclusive n&atilde;o garantem que os Usu&aacute;rios utilizem a
		plataforma em conformidade com a lei brasileira, com este Termo de Uso
		ou de acordo com os valores m&eacute;dios presumidos da sociedade e a
		ordem p&uacute;blica; Tamb&eacute;m n&atilde;o t&ecirc;m as
		obriga&ccedil;&otilde;es de verificar a identidade dos Usu&aacute;rios,
		nem a veracidade, vig&ecirc;ncia, e autenticidade dos dados que os
		Usu&aacute;rios informam sobre si mesmos ao portal, a outros
		usu&aacute;rios ou a terceiros. OS INSTITUTOS SE EXIMEM DE QUALQUER
		RESPONSABILIDADE PELOS DANOS E PREJU&Iacute;ZOS DIRETOS E/OU INDIRETOS
		DE QUALQUER NATUREZA QUE POSSAM SER DEVIDOS EM VIRTUDE DA
		UTILIZA&Ccedil;&Atilde;O DA PLATAFORMA, DO CONTE&Uacute;DO
		DISPONIBILIZADO QUE POSSAM SER DEVIDOS &Agrave; FALTA DE VERACIDADE,
		VIG&Ecirc;NCIA E AUTENTICIDADE DA INFORMA&Ccedil;&Atilde;O QUE OS
		USU&Aacute;RIOS FORNECEM ACERCA DE SI PR&Oacute;PRIOS E, INCLUSIVE, QUE
		POSSAM SER DEVIDOS EM VIRTUDE DA SIMULA&Ccedil;&Atilde;O POR UM
		USU&Aacute;RIO DA PERSONALIDADE DE UM TERCEIRO.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>6.2. Os Institutos n&atilde;o garantem a privacidade e a
		seguran&ccedil;a na utiliza&ccedil;&atilde;o da Plataforma por parte dos
		Usu&aacute;rios e, em particular, se eximem das responsabilidades quanto
		&agrave; pr&aacute;tica por terceiros n&atilde;o autorizados de atos de
		intercepta&ccedil;&atilde;o, elimina&ccedil;&atilde;o,
		altera&ccedil;&atilde;o, modifica&ccedil;&atilde;o ou
		manipula&ccedil;&atilde;o dos conte&uacute;dos.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>6.3. O Usu&aacute;rio &eacute; o &uacute;nico respons&aacute;vel pelo
		que escreve em seu conte&uacute;do, de modo que responder&aacute;
		integralmente por qualquer ofensa a direitos de terceiros que seu
		conte&uacute;do possa causar. Ocorrendo qualquer pleito ou demanda
		judicial de terceiros que de qualquer modo afetem os Institutos, com
		rela&ccedil;&atilde;o ou em decorr&ecirc;ncia dos conte&uacute;dos
		publicados pelos usu&aacute;rios que violem o presente Termo e
		Condi&ccedil;&otilde;es de Uso, quaisquer leis ou regulamentos locais,
		nacionais ou internacionais aplic&aacute;veis, ou quaisquer direitos de
		terceiros, o Usu&aacute;rio dever&aacute; assumir todas as
		responsabilidades decorrentes do descumprimento de tais
		obriga&ccedil;&otilde;es, bem como indenizar regressivamente aos
		Institutos, caso estes venham a ser condenados aos pagamentos de
		indeniza&ccedil;&otilde;es ou outros pagamentos de natureza distinta em
		virtude de atos praticados em desacordo com as disposi&ccedil;&otilde;es
		deste Termo e Condi&ccedil;&otilde;es de Uso ou da
		legisla&ccedil;&atilde;o aplic&aacute;vel.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>6.4. Os Institutos n&atilde;o s&atilde;o respons&aacute;veis pela
		presta&ccedil;&atilde;o de quaisquer servi&ccedil;os ou bens, vendidos
		ou anunciados por interm&eacute;dio dos seus anunciantes,
		patrocinadores, usu&aacute;rios ou parceiros de forma geral, associados
		&agrave; Plataforma.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>7. Vig&ecirc;ncia.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>7.1. A autoriza&ccedil;&atilde;o ora concedida para uso da Plataforma
		tem dura&ccedil;&atilde;o por prazo indeterminado. Os Institutos
		reservam-se ao direito de dar por encerrada ou suspender a
		disponibiliza&ccedil;&atilde;o da Plataforma a qualquer tempo, a seu
		exclusivo crit&eacute;rio.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>7.2. Ademais, os Institutos se reservam ao direito de modificarem
		unilateralmente a apresenta&ccedil;&atilde;o e
		configura&ccedil;&atilde;o da Plataforma, assim como as
		condi&ccedil;&otilde;es ora requeridas para sua
		utiliza&ccedil;&atilde;o.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 700;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>8 &ndash; Disposi&ccedil;&otilde;es Gerais.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>8.1. O n&atilde;o exerc&iacute;cio pelos Institutos ou pelo
		Usu&aacute;rio de quaisquer direitos ou faculdades que lhes sejam
		conferidos por este Termo e Condi&ccedil;&otilde;es de Uso ou pela
		legisla&ccedil;&atilde;o brasileira, bem como a eventual
		toler&acirc;ncia contra infra&ccedil;&otilde;es a este Termo, n&atilde;o
		importar&aacute; na ren&uacute;ncia aos seus direitos,
		nova&ccedil;&atilde;o ou altera&ccedil;&atilde;o de itens e
		cl&aacute;usulas deste instrumento, podendo os Institutos ou
		Usu&aacute;rio exerc&ecirc;-los a qualquer momento.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>8.2. O presente instrumento, bem como a cess&atilde;o prevista na
		cl&aacute;usula quinta n&atilde;o implicam na cria&ccedil;&atilde;o de
		qualquer v&iacute;nculo trabalhista ou de parceria entre os Institutos e
		o Usu&aacute;rio.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>8.3. A marca&ccedil;&atilde;o do &ldquo;</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: italic;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>check-box</span
	><span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>&rdquo; ao lado do texto &quot;Concordo com os Termos&quot;, seguida
		pelo clique no bot&atilde;o &quot;aceitar&quot; e/ou a
		utiliza&ccedil;&atilde;o da Plataforma, feitas pelo Usu&aacute;rio,
		s&atilde;o consideradas, para todos os fins de direito, como sua
		aceita&ccedil;&atilde;o de todos os termos e condi&ccedil;&otilde;es do
		presente Termo e Condi&ccedil;&otilde;es de Uso.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>8.4.Todas as comunica&ccedil;&otilde;es entre os Institutos e o
		Usu&aacute;rio ser&atilde;o feitas via e-mail. Os Institutos
		poder&atilde;o divulgar notifica&ccedil;&otilde;es ou mensagens por
		interm&eacute;dio da Plataforma para informar eventuais mudan&ccedil;as
		na Plataforma ou no Termo e Condi&ccedil;&otilde;es de Uso e/ou outras
		informa&ccedil;&otilde;es que considerarem relevantes.</span
	>
</p>
<p
	dir="ltr"
	style="
		line-height: 1.2;
		text-align: justify;
		margin-top: 0pt;
		margin-bottom: 8pt;
	"
>
	<span
		style="
			font-size: 12pt;
			color: #000000;
			background-color: transparent;
			font-weight: 400;
			font-style: normal;
			font-variant: normal;
			text-decoration: none;
			vertical-align: baseline;
		"
		>8.5. Fica eleito o Foro a Circunscri&ccedil;&atilde;o Judici&aacute;ria
		de Bras&iacute;lia para dirimir quaisquer quest&otilde;es oriundas deste
		Termo e Condi&ccedil;&otilde;es de Uso, desistindo as Partes de qualquer
		outro, por mais privilegiado que seja ou venha a ser. Qualquer disputa
		que surja em virtude do presente Termo ser&aacute; regulada pela
		legisla&ccedil;&atilde;o brasileira.</span
	>
</p>
<p><br /></p>
<p><br /></p>
<p><br /></p>
`
