import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const searchService = {
	getFilteredResults,
	loadMoreFilteredResults
}

const sanitizeFilter = (filter) => {
	return {
		...filter,
		content_type_id: filter.content_type_id
			? filter.content_type_id.map((item) => item.id)
			: null,
		year: filter.year ? filter.year : null,
		theme_id: filter.theme_id ? filter.theme_id : null,
		bioma_id: filter.bioma_id ? filter.bioma_id : null,
		language_id: filter.language_id ? filter.language_id : null,
		pais_id: filter.pais_id ? filter.pais_id : null,
		estado_id: filter.estado_id ? filter.estado_id : null,
		land_category_id: filter.land_category_id
			? filter.land_category_id
			: null
	}
}

function getFilteredResults(
	params,
	page,
	perPage,
	language,
	sort = null,
	sortDirection = 'asc'
) {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(sanitizeFilter(params)),
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	let path = `${config.apiUrl}/busca/${language}/${page}/${perPage}`

	if (sort) {
		path = `${path}/${sort}/${sortDirection}`
	}

	return fetch(path, requestOptions).then(handleResponse)
}

function loadMoreFilteredResults(
	params,
	page,
	perPage,
	language,
	sort = null,
	sortDirection = null
) {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(sanitizeFilter(params)),
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	let path = `${config.apiUrl}/busca/${language}/${page}/${perPage}`

	if (sort) {
		path = `${path}/${sort}/${sortDirection}`
	}

	return fetch(path, requestOptions).then(handleResponse)
}
