import React from 'react'
import Icon from './Icon'

/**
 * @function Button
 */
const Button = ({
	children,
	type = 'nutton',
	icon = null,
	onClick = null,
	style
}) => (
	<button className="default" type={type} onClick={onClick} style={style}>
		{icon && <Icon type={icon} />}
		{children}
	</button>
)

export default Button
