import React from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'

function AsyncSelect(props) {
	return (
		<AsyncPaginate
			{...props}
			debounceTimeout={200}
			theme={(theme) => ({
				...theme,
				borderSize: 1,
				colors: {
					...theme.colors,
					primary: '#85b7d9'
				}
			})}
			additional={{
				page: 1
			}}
			loadingMessage={() => 'Carregando...'}
			noOptionsMessage={() => 'Nenhum valor encontrado.'}
		/>
	)
}

export default AsyncSelect
