import styled from 'styled-components'

export const SkeletonWrap = styled.div`
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	padding: 0px;
	align-items: center;
	gap: 8px;
`
export const SkeletonText = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 4px;
`
