export const getSteps = (collectionTypeObject) => {
	return [
		{
			key: 'step0',
			title: collectionTypeObject.name,
			description: 'Informações básicas'
		},
		{
			key: 'step1',
			title: `Ficha Técnica d${
				collectionTypeObject.genre
			} ${collectionTypeObject.name.toLowerCase()}`,
			description: 'Informações adicionais'
		}
	]
}

export const stepInputs = {
	// STEP 1:
	collection_type: '',
	title: '',
	description: '',
	cover_id: '',
	cover_filename: '',

	// STEP 2:
	// content_indications: [],

	// STEP 3:
	contributors: [],
	language_id: [],
	themes: [],
	keywords: [],

	// Ficha Técnica - Curso and Projeto:
	date_start: null,
	date_end: null,

	// Ficha Técnica - Curso only:
	curso_type: '',
	curso_workload: '',
	curso_program: '',

	// Ficha Técnica - Projeto only:
	projeto_financers: '',
	projeto_url: ''
}
