import React from 'react'

import { term } from './term.js'
import * as S from './styles'

const Term = () => {
	return (
		<div className="page">
			<S.Wrapp>
				<S.Text dangerouslySetInnerHTML={{ __html: term }}></S.Text>
			</S.Wrapp>
		</div>
	)
}

export default Term
