import { orgsConstants } from '../_constants'

export function orgs(state = { orgs: [] }, action) {
	switch (action.type) {
		case orgsConstants.GETALL_REQUEST:
			return {
				loadingOrgs: true
			}
		case orgsConstants.GETALL_SUCCESS:
			return {
				orgs: action.rows
			}
		case orgsConstants.GETALL_FAILURE:
			return {
				error: action.error
			}
		case orgsConstants.GET_ALL_REQUEST:
			return {
				fetchingOrgs: true,
				...state
			}
		case orgsConstants.GET_ALL_SUCCESS:
			return {
				orgs: action.rows
			}
		case orgsConstants.GET_ALL_FAILURE:
			return {
				orgs: [],
				error: action.error
			}
		case orgsConstants.CREATE_SUCCESS:
			return {
				...state,
				object: action.data
			}
		case orgsConstants.CREATE_BATCH_SUCCESS:
			return {
				...state,
				orgs: action.data
			}
		case orgsConstants.ADD_TEMPORARY_SUCCESS:
			return {
				...state,
				orgs: [action.newOrg, ...state.orgs]
			}
		case orgsConstants.CREATE_REQUEST:
		case orgsConstants.CREATE_FAILURE:
		case orgsConstants.CREATE_BATCH_REQUEST:
		case orgsConstants.CREATE_BATCH_FAILURE:
		case orgsConstants.DELETE_REQUEST:
		case orgsConstants.DELETE_SUCCESS:
		case orgsConstants.DELETE_FAILURE:
		default:
			return state
	}
}
