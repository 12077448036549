import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon as SemanticIcon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import {
	collectionsActions,
	contentActions,
	searchActions
} from '../../../app/_actions'
import { useFilter } from '../../../context/advancedFilter'
import '../../../css/style.css'
import languageList from '../../../constants/languages'
import LandingPageContentBlockForGrid from '../LandingPageContentBlockForGrid'
import SkeletonCustom from '../SkeletonCustom'
import SharingBar from '../SharingBar'
import {
	ContentBlock,
	ContentOrCollectionDetailDialog
} from '../../../eita-react-components'
import noImg from '../../../assets/no-image.jpg'
import useOnScreen from '../../../hooks/useOnScreen'

const SearchResultList = ({
	data,
	dataBiomes,
	dataLandCategories,
	dataCountries,
	dataStates,
	topics,
	perPage,
	screenWidth: windowDimensionsWidth
}) => {
	const dispatch = useDispatch()
	const endOfPageRef = useRef(null)
	const [activeItemPointer, setActiveItemPointer] = useState(null)
	// @ts-ignore
	const { object: activeContentItem } = useSelector((state) => state.content)
	const { object: activeCollectionItem } = useSelector(
		// @ts-ignore
		(state) => state.collections
	)
	const [contentDetailOpen, setContentDetailOpen] = useState(false)
	const [isGridMode, setIsGridMode] = useState(false)
	// @ts-ignore
	const { filter, setFilter } = useFilter()
	const reachedEndOfScreen = useOnScreen(endOfPageRef)
	const { i18n } = useTranslation()

	useEffect(() => {
		if (!data || !i18n.language) {
			return
		}
		if (!data.loadingMore && reachedEndOfScreen && data.has_next) {
			dispatch(
				searchActions.loadMoreFilteredResults(
					filter,
					data.page + 1,
					perPage,
					i18n.language
				)
			)
		}
	}, [dispatch, filter, perPage, data, reachedEndOfScreen, i18n.language])

	useEffect(() => {
		if (activeItemPointer) {
			if (data.results[activeItemPointer.itemIndex].content_type.id) {
				dispatch(
					contentActions.getContentById(activeItemPointer.itemId)
				)
				dispatch(collectionsActions.cleanCollection())
				return
			}

			dispatch(contentActions.cleanContent())
			dispatch(
				collectionsActions.getCollectionById(activeItemPointer.itemId)
			)
			return
		}

		dispatch(contentActions.cleanContent())
		dispatch(collectionsActions.cleanCollection())
	}, [activeItemPointer, dispatch, data])

	const hasNext =
		activeItemPointer &&
		data &&
		data.results &&
		data.results.length > 0 &&
		activeItemPointer.itemIndex < data.results.length - 1

	const hasPrev = activeItemPointer && activeItemPointer.itemIndex > 0

	const nextItem = useCallback(() => {
		if (!data || !data.results || data.results.length === 0) {
			return
		}
		setActiveItemPointer((currActiveItemPointer) => {
			if (
				currActiveItemPointer &&
				currActiveItemPointer.itemIndex < data.results.length - 1
			) {
				const newItemIndex = currActiveItemPointer.itemIndex + 1
				return {
					itemId: data.results[newItemIndex].id,
					itemIndex: newItemIndex
				}
			}
			return currActiveItemPointer
		})
	}, [data])

	const previousItem = useCallback(() => {
		if (!data || !data.results || data.results.length === 0) {
			return
		}
		setActiveItemPointer((currActiveItemPointer) => {
			if (currActiveItemPointer && currActiveItemPointer.itemIndex > 0) {
				const newItemIndex = currActiveItemPointer.itemIndex - 1
				return {
					itemId: data.results[newItemIndex].id,
					itemIndex: newItemIndex
				}
			}
			return currActiveItemPointer
		})
	}, [data])

	const onBlockClick = (newItemId, newItemIndex, newItemFriendlyUrl) => {
		if (windowDimensionsWidth > 782) {
			setActiveItemPointer({
				itemIndex: newItemIndex,
				itemId: newItemId
			})
			setContentDetailOpen(true)
			return
		}

		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${newItemFriendlyUrl}`, '_blank')
				: window.open(`/conteudo/${newItemFriendlyUrl}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const handleContentDetailClose = () => {
		setActiveItemPointer(null)
		setContentDetailOpen(false)
	}

	const getChipText = useCallback(
		(key, id) => {
			if (!dataBiomes.length) {
				return
			}

			if (key == 'language_id') {
				const item = languageList.find((i) => i.id == id)
				return item ? item.portugueseName : ''
			}

			if (key == 'bioma_id') {
				const item = dataBiomes.find((i) => i.value == id)
				return item ? item.text : ''
			}

			if (key == 'land_category_id') {
				const item = dataLandCategories.find((i) => i.value == id)
				return item ? item.text : ''
			}
			if (key == 'pais_id') {
				const item = dataCountries.find((i) => i.value == id)
				return item ? item.text : ''
			}
			if (key == 'estado_id') {
				const item = dataStates.find((i) => i.value == id)
				return item ? item.text : ''
			}
			if (key == 'theme_id') {
				const item = topics.find((i) => i.id == id)
				return item ? item.text : ''
			}
			return id
		},
		[dataBiomes, dataCountries, dataLandCategories, dataStates, topics]
	)

	const onSeriesContentClick = (friendlyUrl) => {
		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${friendlyUrl}`, '_blank')
				: window.open(`/conteudo/${friendlyUrl}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const createChips = useCallback(() => {
		if (!i18n.language) {
			return
		}
		const clearFilter = (key, values, filterValue) => {
			const newValues = values.filter((i) => i !== filterValue)
			dispatch(
				searchActions.getFilteredResults(
					{
						...filter,
						[key]: newValues
					},
					1,
					10,
					i18n.language
				)
			)
			setFilter((prevFilter) => ({ ...prevFilter, [key]: newValues }))
		}
		return Object.keys(filter).map((key) => {
			if (
				key !== 'content_type_id' &&
				key !== 'q' &&
				key !== 'keywords' &&
				filter[key].length
			) {
				return filter[key].map((value) => (
					<S.FilterChip
						key={key + value}
						onClick={() => clearFilter(key, filter[key], value)}
					>
						<span>{getChipText(key, value)}</span>
						<S.FilterChipIcon>
							<SemanticIcon
								name="close"
								style={{ color: '#424242' }}
							/>
						</S.FilterChipIcon>
					</S.FilterChip>
				))
			}
		})
	}, [filter, getChipText, dispatch, setFilter, i18n.language])

	return (
		<S.Container>
			<S.ResultsHeader>
				{isGridMode ? (
					<S.DisplayModeButton
						onClick={() => {
							setIsGridMode(false)
						}}
					>
						<SemanticIcon
							name="list"
							style={{
								color: '#424242',
								fontSize: '16px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: 0
							}}
						/>
						Lista
					</S.DisplayModeButton>
				) : (
					<S.DisplayModeButton
						onClick={() => {
							setIsGridMode(true)
						}}
					>
						<SemanticIcon
							name="th"
							style={{
								color: '#424242',
								fontSize: '16px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: 0
							}}
						/>
						Grid
					</S.DisplayModeButton>
				)}
				{!data.loading && (
					<S.Title>
						{data && data.totalResults && (
							<>
								<S.TotalResults>
									{data.totalResults}
								</S.TotalResults>
								&nbsp;{' '}
								{data.totalResults > 1
									? 'Resultados'
									: 'Resultado'}
							</>
						)}
						{filter.content_type_id.length ? (
							<>
								&nbsp;para&nbsp;
								<S.TypeFilterTitle>
									{filter.content_type_id[0].name}
								</S.TypeFilterTitle>
							</>
						) : (
							''
						)}
					</S.Title>
				)}
				{createChips()}
				<S.FilterChips>{}</S.FilterChips>
			</S.ResultsHeader>
			{!data || !data.results || data.loading ? (
				<S.ListWrap>
					{Array.from(Array(perPage).keys()).map((index) => (
						<SkeletonCustom key={index} />
					))}
				</S.ListWrap>
			) : isGridMode ? (
				<S.GridWrap>
					{data && data.results && (
						<>
							{data.results.map((item, index) => {
								const genericType = item.content_type.id
									? item.content_type
									: item.collection_type
								return (
									<LandingPageContentBlockForGrid
										key={`filtered_${item.id}`}
										title={item.title}
										img={
											item.cover_id
												? item.cover_id
												: noImg
										}
										onClick={() =>
											onBlockClick(
												item.id,
												index,
												item.friendly_url
											)
										}
										contentType={genericType}
									></LandingPageContentBlockForGrid>
								)
							})}
							{data.loadingMore && <SkeletonCustom />}
						</>
					)}
				</S.GridWrap>
			) : (
				<S.ListWrap>
					{data && data.results && (
						<>
							{data.results.map((item, index) => {
								const genericType = item.content_type.id
									? item.content_type
									: item.collection_type
								return (
									<ContentBlock
										key={`filtered_${item.id}`}
										title={item.title}
										description={item.description}
										img={item.cover_id}
										onClick={() =>
											onBlockClick(
												item.id,
												index,
												item.friendly_url
											)
										}
										contentType={genericType}
									></ContentBlock>
								)
							})}
							{data.loadingMore && <SkeletonCustom />}
						</>
					)}
				</S.ListWrap>
			)}

			{data && data.totalResults === 0 && (
				<S.NoContent>
					Nenhum resultado encontrado com o filtro especificado.
				</S.NoContent>
			)}
			<S.PaginationWrap ref={endOfPageRef} />
			<ContentOrCollectionDetailDialog
				item={
					activeContentItem.id
						? activeContentItem
						: activeCollectionItem
				}
				open={contentDetailOpen}
				handleClose={handleContentDetailClose}
				nextItem={nextItem}
				previousItem={previousItem}
				hasNext={hasNext}
				hasPrev={hasPrev}
				onCollectionContentClick={onSeriesContentClick}
			>
				<SharingBar
					item={
						activeContentItem.id
							? activeContentItem
							: activeCollectionItem
					}
				/>
			</ContentOrCollectionDetailDialog>
		</S.Container>
	)
}

export default SearchResultList
